import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords, formatNumberWithCommas, getAbbreviationForPi} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';
import { set } from 'rsuite/esm/internals/utils/date';

const GroupPiPDF = () => {
  const {groupPiId } = useParams();
  const [groupPiInfo, setGroupPiInfo] = useState([]);
  const [groupPiNo, setGroupPiNo] = useState('');
  const [groupPiDate, setGroupPiDate] = useState(0);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (groupPiId) {
      fetch_group_pi_pdf_info();
    }
  }, [groupPiId]);

  const fetch_group_pi_pdf_info = () => {
    axios.get(`${window.backendUrl}/get_group_pi_pdf_info/${groupPiId}`)
      .then((response) => {
        setGroupPiInfo(response.data.data);
        setGroupPiNo(response.data.groupPiNo);
        setGroupPiDate(response.data.groupPiDate);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
    // console.log(groupPiInfo)
    // setLoading(false);
  };

//   useEffect(() => {
//     if (groupPiInfo) {
//       console.log(groupPiInfo);
//     }
//   }, [groupPiInfo]);

  if (loading) {
    return <></>
  }


  return (
    <body>
    <table width="100%" border="0" cellSpacing="5" cellPadding="0" style={{ width: '100%', fontSize:'12px', borderCollapse: 'collapse', marginTop:'15px' }}>
      <tbody>
        <tr>
          <td align="center" valign="top" width="130">
            <img
              style={{ maxHeight: '70px' }}
              src="/Images/miahandsonslogo.png"
              alt="MIAH & SONS INDUSTRIES LTD."
            />
          </td>
          <td align="left" valign="top" nowrap="nowrap">
            <p>
              <strong style={{ fontSize: '22px' }}>{companyTitle}</strong>
              <br />
              <strong style={{ fontSize: '15px', color: '#61190d' }}>Factory / Office: </strong>
              Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
              <br />
              BIN/VAT Reg. : 000686131-0103
            </p>
          </td>
          <td width="40%" align="left" valign="middle">
            <h2 align="center" style={{ width: '270px',border: '1px solid #333',padding: '8px 20px', marginTop: '0', lineHeight:'18px', fontSize:'20px'}}>
              <strong>PROFORMA INVOICE</strong>
            </h2>
            
          </td>    
        </tr>
        
        <tr>
          <td colSpan="3" align="center">
          <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
          </td>
        </tr>
        <tr>
          <td colSpan="2" valign="top">
            <strong>To: </strong> {groupPiInfo[0].party_name}
            <br />
            <div style={{width:'400px',wordWrap: 'break-word', whiteSpace: 'normal' }}>
              <strong>Address: </strong>{groupPiInfo[0].address}
            </div>
          </td>
          <td align="left" valign="top">
            <strong>A. General Information</strong> 
            <br />
            <strong>Proforma Invoice No.:</strong> {groupPiNo}
            <br />
            <strong>Proforma Invoice Date:</strong> {new Date(groupPiDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
          </td>
        </tr>
        <tr>
          <td colSpan="2" align="left" style={{ paddingBottom: '15px' }}>
            
          </td>
          <td align="right" style={{ paddingBottom: '15px' }}>
            <div
              align="center"
              style={{
                width: '180px',
                border: '1px solid #333',
                padding: '8px 15px',
                marginTop: '0',
              }}
            >
              <strong>H.S. Code: 4819.10.00</strong>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table width="100%" border="1" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse' }}>
      <thead style={{fontSize:'12px'}}>
        <tr>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>SL</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Date</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>LPD PO No.</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Style/Order /File No.</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Buyer Name</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Item Name</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Spec.</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Measurement</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Qty</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Total Sq.Mt.</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Unit Price</th>
            <th style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000', textAlign: 'center'}}>Total Amount</th>
        </tr>
    </thead>

      <tbody className='challan_pdf_table' style={{ fontSize: '12px' }}>
        {groupPiInfo.map((order,index) => {
            const { created_date, lpd_po_no, style, buyer_name, measurements } = order;
            
            // Calculate the number of measurements for rowspan
            const measurementCount = measurements.length + 1;

            return (
            <React.Fragment>
                <tr>
                <td rowSpan={measurementCount} style={{ textAlign: 'center' }}>{index+1}</td>
                <td rowSpan={measurementCount} style={{ textAlign: 'center' }}>{new Date(created_date * 1000).toLocaleDateString()}</td>
                <td rowSpan={measurementCount} style={{ textAlign: 'center' }}>{lpd_po_no}</td>
                <td rowSpan={measurementCount} style={{ textAlign: 'center' }}>{style}</td>
                <td rowSpan={measurementCount} style={{ textAlign: 'center' }}>{buyer_name}</td>
                <td style={{textAlign:'center'}}>{measurements[0].itemName}</td>
                <td style={{textAlign:'center'}}>{measurements[0].specification}</td>
                <td style={{textAlign:'center'}}>
                        {measurements[0].height === 0
                            ? `${measurements[0].length}x${measurements[0].width} ${measurements[0].unit}`
                            : `${measurements[0].length}x${measurements[0].width}x${measurements[0].height} ${measurements[0].unit}`}
                    </td>
                <td style={{textAlign:'center'}}>{measurements[0].orderQuantity}/Pcs</td>
                <td style={{textAlign:'center'}}>{measurements[0].totalSquareMeter}</td>
                <td style={{textAlign:'center'}}>${(measurements[0].unitPrice).toFixed(5)}</td>
                <td style={{textAlign:'center'}}>{(measurements[0].unitPrice * measurements[0].orderQuantity).toFixed(2)}</td>
                </tr>
                {measurements.slice(1).map((measurement, index) => (
                <tr key={index}>
                    <td style={{textAlign:'center'}}>{measurement.itemName}</td>
                    <td style={{textAlign:'center'}}>{measurement.specification}</td>
                    <td style={{textAlign:'center'}}>
                        {measurement.height === 0
                            ? `${measurement.length}x${measurement.width} ${measurement.unit}`
                            : `${measurement.length}x${measurement.width}x${measurement.height} ${measurement.unit}`}
                    </td>
                    <td style={{textAlign:'center'}}>{measurement.orderQuantity}/Pcs</td>
                    <td style={{textAlign:'center'}}>{measurement.totalSquareMeter}</td>
                    <td style={{textAlign:'center'}}>${(measurement.unitPrice).toFixed(5)}</td>
                    <td style={{textAlign:'center'}}>{(measurement.unitPrice * measurement.orderQuantity).toFixed(2)}</td>
                </tr>
                ))}
                <tr>
                    <td colSpan="3" style={{ textAlign: 'right' }}><strong>LPD PO No. Total: </strong></td>
                    <td style={{textAlign:'center'}}><strong>{measurements.reduce((acc, measurement) => acc + measurement.orderQuantity, 0)}</strong></td>
                    <td colSpan="2"></td>
                    <td style={{textAlign:'center'}}><strong>${formatNumberWithCommas((measurements.reduce((acc, measurement) => acc + (measurement.unitPrice * measurement.orderQuantity), 0)).toFixed(2))}</strong></td>
                </tr>
            </React.Fragment>
            );
        })}
        <tr>
            <td colSpan="8" style={{ textAlign: 'right' }}><strong>Grand Total:</strong></td>
            <td style={{textAlign:'center'}}>
                <strong>
                    {Object.values(groupPiInfo).reduce((acc, order) => 
                        acc + order.measurements.reduce((qtyAcc, measurement) => qtyAcc + measurement.orderQuantity, 0), 0)}
                </strong>
            </td>
            <td colSpan="2"></td>
            <td style={{textAlign:'center'}}>
                <strong>
                    ${formatNumberWithCommas(
                        Object.values(groupPiInfo).reduce((acc, order) => 
                            acc + order.measurements.reduce((priceAcc, measurement) => priceAcc + (measurement.unitPrice * measurement.orderQuantity), 0), 0).toFixed(2)
                        )}
                </strong>
            </td>
        </tr>

    </tbody>


    </table>
{/* /////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div style={{ width: '95%', height: '50px', margin: '15px auto 0', padding: '0', fontSize:'12px'}}>
      <table width="100%" border="1" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td align="left" className='in_words_pi'>
              <strong>
                In Words (USD): <strong>{convertNumberToWords(Object.values(groupPiInfo).reduce((acc, order) => 
                acc + order.measurements.reduce((priceAcc, measurement) => priceAcc + (measurement.unitPrice * measurement.orderQuantity), 0), 0).toFixed(2))}</strong>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <table border="0" cellSpacing="5" cellPadding="3" style={{ marginLeft: '50px', fontSize:'12px' }}>
      <tbody className='group_pi_terms_and_conditions'>
        <tr >
          <td align="left" colSpan="3">
            <br />
            <strong >C. Terms & Condition:</strong>
          </td>
        </tr>
        <br />
        <div style={{marginLeft:'23px'}}>
        {/* <tr>
          <td align="left" nowrap="nowrap" width="5%">
          1. Payment: Irrevocable Letter of Credit at {groupPiInfo[0].credit_days} days from the date of Delivery Challan to be opened in favor of <strong>{companyTitle}, Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.</strong>
          </td>
        </tr> */}
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            1. Payment:{" "}
            {groupPiInfo[0].credit_days === 0
              ? "Irrevocable Letter of Credit at sight to be opened in favor of "
              : `Irrevocable Letter of Credit at ${groupPiInfo[0].credit_days} days from the date of Delivery Challan to be opened in favor of `}
            <strong>
              {companyTitle}, Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
            </strong>
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">2. L/C Advising Bank : 
          <strong>{groupPiInfo[0].bank_name}, </strong>{groupPiInfo[0].bank_address}, SWIFT Code : <strong>{groupPiInfo[0].bank_swift_code}</strong>
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">3.Payment to be made in <strong>U.S Dollar</strong> </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            4. Partial Shipment: Allowed.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            5. Delivery Terms / Inco Term : CPT. (Openers Factory)
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            6. Maturity date should be calculated from the date of Delivery Challan / Negotiation.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            7. All Banking Charges inside opener's bank counter on account of opener and outside opener's bank counter on account of beneficiary.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            8. Clause of Bank Officer's signature on Delivery Challan and or acceptance after physical verification of goods by the bank officer's is/are not allowed in L/C.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            9. Payment after Export Realization Clause not allowed in the L/C.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            10. L/C must incorporate delivery validity 30 days from the date of L/C.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            11. Document Presentation Period : 15 days from the date of delivery challan.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            12. L/C should be freely Negotiable
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            13. Tolerance: 10% (+/-) both in quantity and value are acceptable.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            14. Interest to be paid at LIBOR for usance period.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            15. Overdue Interest @ 13.50% p.a shall have to be paid by the opener for late payment.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            16. Terms & Conditions of this Proforma Invoice deemed to be an integral part of L/C.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">
            17. Utilization Declaration (UD), along with the bank attested copy of L/C or sales contract has to be in place within 3 working days to back L/C or sales contract.
          </td>
        </tr>
        </div>
        {/* {piInfo.note ? (<tr>
          <td colspan="3" align="left">
            <br/>
            <div style={{ textAlign: 'left', width: '95%', border: '1px solid #333', padding: '8px 15px', marginTop: 0 }}>
              Remarks. {piInfo.note}	
            </div>
          </td>
        </tr>) : null} */}
      </tbody>
    </table>


    <table style={{marginTop:'28px'}} align="right" width="100%" border="0" cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td style={{fontSize:'12px'}} width="50%" align="center">
            <strong>{groupPiInfo[0].party_name}</strong><br/>
            <div style={{width:'300px', borderTop:'1px solid black', marginTop:'85px'}}>Accepted By</div>
          </td>
          <td>
            <td width="50%" align="center">
              <br />
              <div style={{ width: '300px', paddingBottom: '15px' }}>&nbsp;</div>
            </td>
            <td width="50%" align="center">
              <strong style={{ fontSize: '15px' }}>{companyTitle}</strong>
              <br />
              <img style={{ maxWidth: '150px' }} src="/Images/miah_and_sons_signature.png" alt="AUTHORISED SIGNATURE" />
              <br />
              <span style={{ borderTop: '1px solid black', fontSize:'12px' }}>AUTHORISED SIGNATURE</span>
            </td>
            </td>
        </tr>
      </tbody>
    </table>
  </body>
  );
};

export default GroupPiPDF;