import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';

const ChallanPDF = () => {
  const { challanId } = useParams();
  const [challan_table_info, set_challan_table_info] = useState([]);
  const [challan_details_info, set_challan_details_info] = useState({});
  const [loading, setLoading] = useState(true);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  const [categoryTotals,setCategoryTotals] = useState('');

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (challanId) {
      fetch_challan_pdf_info();
    }
  }, [challanId]);

  const fetch_challan_pdf_info = () => {
    axios.get(`${window.backendUrl}/get_challan_pdf_info/${challanId}`)
      .then((response) => {
        set_challan_table_info(response.data.challanDetails);
        set_challan_details_info(response.data.challanInfo);
        // Calculate delivery quantity totals for each category
        const newCategoryTotals = response.data.challanDetails.reduce((acc, item) => {
          const category = item.category || "Unknown"; // Handle undefined categories
          if (!acc[category]) {
            acc[category] = { totalDeliveryQty: 0 };
          }
          acc[category].totalDeliveryQty += item.DeliveryQty || 0; // Ensure DeliveryQty is valid
          return acc;
        }, {});

        // Format the totals into the desired string
        const categoryTotalString = Object.keys(newCategoryTotals)
          .map(category => `${category} (${newCategoryTotals[category].totalDeliveryQty})`)
          .join(", ");

        // Set the string in the state
        setCategoryTotals(categoryTotalString);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) {
    return <></>
  }


  return (
    <div style={{ width: '100%' }}>
      <table style={{ width: '100%', padding: 0, borderSpacing: 1 }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'center' }}>
              <h1 className='challan_header' style={{fontSize:'25px', color:'blue'}}> {companyTitle} </h1>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>
              <table style={{ width: '100%', fontSize:'12px', padding: 0, borderSpacing: 0 }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      <p>
                        <strong style={{ fontSize: '15px', color: '#61190d' }}>Office :</strong><br />
                        Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.<br />
                        {companyTitle === 'MIAH & SONS INDUSTRIES LTD.'
                        ? 'Email: miahandsons@gmail.com, md.msil@aligroupbd.net'
                        : 'Email: tanverpack@yahoo.com'}
                      </p>
                    </td>
                    <td className='vertical_align_bottom' style={{textAlign: 'center', verticalAlign: 'bottom' }}>
                      <h2 className='challan_header' style={{ width: '180px', border: '1px solid #333', fontSize:'20px', lineHeight:'30px', padding: '8px 10px',margin:'0px 5px' }}>Delivery Challan</h2>
                    </td>
                    <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
                      <p>
                        <strong style={{ fontSize: '15px', color: '#61190d' }}>Factory :</strong><br />
                        Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.<br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <hr style={{ border: '1px solid black', width: '100%', marginTop:'100px' }} /> */}
            </td>
          </tr>
          <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
          <tr>
            <td>
              <table style={{ width: '100%', fontSize:'12px', padding: 0, borderSpacing: 0 }}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Party Name: </strong>{challan_details_info.PartyName}<br />
                      <strong>Party Address: </strong>{challan_details_info.Address}<br />
                      <strong>Buyer Name: </strong><span id="buyerName">{challan_details_info.BuyerName}</span><br />
                      <strong>Style/Order/File No.: </strong><span id="style_no">{challan_details_info.Style}</span><br />
                      <strong>LPD No.: </strong>{challan_details_info.LpdPoNo}<br />
                      <strong>Delivery Address: </strong><span>{challan_details_info.DeliveryStation}</span><br />
                      {challan_details_info?.Note ? (<><strong>Note: </strong><span>{challan_details_info.Note}</span></>) : null}
                    </td>
                    <td style={{ width: '40%', textAlign: 'right' }}>
                      <strong>Job Number: </strong>{challan_details_info.JobNumber}<br />
                      <strong>Challan No.: </strong>OPC-{challan_details_info.DeliveryChallanNo}<br />
                      <strong>Challan Date: </strong>{challan_details_info.ChallanDeliveryDate}<br />
                      <strong>Vehicle No.: </strong><span>{challan_details_info.VehicleNo}</span><br />
                      <strong>Driver Name: </strong><span>{challan_details_info.DriverName}</span><br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: '15px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead style={{fontSize:'12px'}}>
                  <tr>
                    <th style={{border:'1px solid black',width:'3%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>SL#</th>
                    <th style={{border:'1px solid black',width:'10%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Item Name</th>
                    <th style={{border:'1px solid black',width:'8%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Specification</th>
                    <th style={{border:'1px solid black',width:'12%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Measurement (LxWxH)</th>
                    <th style={{border:'1px solid black',width:'30%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Particular</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Order Qty</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Previous Delivery</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Current Delivery</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Balance Qty</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Pkt Qty.</th>
                    <th style={{border:'1px solid black',width:'5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)',  textAlign: 'center' }}>Remarks</th>
                  </tr>
                </thead>
                <tbody class='challan_pdf_table'>
                  {challan_table_info.map((item, index) => (
                    <React.Fragment key={item.id}>
                      {index === 0 || challan_table_info[index - 1].id !== item.id ? (
                        <tr>
                          <td rowSpan={item.particulars.length} align="center">{index + 1}</td>
                          <td rowSpan={item.particulars.length}>{item.item}</td>
                          <td rowSpan={item.particulars.length}>{item.spec}</td>
                          <td rowSpan={item.particulars.length} nowrap>{item.measurement} {item.unit}</td>
                          <td>{item.particulars[0].remarks}</td>
                          <td rowSpan={item.particulars.length} align="center">{item.orderQty}</td>
                          <td rowSpan={item.particulars.length} align="center">{item.prevDeliQty}</td>
                          <td align="center">{item.particulars[0].qty}</td>
                          <td rowSpan={item.particulars.length} align="center">{item.balanceQty}</td>
                          <td align="center">{item.particulars[0].packet === 0 ? '-' : item.particulars[0].packet}</td>
                          <td rowSpan={item.particulars.length}></td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{item.particulars[0].packetid}</td>
                          <td align="center">{item.particulars[0].qty}</td>
                          <td align="center">{item.particulars[0].packet === 0 ? '-' : item.particulars[0].packet}</td>
                          {/* <td>{item.particulars[0].remarks}</td> */}
                        </tr>
                      )}
                      {item.particulars.slice(1).map((particular, pIndex) => (
                        <tr key={pIndex}>
                          <td>{particular.remarks}</td>
                          <td align="center">{particular.qty}</td>
                          <td align="center">{particular.packet === 0 ? '-' : particular.packet}</td>
                          {/* <td></td> */}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td colSpan="5" align="right">
                      <strong>
                        <span style={{ float: 'left' }}>
                        Challan Total: {categoryTotals}
                        </span>
                        Total:
                      </strong>
                    </td>
                    <td align="center"><strong>{challan_table_info?.reduce((acc, item) => acc + item.orderQty, 0)}</strong></td>
                    <td align="center"><strong>{challan_table_info?.reduce((acc, item) => acc + item.prevDeliQty, 0)}</strong></td>
                    <td align="center"><strong>{challan_table_info?.reduce((acc, item) => acc + item.DeliveryQty, 0)}</strong></td>
                    <td align="center"><strong>{challan_table_info?.reduce((acc, item) => acc + item.balanceQty, 0)}</strong></td>
                    <td align="center"><strong>{challan_table_info?.reduce((acc, item) => acc + item.particulars?.reduce((subAcc, particular) => subAcc + particular.packet, 0), 0) || '-'}</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className='challan_footer' style={{ padding: '20px 0 20px' }}>
              <table style={{ width: '100%', fontSize:'12px',borderSpacing: 0 }}>
                <tbody>
                  <tr style={{marginBottom:'25px'}}>
                    <td className='faithfully_challan' style={{ textAlign: 'left', width: '55%'}}>
                      <p><strong>Your Faithfully,</strong></p>
                    </td>
                    <td className='faithfully_challan' style={{ textAlign: 'right' }}>
                      <p>Received above materials in good order &amp; condition.</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      <p><strong>{companyTitle}</strong></p>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <p><strong>Please sign & return a copy</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      This is computer generated printout AND no signature is required.
                    </td>
                    <td style={{ textAlign: 'right' }}></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ChallanPDF;

