import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Table } from 'react-bootstrap';
import axios from '../axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Functions/manage_orders.css';
import NavigationBar from '../NavigationBar';
import AddNewCommercialForm from '../Forms/add_new_commercial_form';
import {renderPagination, renderAdvancedPagination} from '../utilities';
import { formatNumberWithCommas } from '../utilities';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';


function Commercial() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [team_options, set_team_options]=useState([]);
    const [party_bank_options, set_party_bank_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [filters, setFilters] = useState({
        partyname: '',
        party_bank_name: ''
    });
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetch_dropdown_options();
    }, []); 

    const fetch_dropdown_options = async () => {
        try {
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_party_banks`);

            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((party_bank) => party_bank['Name']);

            set_party_options(options2);
            set_party_bank_options(options3);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    // useEffect(() => {
    //     fetchDataWithFilters()
    // }, [filters, currentPage]);

    const fetchDataWithFilters = (page, filters) => {
        const {partyname, party_bank_name} = filters; 
        // const page = currentPage
        
        axios.get(`${window.backendUrl}/get_all_commercials`, {
            params: {partyname, party_bank_name, page}
        })
        .then((response) => {
            // console.log(response.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            // setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, debouncedFetchData]); // Only trigger when these dependencies change

    const [selected_commercial_id, set_selected_commercial_id] = useState(null);

    const handle_edit_click = (commercial_id) => {
        set_selected_commercial_id(commercial_id);
    }

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row  style={{margin:'20px 0px'}}>
                <Col xs='auto' style={{margin:'0px 20px'}}>
                    <h4 className='mb-3'>Commercial</h4>
                    <AddNewCommercialForm commercial_id={selected_commercial_id}/>
                </Col>
                <Col style={{marginTop:'50px'}}>
                    <Container style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '10px 25px', borderRadius: '5px' }}>
                        <Row>
                            <Col>
                                <h5>Commercial Information</h5>
                            </Col>
                            <Col>
                                {renderPagination({ totalPages, currentPage, setCurrentPage })}
                                {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                            </Col>
                        </Row>
                        <Row className='mt-2 mb-2'>
                            <Form className='d-flex'>
                                <Form.Select style={{width:'250px', marginRight:'25px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                                    <option value="">All Parties</option>
                                    {party_options.map((party, index) => (
                                        <option key={index} value={party}>
                                        {party}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Select style={{width:'250px'}} name="party_bank_name" value={filters.party_bank_name} onChange={handleInputChange}>
                                    <option value="">All Party Banks</option>
                                    {party_bank_options.map((party_bank, index) => (
                                        <option key={index} value={party_bank}>
                                        {party_bank}
                                        </option>
                                    ))}
                                </Form.Select>
                                
                            </Form>
                        </Row>
                        <Row>
                            <Table bordered striped condensed="true" style={{ fontSize: '13px', overflowY: 'auto', backgroundColor:'white'  }}>
                                <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                    <tr>
                                        <th>ID#</th>
                                        <th>Invoice No. / Party <br/> Name</th>
                                        <th>PI Info.</th>
                                        <th>LC No. / Date</th>
                                        <th>Export LC/SC <br/>No. / Date</th>
                                        <th>Net / Gross <br/>Weight</th>
                                        <th>Duration / <br/>Acceptance Terms</th>
                                        <th>LC Value</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                        <td>{order.CommercialId}<hr style={{border: '1px solid black' }}/>{order.ComType != 'General' ? order.ComType : ''}</td>
                                        <td>{order.InvoiceNo}<hr style={{border: '1px solid black' }}/>{order.PartyName}</td>
                                        <td style={{width:'220px'}}>{order.PIs.map(pi => pi.PiNo).join(', ')}</td>
                                        <td>{order.LcNo}<hr style={{border: '1px solid black' }}/>{order.LcDate && (new Date(order.LcDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.'))}</td>
                                        <td>{order.ContractNo}<hr style={{border: '1px solid black' }}/>{order.ContractDate && (new Date(order.ContractDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.'))}</td>
                                        <td>{order.Netweight}<hr style={{border: '1px solid black' }}/>{order.Grossweight}</td>
                                        <td>{order.Duration} Days<hr style={{border: '1px solid black' }}/>{order.AcceptanceTerms}</td>
                                        <td>${formatNumberWithCommas(order.TotalPrice.toFixed(2))}</td>
                                        <td>
                                            {/* <i className="print-button fa fa-print"></i> */}
                                            <i style={{fontSize:'15px'}} className="print-button fa fa-print" onClick={() => window.open(`/commercial-pdf-viewer/${order.CommercialId}`, '_blank')}></i>
                                            <i class="fa fa-edit" id="commercial_edit_icon" onClick={()=>handle_edit_click(order.CommercialId)}></i>
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default Commercial