import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, Edit_Bill_Modal, formatNumberWithCommas} from '../utilities';
import { DateRangePicker } from 'rsuite';
import '../Functions/manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function BillList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        itemname: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };


    const fetchDataWithFilters= (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, itemname} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_all_bills`, {
            params: { teamname, partyname, itemname, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            // console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const [showModal, setShowModal] = useState(false);
    const [modal_bill_id, set_modal_bill_id]=useState('');

    const handle_edit_bill_click=(bill_id)=>{
        set_modal_bill_id(bill_id);
        setShowModal(true);
    }

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Bill List</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'350px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'350px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'350px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '13px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr style={{fontSize:'14px'}}>
                            <th>SL</th>
                            <th>Bill ID</th>
                            <th>Group <br/>  Bill <br/> /Date</th>
                            <th>Bill No./Date</th>
                            <th>Status / Date</th>
                            <th>Job / <br/> LPD No.</th>
                            <th>PO/Style#</th>
                            <th>Company/ <br/> Buyer</th>
                            <th>Items</th>
                            <th>Spec.</th>
                            <th>Measurement</th>
                            <th>Deli.<br/>  Qty.</th>
                            <th>Sq.Mt.</th>
                            <th>Unit <br/> Price</th>
                            <th>Total <br/> Amount</th>
                            <th>Remarks</th>
                        </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, orderIndex) => {
                                let totalAmount = 0;
                                let totalQuantity = 0;

                                const orderRows = order.items.map((item, itemIndex) => (
                                item.specifications.map((spec, specIndex) => (
                                    spec.gsms.map((gsm, gsmIndex) => {
                                    totalAmount += gsm.quantity * parseFloat(gsm.unitPrice);
                                    totalQuantity += gsm.quantity;

                                    const rowSpanLength = order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0) + 1;

                                    return (
                                        <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`}>
                                            {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                <>
                                                <td rowSpan={rowSpanLength}>{order.serial_no}</td>
                                                <td rowSpan={rowSpanLength}>{order.billId}<br/><i className="fa fa-edit" id="edit_icon" onClick={() => handle_edit_bill_click(order.billId)}></i></td>
                                                <td rowSpan={rowSpanLength}></td>
                                                <td rowSpan={rowSpanLength}>BILL/{order.jobNumber}<hr style={{border: '1px solid black' }}/>{new Date(order.createdDate * 1000).toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka', year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.')}</td>
                                                <td rowSpan={rowSpanLength}>{order.status}</td>
                                                <td rowSpan={rowSpanLength}>{order.jobNumber}<hr style={{border: '1px solid black' }}/>{order.lpdPoNo}</td>
                                                <td rowSpan={rowSpanLength}>{order.style}</td>
                                                <td rowSpan={rowSpanLength}>{order.partyName}<hr style={{border: '1px solid black' }}/>{order.buyerName}</td>
                                                </>
                                            )}
                                            {gsmIndex === 0 && specIndex === 0 && (
                                                <td rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.itemName}</td>
                                            )}
                                            {gsmIndex === 0 && (
                                                <td rowSpan={spec.gsms.length}>{spec.specification}</td>
                                            )}
                                            <td>{gsm.measurement}</td>
                                            <td>{gsm.quantity}</td>
                                            <td>{gsm.square_meter}</td>
                                            <td>৳{gsm.unitPrice}</td>
                                            <td>৳{formatNumberWithCommas((gsm.quantity * parseFloat(gsm.unitPrice)).toFixed(2))}</td>
                                            <td>{gsm.remarks}</td>
                                        </tr>
                                    );
                                    })
                                ))
                                ));

                                return (
                                <>
                                    {orderRows}
                                    <tr className='green_row'>
                                        <td colSpan="3" style={{ textAlign: 'right' }}><strong>Bill Total: </strong></td>
                                        <td><strong>{totalQuantity}</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>৳{formatNumberWithCommas(totalAmount.toFixed(2))}</strong></td>
                                        <td></td>
                                    </tr>
                                </>
                                );
                            })}
                            </tbody>

                    </Table>
                    {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                    <Edit_Bill_Modal show={showModal} setShow={setShowModal} bill_id={modal_bill_id}/>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default BillList