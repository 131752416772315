import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ManagePartyTable from '../Tables/manage_party_table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavigationBar from '../NavigationBar';
import ManageBuyerTable from '../Tables/manage_buyer_table';
import ManageBuyerForm from '../Forms/manage_buyer_form';
import axios from '../axios';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import {renderPagination, renderTableData, renderAdvancedPagination } from '../utilities';

function Demo_Table() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // const limit = 20;
    
    
    const [minAge, setMinAge] = useState('');
    const [location, setLocation] = useState('');
    
    useEffect(() => {
      fetchData(currentPage);
    }, [currentPage]);

  
    const fetchData = async (page) => {
      try {
        const response = await axios.get(`${window.backendUrl}/demo_table?page=${page}`, {
          params: {
            min_age: minAge,
            location: location
          }
        });
        setData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
      
    const handleLocationChange = (value) => {
      if (value=='All'){
        setLocation('');
        setCurrentPage(1)
      }
      else{
        setLocation(value)
        setCurrentPage(1)
      }
    };

    const handleMinAgeChange = (value) => {
        setMinAge(value)
        setCurrentPage(1)
    }
      

    return (
        <>
            <NavigationBar />
            <Container fluid>
                <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                    <Col md={2}>
                        <h4>Demo Table</h4>
                    </Col>
                    <Col md={7}>
                        <Form>
                             <Row>
                                <Col md={2}>
                                    <Form.Group controlId="formMinAge">
                                        <Form.Control style={{maxWidth:'150px'}} type="number" placeholder="Min. Age" onChange={(e) => handleMinAgeChange(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group controlId="formLocation">
                                        <Form.Control as="select" style={{maxWidth:'600px'}} defaultValue="Select a location" onChange={(e) => handleLocationChange(e.target.value)}>
                                            <option value="Select a location" disabled>Select a location</option>
                                            <option value="All">All</option>
                                            <option value="Uttara">Uttara</option>
                                            <option value="Gulshan">Gulshan</option>
                                            <option value="Banani">Banani</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* <Col md={3}>
                            {renderPagination({ totalPages, currentPage, setCurrentPage })}
                            {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                    </Col> */}
                    <Col md={3}>
                      <div style={{ position: 'absolute', right:220 }}>
                          {renderPagination({ totalPages, currentPage, setCurrentPage })}
                          {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                      </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData(currentPage, data,'name','location','age')}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Demo_Table