import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords, formatNumberWithCommas, getAbbreviationForPi} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';

const PiPDF = () => {
  const { piId } = useParams();
  const [piInfo, setPiInfo] = useState([]);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true);
  const [categoryTotals,setCategoryTotals] = useState('');

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (piId) {
      fetch_pi_pdf_info();
    }
  }, [piId]);

  const fetch_pi_pdf_info = () => {
    axios.get(`${window.backendUrl}/get_pi_pdf_info/${piId}`)
      .then((response) => {
        console.log(response.data);
        setPiInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // setLoading(false);
  };

  if (loading) {
    return <></>
  }


  return (
    <body>
    <table width="100%" border="0" cellSpacing="5" cellPadding="0" style={{ width: '100%', fontSize:'12px', borderCollapse: 'collapse', marginTop:'15px' }}>
      <tbody>
        <tr>
          <td align="center" valign="top" width="130">
            <img
              style={{ maxHeight: '70px' }}
              src="/Images/miahandsonslogo.png"
              alt="MIAH & SONS INDUSTRIES LTD."
            />
          </td>
          <td align="left" valign="top" nowrap="nowrap">
            <p>
              <strong style={{ fontSize: '22px' }}>{companyTitle}</strong>
              <br />
              <strong style={{ fontSize: '15px', color: '#61190d' }}>Factory Address: </strong>
              {companyTitle === 'MIAH & SONS INDUSTRIES LTD.'
              ? 'Holding No. 110/1, Barabo, Gazipur-1703, Bangladesh.'
              : '746/747 NOLVOG, DHORANGERTEK, TURAG, UTTARA, DHAKA-1230'}
              <br />
              <strong style={{ fontSize: '15px', color: '#F36F26' }}>Mobile No.:</strong>{' '}
              <span style={{ color: '#E9590A' }}>01711564795, 01866 990700</span>
              <strong style={{ fontSize: '15px', color: '#F36F26' }}> Email:</strong>{' '}
              <span style={{ color: '#E9590A' }}> {companyTitle === 'MIAH & SONS INDUSTRIES LTD.' ? 'miahandsons@gmail.com' : 'tanverpack@yahoo.com'}</span>
            </p>
          </td>
          <td width="40%" align="left" valign="middle">
            <h2 align="center" style={{ width: '270px',border: '1px solid #333',padding: '8px 20px', marginTop: '0', lineHeight:'18px', fontSize:'20px'}}>
              <strong>PROFORMA INVOICE</strong>
            </h2>
            
          </td>    
        </tr>
        
        <tr>
          <td colSpan="3" align="center">
          <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
          </td>
        </tr>
        <tr>
          <td colSpan="2" valign="top">
            <strong>TO: {piInfo.partyName}</strong>
            <br />
            <div style={{width:'400px',wordWrap: 'break-word', whiteSpace: 'normal' }}>
              <strong>Office Address: </strong>{piInfo.partyAddress}
            </div>
            <div style={{width:'400px',wordWrap: 'break-word', whiteSpace: 'normal' }}>
              <strong>Delivery Address: </strong> {piInfo.delivery_station}
            </div>
            <strong>Mobile No.:</strong>
            <br />
            <strong>Account/Buyer:</strong> {piInfo.buyerName}
            <br />
            <strong>ATTN:</strong> {piInfo.attention}
            <strong> Contact Ref:</strong>
            <br />
          </td>
          <td align="left" valign="top">
            <strong>PI No.:</strong> PI/{getAbbreviationForPi(piInfo?.partyName)}/{piInfo?.jobNumber}
            <br />
            <strong>PI Date:</strong> {new Date(piInfo.createdDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
            <br />
            <br />
            <strong>IRC No.:</strong> 260326120555921
            <br />
            <strong>ERC No.:</strong> 260326210959520
            <br />
            <strong>BIN No.:</strong> 000686131-0103
            <br />
            <strong>TIN No.:</strong> 117556497007
            <br />
          </td>
        </tr>
        <tr>
          <td colSpan="3" align="left" style={{ paddingBottom: '15px' }}>
            This Proforma Invoice (PI) has been issued in relation to the Job No.{' '}
            <strong>{piInfo.jobNumber}</strong> &amp; PO / Style No. <strong>{piInfo.style}</strong> presented by the buyer/customer.
          </td>
        </tr>
        <tr>
          <td colSpan="2" align="left" style={{ paddingBottom: '15px' }}>
            <strong>Generated PI Information</strong>
          </td>
          <td align="right" style={{ paddingBottom: '15px' }}>
            <div
              align="center"
              style={{
                width: '180px',
                border: '1px solid #333',
                padding: '8px 15px',
                marginTop: '0',
              }}
            >
              <strong>H.S. Code: 4819.10.00</strong>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table width="100%" border="1" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse' }}>
      <thead style={{fontSize:'12px'}}>
        <tr>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Item Name</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Style / PO#</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Specification</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Measurement</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Quantity</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Tot. SqrMt/Lbs</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Unit Price</th>
          <th align="center" style={{padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Total Amount</th>
        </tr>
      </thead>
      <tbody className='challan_pdf_table' style={{ fontSize: '12px' }}>
        {piInfo.items.map((item) => (
          item.specifications.map((spec) => (
            spec.gsms.map((gsm, gsmIndex) => (
              <React.Fragment key={`${item.itemId}-${spec.specificationId}-${gsm.gsmId}`}>
                {gsmIndex === 0 ? (
                  <tr>
                    <td rowSpan={spec.gsms.length} align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {item.itemName}
                    </td>
                    <td rowSpan={spec.gsms.length} align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {item.itemStyle}
                    </td>
                    <td rowSpan={spec.gsms.length} align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {spec.specification}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {gsm.height === 0
                        ? `${gsm.length}x${gsm.width} ${gsm.unit}`
                        : `${gsm.length}x${gsm.width}x${gsm.height} ${gsm.unit}`}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {gsm.orderQuantity}/Pcs
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {(gsm.squareMeter * gsm.orderQuantity).toFixed(3)}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      ${gsm.unitPrice.toFixed(4)}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      ${(gsm.unitPrice * gsm.orderQuantity).toFixed(3)}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {gsm.height === 0
                        ? `${gsm.length}x${gsm.width} ${gsm.unit}`
                        : `${gsm.length}x${gsm.width}x${gsm.height} ${gsm.unit}`}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {gsm.orderQuantity}/Pcs
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      {(gsm.squareMeter * gsm.orderQuantity).toFixed(3)}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      ${gsm.unitPrice.toFixed(4)}
                    </td>
                    <td align="center" style={{ padding: '8px 10px', border: '1px solid #000' }}>
                      ${(gsm.unitPrice * gsm.orderQuantity).toFixed(3)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ))
        ))}

        {/* Calculating Grand Totals */}
        <tr>
          <td colSpan="4" align="right" style={{ padding: '8px 10px', border: '1px solid #000', fontWeight: 'bold' }}>
            Grand Total:
          </td>
          <td align="center" style={{ padding: '8px 10px', border: '1px solid #000', fontWeight: 'bold' }}>
            {piInfo.items.reduce((acc, item) => acc + item.specifications.reduce((specAcc, spec) => 
              specAcc + spec.gsms.reduce((gsmAcc, gsm) => gsmAcc + gsm.orderQuantity, 0), 0), 0)}
          </td>
          <td colSpan='2'></td>
          <td align="center" style={{ padding: '8px 10px', border: '1px solid #000', fontWeight: 'bold' }}>
            ${formatNumberWithCommas(piInfo.items.reduce((acc, item) => acc + item.specifications.reduce((specAcc, spec) => 
              specAcc + spec.gsms.reduce((gsmAcc, gsm) => gsmAcc + gsm.unitPrice * gsm.orderQuantity, 0), 0), 0).toFixed(2))}
          </td>
        </tr>
      </tbody>


    </table>
{/* /////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div style={{ width: '95%', height: '50px', margin: '15px auto 0', padding: '0', fontSize:'12px'}}>
      <table width="100%" border="1" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td align="left" className='in_words_pi'>
              <strong>
                In Words (USD): <strong>{convertNumberToWords(piInfo.items.reduce((acc, item) => acc + item.specifications.reduce((specAcc, spec) => 
                  specAcc + spec.gsms.reduce((gsmAcc, gsm) => gsmAcc + gsm.unitPrice * gsm.orderQuantity, 0), 0), 0).toFixed(2))}</strong>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <table border="0" cellSpacing="5" cellPadding="3" style={{ marginLeft: '50px', fontSize:'12px' }}>
      <tbody>
        <tr>
          <td align="left" colSpan="3">
            <br />
            <strong>C. Terms & Condition:</strong>
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">1. Payment</td>
          <td align="center" width="2%">:</td>
          <td align="left">
            At ({piInfo.credit_days}) Day Sight from the date of shipment by irrevocable letter of credit in USD.
            <br />
            Incorporating your Export L/C number & the Payment must be made at Maturity in USD Draft drawn under asper rules of bangladesh bank
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">2.SHIPMENT VALIDITY</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          25 (Twenty Five) day from the date of opening L/C
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">3.PRODUCTION</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          Subject to the receiving of L/C.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">4.DELIVERY</td>
          <td align="center" width="2%">:</td>
          <td align="left">
            From our factory to your factory site
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">5.PACKING</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          Standard pack
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">6.FREIGHT CHARGE</td>
          <td align="center" width="2%">:</td>
          <td align="left">
            Free of Cost
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">7.INCOTERMS CPT</td>
          <td align="center" width="2%">:</td>
          <td align="left">
            Carriage Paid To (2000 & 2010)
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">8.PI VALIDITY</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          35 (THIRTY-FIVE) Days {/* From the date of shipment. */}
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">9.Utilization Declaration</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          Product Details, Qty, Value, BTB L/C No. And Beneficiary's Name Must be Mentioned in the UD.
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">10.ADVISING BANK</td>
          <td align="center" width="2%">:</td>
          <td align="left">
           <strong>: {companyTitle} : {piInfo.bank_name}</strong>
            <br />
            A/C # {piInfo.bank_account_no}, {piInfo.bank_address}
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">11.SWIFT Code</td>
          <td align="center" width="2%">:</td>
          <td align="left">
            <strong>{piInfo.bank_swift_code}</strong>
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">12.Country of Origin</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          Made In Bangladesh
          </td>
        </tr>
        <tr>
          <td align="left" nowrap="nowrap" width="5%">13.Routing No.</td>
          <td align="center" width="2%">:</td>
          <td align="left">
          <strong>{piInfo.bank_routing_no}</strong>
          </td>
        </tr>
        {piInfo.note ? (<tr>
          <td colspan="3" align="left">
            <br/>
            <div style={{ textAlign: 'left', width: '95%', border: '1px solid #333', padding: '8px 15px', marginTop: 0 }}>
              Remarks. {piInfo.note}	
            </div>
          </td>
        </tr>) : null}
      </tbody>
    </table>


    <table style={{marginTop:'18px'}} align="right" width="100%" border="0" cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td width="50%" align="center">
            <br />
            <div style={{ width: '300px', paddingBottom: '15px' }}>&nbsp;</div>
          </td>
          <td width="50%" align="center">
            <strong style={{ fontSize: '15px' }}>{companyTitle}</strong>
            <br />
            <img style={{ maxWidth: '150px' }} src="/Images/miah_and_sons_signature.png" alt="AUTHORISED SIGNATURE" />
            <br />
            <span style={{ borderTop: '1px solid black', fontSize:'12px' }}>AUTHORISED SIGNATURE</span>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
  );
};

export default PiPDF;