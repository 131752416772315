import React, { useState, useEffect } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { IoMdAdd } from "react-icons/io";
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import {Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { ImCross } from "react-icons/im";
import './manage_orders.css';
import { useLocation, useNavigate} from 'react-router-dom';

const ChangeDeliveryChallan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [challanInfo, setChallanInfo] = useState({});
    const [deleted_packets, set_deleted_packets] = useState([]);
  
    const addParticular = (rowId) => {
      setRows((rows) =>
        rows.map((row) =>
          row.id === rowId
            ? {
                ...row,
                particulars: [...row.particulars, { remarks: '', qty: 0, packet: 0 }],
              }
            : row
        )
      );
    };
  
    const removeParticular = (rowId, index) => {
        const rowToDelete = rows.find((row) => row.id === rowId);
        const particularToDelete = rowToDelete?.particulars[index];
      
        if (particularToDelete && particularToDelete.packetid) {
          set_deleted_packets((prevDeleted) => [
            ...prevDeleted,
            particularToDelete.packetid,
          ]);
        }

        setRows((rows) =>
            rows.map((row) =>
                row.id === rowId
                ? {
                    ...row,
                    particulars: row.particulars.filter((_, i) => i !== index),
                    }
                : row
            )
        );
    };
  
    const handleRemarksChange = (rowId, index, value) => {
      const newParticulars = [...rows.find((row) => row.id === rowId).particulars];
      newParticulars[index].remarks = value;
      setRows((rows) =>
        rows.map((row) => (row.id === rowId ? { ...row, particulars: newParticulars } : row))
      );
    };
  
    const handleQtyChange = (rowId, index, value) => {
      const newParticulars = [...rows.find((row) => row.id === rowId).particulars];
      newParticulars[index].qty = value ? parseInt(value) : 0
      setRows((rows) =>
        rows.map((row) => (row.id === rowId ? { ...row, particulars: newParticulars } : row))
      );
    };
  
    const handlePacketChange = (rowId, index, value) => {
      const newParticulars = [...rows.find((row) => row.id === rowId).particulars];
      newParticulars[index].packet = value ? parseInt(value) : 0;
      setRows((rows) =>
        rows.map((row) => (row.id === rowId ? { ...row, particulars: newParticulars } : row))
      );
    };
    
    const handleChallanInfoChange = (field, value) => {
        setChallanInfo((prev) => ({
          ...prev,
          [field]: value,
        }));
      };
    
    const handleSaveChanges = () => {
        const data = {
            rows: rows,  
            challaninfo: challanInfo,
            deleted_packets : deleted_packets
        };
    
        axios.post(`${window.backendUrl}/update_delivery_challan`, data)
            .then(response => {
                console.log('Update successful:', response.data);
                navigate('/manage-delivery-challan')
                // Handle any UI updates or navigation upon successful save
            })
            .catch(error => {
                console.error('Error updating delivery challan:', error);
                // Handle any error scenarios here
        });
    };

    const handleDeleteChallan = () => {
        const data = {
            DeliveryChallanId: challanInfo.DeliveryChallanId
        };
    
        axios.post(`${window.backendUrl}/delete_challan`, data)
            .then(response => {
                console.log('Deletion successful:', response.data);
                navigate('/manage-delivery-challan')
                // Handle any UI updates or navigation upon successful deletion
            })
            .catch(error => {
                console.error('Error deleting delivery challan:', error);
                // Handle any error scenarios here
            });
    };
    
    useEffect(() => {
      if (location.state && location.state.challanId) {
          const challanId= location.state.challanId

          axios.get(`${window.backendUrl}/get_challan_info/${challanId}`)
          .then(response => {
            //   console.log('Order fetched successfully:', response.data);
              setRows(response.data.challanDetails)
              setChallanInfo(response.data.challanInfo);
              // setOrderData(response.data.orderData);
              // setOrderItems(response.data.orderItems);
              // set_update_mode(true);//dont forget to set this when update button is clicked
          })
          .catch(error => {
              console.error('There was an error fetching the order:', error);
              // Handle any additional error actions here
          });
      }
    }, [location.state]);

    return (
      <>
        <NavigationBar/>
        <Container fluid>
            <div className='d-flex justify-content-between'>
                <h4 style={{ margin: '30px 0' }}>Change Delivery Challan Information</h4>
                <div className='d-flex align-items-center'>
                    <Button className='manage_deli_challan_btn' href="/manage-delivery-challan">
                                Manage Delivery Challan
                    </Button>
                </div>
            </div>
            <Row className="mb-3 fs-6">
                <Col xs='auto'>
                    <div className='d-flex'>
                        <p className='me-2'>Challan #:</p>
                        <p>{challanInfo.DeliveryChallanNo} </p>
                    </div>
                </Col>
                <Col xs='auto' className='me-5'>
                    <div className='d-flex'>
                        <p className='me-2'>Job #:</p>
                        <p>{challanInfo.JobNumber}</p>
                    </div>
                </Col>
                <Col xs='auto' className='me-5'>
                    <div className='d-flex'>
                        <p className='me-2'>Job Date:</p>
                        <p>{new Date(challanInfo.OrderDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</p>  
                    </div>
                </Col>
                <Col xs='auto' className='me-5'>
                    <div className='d-flex'>
                        <p className='me-2'>Ex.Deli. Date:</p>
                        <p>{new Date(challanInfo.DeliveryDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</p>
                    </div>
                </Col>
                <Col xs='auto' className='me-5'>
                    <div className='d-flex'>
                        <p className='me-2'>Party Name:</p>
                        <p>{challanInfo.PartyName}</p>
                    </div>
                </Col>
                <Col xs='auto' className='me-5'>
                    <div className='d-flex'>
                        <p className='me-2'>Buyer Name:</p>
                        <p>{challanInfo.BuyerName}</p>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3 fs-6">
                    <Col xs='auto'>
                        <Form.Group className='d-flex align-items-center'>
                            <strong>Challan Date:</strong>
                            <Form.Control
                                value={challanInfo.ChallanDeliveryDate}
                                type="date"
                                style={{width:'200px',marginLeft:'10px'}}
                                onChange={(e) => handleChallanInfoChange('ChallanDeliveryDate', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs='auto'>
                        <Form.Group className='d-flex align-items-center'>
                            <strong>Vehicle No.:</ strong>
                            <Form.Control
                                value={challanInfo.VehicleNo}
                                type="text"
                                style={{width:'200px',marginLeft:'10px'}}
                                onChange={(e) => handleChallanInfoChange('VehicleNo', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs='auto'>
                        <Form.Group className='d-flex align-items-center'>
                            <strong>Driver Name:</ strong>
                            <Form.Control
                                value={challanInfo.DriverName}
                                type="text"
                                style={{width:'300px',marginLeft:'10px'}}
                                onChange={(e) => handleChallanInfoChange('DriverName', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs='auto'>
                        <Form.Group className='d-flex align-items-center'>
                            <strong>Note:</ strong>
                            <Form.Control
                                value={challanInfo.Note}
                                type="text"
                                style={{width:'400px',marginLeft:'10px'}}
                                onChange={(e) => handleChallanInfoChange('Note', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
            </Row>
            <Table bordered hover>
            <thead>
                <tr>
                <th>Items</th>
                <th>Specif.</th>
                <th>Measurement</th>
                <th>Order Qty</th>
                <th>Prod. Done</th>
                <th>Prev. Deli. <br /> Qty</th>
                <th>Ready for Deli <br /> Qty</th>
                <th style={{ width: '300px' }}>Particular</th>
                <th style={{ width: '200px' }}>Challan Deli Qty</th>
                <th style={{ width: '200px' }}>Packet</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row) => (
                <React.Fragment key={row.id}>
                    {row.particulars.map((particular, index) => (
                    <tr key={`${row.id}-${index}`}>
                        {/* Render only the first <td> in the first iteration */}
                        {index === 0 && (
                        <>
                            <td rowSpan={row.particulars.length}>{row.item}</td>
                            <td rowSpan={row.particulars.length}>{row.spec}</td>
                            <td rowSpan={row.particulars.length}>{row.measurement}</td>
                            <td rowSpan={row.particulars.length}>{row.orderQty}</td>
                            <td rowSpan={row.particulars.length}>{row.prodDone}</td>
                            <td rowSpan={row.particulars.length}>{row.prevDeliQty}</td>
                            <td rowSpan={row.particulars.length}>{row.readyForDeliQty}</td>
                        </>
                        )}
                        {/* Render each <td> for particulars */}
                        <td>
                        <Form.Control
                            as="textarea"
                            rows={1}
                            value={particular.remarks}
                            onChange={(e) => handleRemarksChange(row.id, index, e.target.value)}
                        />
                        </td>
                        <td>
                        <Form.Control
                            type="text"
                            value={particular.qty}
                            onChange={(e) => handleQtyChange(row.id, index, e.target.value)}
                        />
                        </td>
                        <td>
                        <div style={{ textAlign: 'right', position: 'relative' }}>
                            <ImCross
                            onClick={() => removeParticular(row.id, index)}
                            className="challan_cross_icon"
                            />
                        </div>
                        <Form.Control
                            type="text"
                            value={particular.packet}
                            onChange={(e) => handlePacketChange(row.id, index, e.target.value)}
                        />
                        {/* Add the plus icon only in the last row of particulars */}
                        {index === row.particulars.length - 1 && (
                            <div style={{ textAlign: 'right', position: 'relative' }}>
                            <FaPlus
                                onClick={() => addParticular(row.id)}
                                className="challan_plus_icon"
                            />
                            </div>
                        )}
                        </td>
                    </tr>
                    ))}
                </React.Fragment>
                ))}
            </tbody>
            </Table>
            <Row className='d-flex mt-2'>
              <Button variant="danger"  onClick={handleDeleteChallan} style={{margin: '0 30px 0 30px',width:'130px' }}>
                  Delete challan
              </Button>
              <Button variant="warning" onClick={()=>navigate("/manage-delivery-challan")} style={{width:'100px',textDecoration:'none' }}>
                  Go back
              </Button>
              <Button variant="primary" onClick={handleSaveChanges} style={{width:'150px', marginLeft:'auto',marginRight:'30px'}}>
                  Save Changes
              </Button>
            </Row>
        </Container>
      </>
    );
  };
  
  export default ChangeDeliveryChallan;
