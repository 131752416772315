import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function ManageCompanyBankForm({ saveClick, setSaveClick, selectedEntry,setSelectedEntry }) {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        account_no: '',
        swift_code: '',
        routing_no:'',
    });
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (selectedEntry !== null) {
            setFormData({
                    name: selectedEntry['Name'],
                    address: selectedEntry['Address'],
                    account_no: selectedEntry['AccountNo'],
                    swift_code: selectedEntry['SwiftCode'], 
                    routing_no: selectedEntry['RoutingNo'],
                });
            setIsEditMode(true);
        }
    }, [selectedEntry]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = () => {
        if(formData.name !== ''){
            axios.post(`${window.backendUrl}/insert_manage_company_bank`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    address: '',
                    account_no: '',
                    swift_code: '',
                    routing_no:'',
                });
            })
        }
    };

    const handleUpdate = () => {
        if(formData.name !== ''){
            axios.put(`${window.backendUrl}/update_manage_company_bank/${selectedEntry['CompanyBankID']}`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    address: '',
                    account_no: '',
                    swift_code: '',
                    routing_no:'',
                });
                setIsEditMode(false);
                setSelectedEntry(null);
            })
        }
    };
    
    const handleDelete = () => {
        axios.delete(`${window.backendUrl}/delete_manage_company_bank/${selectedEntry['CompanyBankID']}`)
        .then((response) => {
            console.log('Success:', response.data);
        })
        .catch((error) => {
            console.error('Error:edidq', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData({
                name: '',
                address: '',
                account_no: '',
                swift_code: '',
                routing_no:'',
            });
            setIsEditMode(false);
            setSelectedEntry(null);
        })
    };
    
    const handleCancel = () => {
        setFormData({
            name: '',
            address: '',
            account_no: '',
            swift_code: '',
            routing_no:'',
        });
        setIsEditMode(false);
        setSelectedEntry(null);
    };

    return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
        <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Company Bank</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Company Bank Name :</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Address">
            <Form.Label>Company Bank Address :</Form.Label>
            <Form.Control as="textarea" rows={3} name="address" value={formData.address} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Account">
            <Form.Label>Account Number :</Form.Label>
            <Form.Control type="text" name="account_no" value={formData.account_no} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="SwiftCode">
            <Form.Label>Swift Code :</Form.Label>
            <Form.Control type="text" name="swift_code" value={formData.swift_code} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="RoutingNo">
            <Form.Label>Routing No. :</Form.Label>
            <Form.Control type="text" name="routing_no" value={formData.routing_no} onChange={handleInputChange} />
        </Form.Group>


        {isEditMode ? (
            <>
            <Button variant="primary" onClick={handleUpdate}>
                Update
            </Button>
            <Button variant="warning" onClick={handleCancel} style={{ marginLeft: '70px' }}>
                Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>
                Delete
            </Button>
            </>
        ) : (
            <Button variant="primary" type="submit">
            Save
            </Button>
        )}
        </Form>
    </div>
    );
}

export default ManageCompanyBankForm;