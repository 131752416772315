import React from 'react';
import {useState} from 'react';
import { convertNumberToWords, formatNumberWithCommas } from '../../utilities';

const BeneficiaryCertificate = ({table_info, commercial_info}) => {
    const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));

    return (
        <div style={{ paddingTop: "10px", display: "block",fontSize:'15px' }}>
            <table width="100%" border="0" cellSpacing="5" cellPadding="0">
            <tbody>
                <tr>
                <td align="center" valign="top">
                    <h1 className='challan_header' style={{ fontSize: '40px', margin: 0, color:'blue'}}>{companyTitle}</h1>
                    <p style={{ fontSize: '16px', margin: 0 }}>
                    Factory &amp; Office : Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.<br />
                    Phone: +8801819239970, +8801713130713, Email: palash.orixpackaging@gmail.com
                    </p>
                    <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
                </td>
                </tr>
            </tbody>
            </table>
            <table width="100%" border="0" cellspacing="5" cellpadding="0">
                <tbody>
                    <tr>
                        <td align="center">
                            <p style={{ textDecoration: 'underline', fontSize:'20px', fontWeight: 'normal', margin: '0 0 15px' }}>Beneficiary Certificate</p>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            DATE: <strong>{new Date(commercial_info.commercialDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td align="left" style={{ padding: "10px 0" }}>
                            <table className='certificate_table' width="100%" border="0" cellspacing="5" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td width="150" align="left" valign="top">BUYERS NAME & ADDRESS</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left">
                                            <strong>{table_info[0].party_name}</strong><br />
                                            {table_info[0].party_address}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="100" align="left" valign="top">Export LC/SC NO.</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left"><strong>{commercial_info.contractNo}</strong> <strong> DATE: <strong>{new Date(commercial_info.contractDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong></strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="left" valign="top" style={{ padding: "10px 15px 0 0" }}>
                                            ISSUING BANK BIN NO. <strong>{commercial_info.partyBankBin}</strong><br />
                                            BENEFICIARY'S VAT REG. NO. 18051014507, BIN NO. 000353482-0102, H.S CODE NO. 4819.10.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="100" align="left" valign="top">DOCUMENTARY CREDIT NO.</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left"><strong>{commercial_info.lcNo}</strong>, DATE : <strong>{new Date(commercial_info.lcDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="150" align="left" valign="top">VALUE.</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left"><strong>${formatNumberWithCommas(commercial_info.totalPrice)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td width="100" align="left" valign="top">ITEM</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left">CORRUGATED CARTON </td>
                                    </tr>
                                    <tr>
                                        <td width="100" align="left" valign="top">QUANTITY</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left">
                                            <strong>
                                            {table_info.reduce((total, order) => {
                                                return total + order.items.reduce((itemTotal, item) => {
                                                    return itemTotal + item.specifications.reduce((specTotal, spec) => {
                                                    return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.orderQuantity, 0);
                                                    }, 0);
                                                }, 0);
                                            }, 0)}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="100" align="left" valign="top">L/C ISSUING BANK</td>
                                        <td align="left" valign="top">:</td>
                                        <td align="left">
                                            <strong>{commercial_info.partyBank}</strong><br />
                                            {commercial_info.partyBankAddress.split(',').reduce((acc, word, index) => {
                                                if (index > 0 && index % 4 === 0) acc.push(<br key={index} />);
                                                acc.push(word.trim());
                                                return acc;
                                            }, []).map((item, index) => (
                                                <span key={index}>
                                                {item}
                                                {index < commercial_info.partyBankAddress.split(',').length - 1 ? ',' : ''}
                                                </span>
                                            ))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="left" valign="top">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="left" valign="top">
                                            WE DO HEREBY CERTIFY THAT THE GOODS HAVE BEEN SHIPPED IN ACCORDANCE WITH THE TERMS AS PROFORMA INVOICE NO.  
                                            {commercial_info.piInfo.map((pi, index) => <span key={pi.piId}><strong> PI/{pi.jobNumber}   DATE: {new Date(pi.createdDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.')}</strong>{index < commercial_info.piInfo.length - 1 ? ', ' : ''} </span>)}
                                            CORRUGATED CARTON AND TOP BOTTOM FOR 100% EXPORT ORIENTED READYMADE GARMENTS INDUSTRY.<br/><br/>
                                            
                                            WE ALSO ASSURE THAT WE HAVE NO CLAIMED / WILL NOT CLAIM DUETY DRAW BACK AGAINST THE CONSIGNMENT SHIPPED UNDER ABOVE L/C.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td align="left">
                            <table width="100%" border="0" cellspacing="5" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td width="55%" align="left" valign="top">&nbsp;</td>
                                        <td align="right" valign="top">
                                            <p><br /><br /></p>
                                            ON BEHALF OF<br />
                                            <strong>{companyTitle}</strong>
                                            <br /><img style={{ maxWidth: "150px" }}  src="/Images/miah_and_sons_signature.png" alt="AUTHORISED SIGNATURE" /><br />
                                            <span style={{ borderTop: "1px solid black" }}>AUTHORISED SIGNATURE</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default BeneficiaryCertificate;
