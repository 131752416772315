import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal, formatNumberWithCommas} from '../utilities';
import { DateRangePicker } from 'rsuite';
import '../Functions/manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";

function OrderSummary() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    // write a new version of the code below
    useEffect(() => {
        fetchDataWithFilters(currentPage)
    }, [filters, currentPage, searchClick, dateRange]);

    const fetchDataWithFilters = () => {
        const { teamname, partyname, buyername, itemname, specification} = filters; 
        const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_order_summary_report`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            // console.log(response.data.data);
            setOrders(response.data.data);
            console.log(response.data.data)
            // setCurrentPage(response.data.currentPage);
            // setTotalPages(response.data.totalPages);
            // setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Initialize global totals for all teams
    let overallCategoryTotals = {};
    let overallTotalOrderQuantity = 0;
    let overallTotalFinishedQuantity = 0;
    let overallTotalDeliveryQuantity = 0;
    let overallTotalReadyForDeliveryQuantity = 0;
    let overallTotalDeliveryBalanceQuantity = 0;
    let overallTotalPriceUSD = 0;
    let overallTotalPriceBDT = 0;
    let overallTotalOverInvoiceUSD = 0;
    let overallTotalOverInvoiceBDT = 0;

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Order Summary Report</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'220px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'220px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'220px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'220px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select  style={{width:'220px'}}> 
                        <option value="">All category</option>
                    </Form.Select>
                    <Form.Select style={{width:'220px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '14px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th>SL</th>
                                <th>Team</th>
                                <th>Party</th>
                                <th>Items</th>
                                <th>Spec.</th>
                                <th>Order Qty</th>
                                <th>Prod. Qty</th>
                                <th>Deli. Qty</th>
                                <th>Ready for <br/> Deli. Qty</th>
                                <th>Deli. Bal. <br/> Qty</th>
                                <th>Actual Price</th>
                                <th>Over Invoice</th>
                                <th>Tot. Price</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:'12px'}}>
                            {orders.map((team, teamIndex) => {
                                const totalTeamSpecCount = team.parties.reduce((acc, party) => {
                                    return acc + 1 + party.items.reduce((itemAcc, item) => {
                                        return itemAcc + item.specifications.length;
                                    }, 0);
                                }, 0) + 1;

                                let teamTotalOrderQuantity = 0;
                                let teamTotalFinishedQuantity = 0;
                                let teamTotalDeliveryQuantity = 0;
                                let teamTotalReadyForDeliveryQuantity = 0;
                                let teamTotalDeliveryBalanceQuantity = 0;
                                let teamTotalPriceUSD = 0;
                                let teamTotalPriceBDT = 0;
                                let teamTotalOverInvoiceUSD = 0;
                                let teamTotalOverInvoiceBDT = 0;
                                const teamCategoryTotals = {};

                                return (
                                    <React.Fragment key={teamIndex}>
                                        {team.parties.map((party, partyIndex) => {
                                            let partyTotalOrderQuantity = 0;
                                            let partyTotalFinishedQuantity = 0;
                                            let partyTotalDeliveryQuantity = 0;
                                            let partyTotalReadyForDeliveryQuantity = 0;
                                            let partyTotalDeliveryBalanceQuantity = 0;
                                            let partyTotalPriceUSD = 0;
                                            let partyTotalPriceBDT = 0;
                                            let partyTotalOverInvoiceUSD = 0;
                                            let partyTotalOverInvoiceBDT = 0;

                                            const partyCategoryTotals = {};
                                            const partySpecCount = party.items.reduce((acc, item) => acc + item.specifications.length, 0) + 1;

                                            return (
                                                <>
                                                    {party.items.map((item, itemIndex) => {
                                                        return item.specifications.map((spec, specIndex) => {
                                                            // Update party category totals
                                                            if (!partyCategoryTotals[item.item_category]) {
                                                                partyCategoryTotals[item.item_category] = {
                                                                    totalOrderQuantity: 0,
                                                                };
                                                            }
                                                            partyCategoryTotals[item.item_category].totalOrderQuantity += spec.orderQuantity;

                                                            // Update team category totals
                                                            if (!teamCategoryTotals[item.item_category]) {
                                                                teamCategoryTotals[item.item_category] = {
                                                                    totalOrderQuantity: 0,
                                                                };
                                                            }
                                                            teamCategoryTotals[item.item_category].totalOrderQuantity += spec.orderQuantity;

                                                            // Update overall category totals
                                                            if (!overallCategoryTotals[item.item_category]) {
                                                                overallCategoryTotals[item.item_category] = {
                                                                    totalOrderQuantity: 0,
                                                                };
                                                            }
                                                            overallCategoryTotals[item.item_category].totalOrderQuantity += spec.orderQuantity;

                                                            // Update quantities
                                                            partyTotalOrderQuantity += spec.orderQuantity;
                                                            teamTotalOrderQuantity += spec.orderQuantity;
                                                            overallTotalOrderQuantity += spec.orderQuantity;

                                                            partyTotalFinishedQuantity += spec.finished_qty;
                                                            teamTotalFinishedQuantity += spec.finished_qty;
                                                            overallTotalFinishedQuantity += spec.finished_qty;

                                                            partyTotalDeliveryQuantity += spec.delivery_qty;
                                                            teamTotalDeliveryQuantity += spec.delivery_qty;
                                                            overallTotalDeliveryQuantity += spec.delivery_qty;

                                                            partyTotalReadyForDeliveryQuantity += spec.ready_for_deli_qty;
                                                            teamTotalReadyForDeliveryQuantity += spec.ready_for_deli_qty;
                                                            overallTotalReadyForDeliveryQuantity += spec.ready_for_deli_qty;

                                                            partyTotalDeliveryBalanceQuantity += spec.delivery_balance_qty;
                                                            teamTotalDeliveryBalanceQuantity += spec.delivery_balance_qty;
                                                            overallTotalDeliveryBalanceQuantity += spec.delivery_balance_qty;

                                                            partyTotalPriceUSD += parseFloat(spec.totalPriceUSD);
                                                            teamTotalPriceUSD += parseFloat(spec.totalPriceUSD);
                                                            overallTotalPriceUSD += parseFloat(spec.totalPriceUSD);

                                                            partyTotalPriceBDT += parseFloat(spec.totalPriceBDT);
                                                            teamTotalPriceBDT += parseFloat(spec.totalPriceBDT);
                                                            overallTotalPriceBDT += parseFloat(spec.totalPriceBDT);

                                                            partyTotalOverInvoiceUSD += parseFloat(spec.over_invoiceUSD);
                                                            teamTotalOverInvoiceUSD += parseFloat(spec.over_invoiceUSD);
                                                            overallTotalOverInvoiceUSD += parseFloat(spec.over_invoiceUSD);

                                                            partyTotalOverInvoiceBDT += parseFloat(spec.over_invoiceBDT);
                                                            teamTotalOverInvoiceBDT += parseFloat(spec.over_invoiceBDT);
                                                            overallTotalOverInvoiceBDT += parseFloat(spec.over_invoiceBDT);

                                                            return (
                                                                <tr key={`${teamIndex}-${partyIndex}-${itemIndex}-${specIndex}`}>
                                                                    {specIndex === 0 && itemIndex === 0 && partyIndex === 0 && (
                                                                        <>
                                                                            <td rowSpan={totalTeamSpecCount}>{teamIndex + 1}</td>
                                                                            <td rowSpan={totalTeamSpecCount}>{team.team_name}</td>
                                                                        </>
                                                                    )}
                                                                    {specIndex === 0 && itemIndex === 0 && (
                                                                        <td rowSpan={partySpecCount}>{party.party_name}</td>
                                                                    )}
                                                                    {specIndex === 0 && (
                                                                        <td rowSpan={item.specifications.length}>{item.item_name}</td>
                                                                    )}
                                                                    <td>{spec.specification}</td>
                                                                    <td>{spec.orderQuantity}</td>
                                                                    <td>{spec.finished_qty}</td>
                                                                    <td>{spec.delivery_qty}</td>
                                                                    <td>{spec.ready_for_deli_qty}</td>
                                                                    <td>{spec.delivery_balance_qty}</td>
                                                                    <td>
                                                                        <strong>${formatNumberWithCommas((spec.totalPriceUSD).toFixed(2))}</strong><br />
                                                                        <strong>৳{formatNumberWithCommas((spec.totalPriceBDT).toFixed(2))}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>${formatNumberWithCommas((spec.over_invoiceUSD).toFixed(2))}</strong><br />
                                                                        <strong>৳{formatNumberWithCommas((spec.over_invoiceBDT).toFixed(2))}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>${formatNumberWithCommas((spec.totalPriceUSD).toFixed(2))}</strong><br />
                                                                        <strong>৳{formatNumberWithCommas((spec.totalPriceBDT).toFixed(2))}</strong>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        });
                                                    })}
                                                    <tr className='green_row'>
                                                        <td colSpan="2" style={{ textAlign: 'right' }}>
                                                            <strong>
                                                                Party Total:{" "}
                                                                {Object.keys(partyCategoryTotals)
                                                                    .map(category => `${category} (${partyCategoryTotals[category].totalOrderQuantity})`)
                                                                    .join(", ")}
                                                            </strong>
                                                        </td>
                                                        <td><strong>{partyTotalOrderQuantity}</strong></td>
                                                        <td><strong>{partyTotalFinishedQuantity}</strong></td>
                                                        <td><strong>{partyTotalDeliveryQuantity}</strong></td>
                                                        <td><strong>{partyTotalReadyForDeliveryQuantity}</strong></td>
                                                        <td><strong>{partyTotalDeliveryBalanceQuantity}</strong></td>
                                                        <td>
                                                            <strong>${formatNumberWithCommas(partyTotalPriceUSD.toFixed(2))}</strong><br />
                                                            <strong>৳{formatNumberWithCommas(partyTotalPriceBDT.toFixed(2))}</strong>
                                                        </td>
                                                        <td>
                                                            <strong>${formatNumberWithCommas(partyTotalOverInvoiceUSD.toFixed(2))}</strong><br />
                                                            <strong>৳{formatNumberWithCommas(partyTotalOverInvoiceBDT.toFixed(2))}</strong>
                                                        </td>
                                                        <td>
                                                            <strong>${formatNumberWithCommas(partyTotalPriceUSD.toFixed(2))}</strong><br />
                                                            <strong>৳{formatNumberWithCommas(partyTotalPriceBDT.toFixed(2))}</strong>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                        <tr className='yellow_row'>
                                            <td colSpan="3" style={{ textAlign: 'right' }}>
                                                <strong>
                                                    Team Total:{" "}
                                                    {Object.keys(teamCategoryTotals)
                                                        .map(category => `${category} (${teamCategoryTotals[category].totalOrderQuantity})`)
                                                        .join(", ")}
                                                </strong>
                                            </td>
                                            <td><strong>{teamTotalOrderQuantity}</strong></td>
                                            <td><strong>{teamTotalFinishedQuantity}</strong></td>
                                            <td><strong>{teamTotalDeliveryQuantity}</strong></td>
                                            <td><strong>{teamTotalReadyForDeliveryQuantity}</strong></td>
                                            <td><strong>{teamTotalDeliveryBalanceQuantity}</strong></td>
                                            <td>
                                                <strong>${formatNumberWithCommas(teamTotalPriceUSD.toFixed(2))}</strong><br />
                                                <strong>৳{formatNumberWithCommas(teamTotalPriceBDT.toFixed(2))}</strong>
                                            </td>
                                            <td>
                                                <strong>${formatNumberWithCommas(teamTotalOverInvoiceUSD.toFixed(2))}</strong><br />
                                                <strong>৳{formatNumberWithCommas(teamTotalOverInvoiceBDT.toFixed(2))}</strong>
                                            </td>
                                            <td>
                                                <strong>${formatNumberWithCommas(teamTotalPriceUSD.toFixed(2))}</strong><br />
                                                <strong>৳{formatNumberWithCommas(teamTotalPriceBDT.toFixed(2))}</strong>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                            <tr className='red_row'>
                                <td colSpan="5" style={{ textAlign: 'right' }}>
                                    <strong>
                                        Team Total:{" "}
                                        {Object.keys(overallCategoryTotals)
                                            .map(category => `${category} (${overallCategoryTotals[category].totalOrderQuantity})`)
                                            .join(", ")}
                                    </strong>
                                </td>
                                <td><strong>{overallTotalOrderQuantity}</strong></td>
                                <td><strong>{overallTotalFinishedQuantity}</strong></td>
                                <td><strong>{overallTotalDeliveryQuantity}</strong></td>
                                <td><strong>{overallTotalReadyForDeliveryQuantity}</strong></td>
                                <td><strong>{overallTotalDeliveryBalanceQuantity}</strong></td>
                                <td>
                                    <strong>${formatNumberWithCommas(overallTotalPriceUSD.toFixed(2))}</strong><br />
                                    <strong>৳{formatNumberWithCommas(overallTotalPriceBDT.toFixed(2))}</strong>
                                </td>
                                <td>
                                    <strong>${formatNumberWithCommas(overallTotalOverInvoiceUSD.toFixed(2))}</strong><br />
                                    <strong>৳{formatNumberWithCommas(overallTotalOverInvoiceBDT.toFixed(2))}</strong>
                                </td>
                                <td>
                                    <strong>${formatNumberWithCommas(overallTotalPriceUSD.toFixed(2))}</strong><br />
                                    <strong>৳{formatNumberWithCommas(overallTotalPriceBDT.toFixed(2))}</strong>
                                </td>
                            </tr>



                        </tbody>





                    </Table>
                    {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default OrderSummary