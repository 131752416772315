import React from 'react';
import NavigationBar from '../NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Add_new_party_form from '../Forms/add_new_party_form';
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Grade from './grade';
import Image from 'react-bootstrap/Image';

function Specification_and_grade({Spec_and_grade_count, setSpec_and_grade_count,item_id, spec_id, partyItems, setPartyItems, handlePartyItemChange}) {
    const [spec_options, setSpec_options] = useState(['Ply 3', 'Ply 5', 'Ply 7','Ply 1','PP','PE','PP 100']);
    const increasespecCount = () => {
        setSpec_and_grade_count(prevCount => prevCount + 1);
    };

    const decreasespecCount = () => {
        setPartyItems((prevPartyItems) =>
            prevPartyItems.map((item, item_index) => {
              if (item_index == item_id && 'specification' in item) {
                return {
                  ...item,
                  specification: item.specification.filter((_, index) => index != spec_id),
                };
              }
              return item;
            })
        );
        setSpec_and_grade_count(prevCount => prevCount - 1);
    };

    const [gradeCount, setgradeCount] = useState(1);

    useEffect(() => {
        const item = partyItems[item_id];
        if (item && item.specification && item.specification[spec_id] && item.specification[spec_id].grade) {
          if (item.specification[spec_id].grade.length === 0) {
            setgradeCount(1);
          } else {
            setgradeCount(item.specification[spec_id].grade.length);
          }
        }
      }, [partyItems[item_id]?.['specification']?.[spec_id]?.['grade']?.length]);

    return (
        <>
            <Container fluid className='mt-3'>
                {spec_id != 0 && (<Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-end mb-1'>
                            <Image onClick={decreasespecCount} src="/Images/cross-button.png" alt="cross" style={{ position:'absolute', width: '17px', height: '17px',cursor:'pointer'}}/>
                        </Col>
                    </Row>
                </Container>)}
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Select name="specName"  value={partyItems[item_id]?.['specification']?.[spec_id]?.specName || ""}  data-item_id={item_id} data-spec_id={spec_id}  onChange={handlePartyItemChange} style={{width:'250px'}}>
                            <option>Select specification</option>
                            {spec_options.map((spec_option, index) => (
                                <option key={index}>{spec_option}</option>
                            ))}
                            {/* <option>ply 3</option>
                            <option>ply 5</option>
                            <option>ply 7</option> */}
                        </Form.Select>
                    </Col>
                    <Col xs={12} md={8}>
                        {/*Grade component */}
                        {Array.from({ length: gradeCount }, (_, index) => (
                            <Grade key={index} grade_id={index} gradeCount={gradeCount} setgradeCount={setgradeCount} item_id={item_id} spec_id={spec_id} partyItems={partyItems} setPartyItems={setPartyItems} handlePartyItemChange={handlePartyItemChange}  />
                        ))}
                    </Col>
                </Row>
            </Container>
            {spec_id == Spec_and_grade_count-1 && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image  onClick={increasespecCount}  src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
        </>
    )
}

export default Specification_and_grade