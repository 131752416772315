import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import ManagePartyTable from '../Tables/manage_party_table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavigationBar from '../NavigationBar';
import ManageGSMForm from '../Forms/manage_gsm_form';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../axios';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import {renderPagination, renderTableData, renderAdvancedPagination } from '../utilities';

function Manage_gsm() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [saveClick, setSaveClick] = useState(false);

    useEffect(() => {
        fetchData(currentPage);
      }, [currentPage,saveClick]);
    
    const fetchData = async (page) => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_manage_gsm_table`, {
            params: {
                page: page
            }
            });
            setData(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
    <>
    <NavigationBar />
    <Container fluid >
            <Row style={{marginTop:'20px'}}>
                <Col xs={2}></Col>
                <Col xs={7}>
                    <Row>
                        <Col xs='auto'>
                            <h3 style={{margin:'0px',textAlign:'left'}}>Manage GSM</h3>
                        </Col>
                        <Col className='d-flex align-items-end' xs='7'>
                            <Form>
                                <Row className="mb-3">
                                    <Col style={{marginLeft:'300px'}}>
                                        {/* PageStuff */}
                                        {renderPagination({ totalPages, currentPage, setCurrentPage })}
                                        {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <ManageBuyerTable /> */}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>GSM Value</th>
                                    <th>GSM Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableData(currentPage, data,'Name','GSMValue','GSMType')}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <ManageGSMForm saveClick={saveClick} setSaveClick={setSaveClick} />
                </Col>
            </Row>
    </Container>
    </>
    )
}

export default Manage_gsm