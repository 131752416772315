import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function ManageBrandForm({ saveClick, setSaveClick, selectedEntry,setSelectedEntry }) {
    const rollOptions = ['Roll Code','Other Accessories']
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        contact : '',
        rollorothers : 'Roll Code + Others',
    });
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (selectedEntry !== null) {
            setFormData({
                    name: selectedEntry['Name'],
                    address: selectedEntry['Address'],
                    contact: selectedEntry['ContactNo'],
                    rollorothers: selectedEntry['RollOrOthers'],
                });
            setIsEditMode(true);
        }
    }, [selectedEntry]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = () => {
        if(formData.name !== ''){
            axios.post(`${window.backendUrl}/insert_manage_brand`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    address: '',
                    contact : '',
                    rollorothers : 'Roll Code + Others',
                });
            })
        }
    };

    const handleUpdate = () => {
        if(formData.name !== ''){
            axios.put(`${window.backendUrl}/update_manage_brand/${selectedEntry['BrandID']}`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    address: '',
                    contact : '',
                    rollorothers : 'Roll Code + Others',
                });
                setIsEditMode(false);
                setSelectedEntry(null);
            })
        }
    };
    
    const handleDelete = () => {
        axios.delete(`${window.backendUrl}/delete_manage_brand/${selectedEntry['BrandID']}`)
        .then((response) => {
            console.log('Success:', response.data);
        })
        .catch((error) => {
            console.error('Error:edidq', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData({
                name: '',
                address: '',
                contact : '',
                rollorothers : 'Roll Code + Others',
            });
            setIsEditMode(false);
            setSelectedEntry(null);
        })
    };
    
    const handleCancel = () => {
        setFormData({
            name: '',
            address: '',
            contact : '',
            rollorothers : 'Roll Code + Others',
        });
        setIsEditMode(false);
        setSelectedEntry(null);
    };

    return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
        <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Brand</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Name* :</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Address">
            <Form.Label>Address :</Form.Label>
            <Form.Control type="text" name="address" value={formData.address} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Contact">
            <Form.Label>Contact :</Form.Label>
            <Form.Control type="text" name="contact" value={formData.contact} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="HeightOrThickness">
            <Form.Label>Roll/ Other Accessories :</Form.Label>
            <Form.Select name="rollorothers" value={formData.rollorothers} onChange={handleInputChange}>
            <option value="Roll Code + Others">Roll Code + Others</option>
            {rollOptions.map((rollOption, index) => (
                <option key={index} value={rollOption}>
                {rollOption}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        {isEditMode ? (
            <>
            <Button variant="primary" onClick={handleUpdate}>
                Update
            </Button>
            <Button variant="warning" onClick={handleCancel} style={{ marginLeft: '70px' }}>
                Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>
                Delete
            </Button>
            </>
        ) : (
            <Button variant="primary" type="submit">
            Save
            </Button>
        )}
        </Form>
    </div>
    );
}

export default ManageBrandForm;