import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from 'react';
import axios from '../axios';

function GSM({party_name, item_name, spec_name, gsmCount, setgsmCount, item_id, spec_id, gsm_id, orderItems, setOrderItems, handleOrderItemChange}) {
    const [unit_options, set_unit_options] = useState([]);
    const [grade_options, set_grade_options] = useState([]);
    const [grade_info, set_grade_info] = useState({});
    const [gsm_options, set_gsm_options] = useState([]);
    const [addiwork_options, set_addiwork_options] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const increasegsmCount = () => {
        setgsmCount(prevCount => prevCount + 1);
    };

    const decreasegsmCount = () => {
        setOrderItems((prevOrderItems) =>
          prevOrderItems.map((item, item_index) => {
            if (item_index === item_id && 'specification' in item) {
              return {
                ...item,
                specification: item.specification.map((spec, spec_index) => {
                  if (spec_index === spec_id && 'gsm' in spec) {
                    return {
                      ...spec,
                      gsm: spec.gsm.filter((_, gsm_index) => gsm_index !== gsm_id),
                    };
                  }
                  return spec;
                }),
              };
            }
            return item;
          })
        );
        setgsmCount(prevCount => prevCount - 1);
    };

    useEffect(() => {
        fetch_units_and_gsm();
    }, []);
    
    const fetch_units_and_gsm = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_units`);
            // const options = response.data.data.map((unit) => unit['Name']);
            // set_unit_options(options);
            const options = response.data.data
            .map((unit) => unit['Name'])
            .sort((a, b) => (a === 'CM' ? -1 : b === 'CM' ? 1 : 0));
            set_unit_options(options);

            const response2 = await axios.get(`${window.backendUrl}/get_all_gsm`);
            const options2 = response2.data.data.map((gsm) => gsm['Name'] + ' ' + gsm['GSMValue']);
            set_gsm_options(options2);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // setIsFirstLoad(false);
    }, [item_name, party_name, spec_name]);
    
    const fetchData = async () => {
        if (!party_name || !item_name || !spec_name) {
            return;
        }
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_party_grades/${party_name}/${item_name}/${spec_name}`);
            const options = response.data.map((grade) => grade['Grade']);
            set_grade_options(options);
            /////////////////////////
            const response_2 = await axios.get(`${window.backendUrl}/get_all_addiwork_of_party/${party_name}`);
            set_addiwork_options(response_2.data);
            // const addi_work_price = orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.addi_work_price 
            // if (addi_work_price) {
            //     const updatedItems = [...orderItems]
            //     updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work_price = 0;
            //     setOrderItems(updatedItems);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetch_grade_info_and_unit_price();
    }, [orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.grade, party_name, item_name, spec_name]);
    
    const fetch_grade_info_and_unit_price = async () => {
        const grade = orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.grade;
        ////
        const gsmId = orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.gsmId;

        if (grade && grade !== '') {
            try {
                const response = await axios.get(`${window.backendUrl}/get_grade_info_and_unit_price/${grade}/${party_name}/${item_name}/${spec_name}`);
                // console.log(response.data)
                const new_grade_info = response.data.gradeInfo[0]//response.data[0];
                const new_unit_price = response.data.unit_price
                set_grade_info(new_grade_info);    
                //set the values of the layers to the gsm part in orderItems
                const updatedItems = [...orderItems]
                const keys = ['LinerOuter', 'LinerInner', 'BFlute1', 'BFlute2', 'CFlute', 'PMedium1', 'PMedium2'];            
                // Iterate over each key, set value from grade_info if exists, else set to ''
                keys.forEach(key => {
                    updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id][key] = new_grade_info[key] || '';
                });
                if(!gsmId){
                    if (new_unit_price) {
                        updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].unit_price = new_unit_price;
                    }else{
                        updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].unit_price = '';
                    }
                }else{
                    if (new_unit_price && !isFirstLoad) {
                        updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].unit_price = new_unit_price;
                    }else if (!isFirstLoad){
                        updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].unit_price = '';
                    }
                }
                setOrderItems(updatedItems);
                setIsFirstLoad(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    
    const gradeExists = orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.grade !== '';

    const renderSelects = () => {
        const selects = [];
        let row = [];

        Object.keys(grade_info).forEach((layer, index) => {
            if (grade_info[layer] !== '') {
                const req_qty_key = `${layer}_qty`;
                row.push(
                    <>
                        <Form.Select key={index} name={layer} value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.[layer] || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange} style={{width:'150px', marginBottom:'10px'}}>
                            {/* <option value={grade_info[layer]}>{grade_info[layer] +' GSM' }</option> */}
                            {gsm_options.map((gsm, index) => (
                                <option key={index} value={gsm}>
                                {gsm + ' GSM'}
                                </option>
                            ))}
                            {/* <option value=''>{layer}</option> */}
                        </Form.Select>
                        <Form.Group className=" mb-2" controlId='required quantity' >
                            <Form.Control type="number" style={{ width: '100px', height:'35px', marginRight:'20px' }} key={index} placeholder={'req. qty ' +layer} disabled/>
                        </Form.Group>
                    </>
                );

                if (row.length === 3) {
                    selects.push(
                        <Form className='d-flex flex-row' key={`row-${index}`}>
                            {row}
                        </Form>
                    );
                    row = [];
                }
            }
        });

        if (row.length > 0) {
            selects.push(
                <Form className='d-flex flex-row' key={`row-last`}>
                    {row}
                </Form>
            );
        }

        return selects;
    };

    const handleAddiworkChange =  (e) =>{
        const addiwork = e.target.value;
        const updatedItems = [...orderItems]
        updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work = addiwork;
        if (addiwork == '') {
            updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work_price = 0;
            setOrderItems(updatedItems);
        }else{
            const addiwork_info = addiwork_options.find((option) => option['name'] === addiwork);
            updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work_price = addiwork_info['value'];
            setOrderItems(updatedItems);
        }
    }

    // useEffect(() => {
    //     if(orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.addi_work != ''){
    //         const addi_work_price = orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.addi_work_price 
    //         if (addi_work_price) {
    //             const updatedItems = [...orderItems]
    //             updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work = '';
    //             updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id].addi_work_price = '';
    //             setOrderItems(updatedItems);
    //         }
    //     }
    // }, [party_name]);
    

    return (
        <div style={{marginBottom:'10px'}}>
            {gsm_id != 0  && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={decreasegsmCount} src="/Images/cross-button.png" alt="cross" style={{ width: '17px', height: '17px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
            <Container fluid style={{width:'95%',border:'1px solid rgba(0,0,0,0.3)',borderRadius:'5px'}}>
                <Row style={{padding:'10px'}}>
                    <Col>
                        <Row>
                            <Col>
                                <Form className='d-flex flex-col justify-content-between'>
                                    <Form.Select name="unit"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.unit || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange} style={{width:'200px', marginBottom:'10px'}}>
                                        {unit_options.map((unit,index) => (
                                            <option key={index} value={unit}>{unit}</option>
                                        ))}
                                     </Form.Select>

                                    <Form.Group className="mb-2" controlId="length">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Length' name="length"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.length || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange} />
                                    </Form.Group>

                                    <Form.Group className=" mb-2" controlId="width">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Width' name="width"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.width || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>

                                    <Form.Group className=" mb-2" controlId="height">
                                        <Form.Control type="number" style={{ width: '200px', height:'30px' }} placeholder='Height' name="height"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.height || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form className='d-flex flex-col justify-content-between'>

                                    {/* <Form.Group className=" mb-2" controlId="thickness">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Thickness' name="thickness" />
                                    </Form.Group> */}

                                    <Form.Select name="grade"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.grade || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange} style={{width:'200px', marginBottom:'10px'}}>
                                        <option value=''>Select grade</option>
                                        {grade_options.map((grade,index) => (
                                            <option key={index} value={grade}>{grade}</option>
                                        ))}
                                     </Form.Select>

                                    <Form.Group className=" mb-2" controlId="order_quantity">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Order Qty' name="order_quantity"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.order_quantity || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>

                                    <Form.Group className=" mb-2" controlId="square_meter">
                                        <Form.Control type="number" style={{ width: '200px', height:'30px' }} placeholder='Sq. Mt.' name="square_meter"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.square_meter || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  disabled/>
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="unit_price">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Unit price' name="unit_price"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.unit_price || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form className='d-flex flex-col'>
                                    {/* <Form.Group className=" me-5" controlId="unit_price">
                                        <Form.Control type="text" style={{ width: '200px', height:'30px' }} placeholder='Unit price' name="unit_price"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.unit_price || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group> */}
                                    <div className='d-flex flex-col me-2'>
                                        {/* <Form.Select name="addi_work" style={{width:'120px', height:'40px', marginBottom:'10px'}}>
                                            <option value=''>Addi work</option>
                                        </Form.Select> */}
                                        <div>
                                        <Form.Select name="addi_work"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.addi_work || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={(e) => { handleOrderItemChange(e); handleAddiworkChange(e); }} style={{width:'200px', marginBottom:'10px'}}>
                                            <option value=''>Addi work</option>
                                            {addiwork_options.map((addiwork,index) => (
                                                <option key={index} value={addiwork['name']}>{addiwork['name']}</option>
                                            ))}
                                        </Form.Select>
                                        </div>

                                        <Form.Group className=" mb-2" controlId="additional_work_price">
                                            <Form.Control type="number" style={{ width: '120px', height:'30px',marginRight:'10px' }} value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.addi_work_price || ""} placeholder='Addi. Price' disabled/>
                                        </Form.Group>
                                    </div>

                                    <Form.Group className=" me-2" controlId="over_invoice">
                                        <Form.Control type="number" style={{ width: '130px', height:'30px',marginRight:'10px' }} placeholder='Over invoice' name="over_invoice"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.over_invoice || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="notes">
                                        <Form.Control as="textarea" style={{ width: '200px'}} placeholder='Notes' name="notes"  value={orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.[gsm_id]?.notes || ""}  data-item_id={item_id} data-spec_id={spec_id} data-gsm_id={gsm_id}  onChange={handleOrderItemChange}/>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {gradeExists && renderSelects()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-end'>
                            <Image onClick={increasegsmCount} src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                        </Col>
                    </Row>
            </Container>
        </div>
    )
}

export default GSM