import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords, formatNumberWithCommas, getAbbreviationForPi} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';
import { set } from 'rsuite/esm/internals/utils/date';

const WorkOrderPDF = () => {
  const {orderId } = useParams();
  const [orderInfo, setOrderInfo] = useState(null);
//   const [groupPiNo, setGroupPiNo] = useState('');
//   const [groupPiDate, setGroupPiDate] = useState(0);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true);
  const categoryTotals = {}; 
  let totalOrderQuantity = 0;
  let totalSquareMeter = 0;
  let totalLinerOuter = 0;
  let totalLinerInner = 0;
  let totalBFlute1 = 0;
  let totalCFlute = 0;
  let totalPM1 = 0;

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      fetch_work_order_pdf_info();
    }
  }, [orderId]);

  const fetch_work_order_pdf_info = () => {
    axios.get(`${window.backendUrl}/get_work_order_pdf_info/${orderId}`)
      .then((response) => {
        setOrderInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
      // setLoading(false);
  };


  if (loading) {
    return <></>
  }


  return (
    <body>
    <div style={{width: '100%', minHeight: '900px', padding:'20px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody className='work_order_info'>
            <tr>
                <td colSpan="5" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <h1 className='challan_header' style={{fontSize:'25px', color:'blue', lineHeight:'25px'}}>{companyTitle}</h1>
                    <strong style={{ color: '#61190d' }}>Factory :</strong> Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
                    <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
                </td>
            </tr>
            <tr>
                <td colSpan="5" style={{ textAlign: 'center' }}>
                <div style={{ height: '5px', margin: 0, padding: 0 }}></div>
                </td>
            </tr>
            <br/>
            <tr>
                <td colSpan="2" style={{ verticalAlign: 'top' }}>
                <h3 style={{ border: '1px solid #333', padding: '5px 100px', float: 'right', fontSize:'14px' }}><strong>Work Order</strong></h3>
                </td>
                <td style={{ textAlign: 'center' }}>&nbsp;</td>
                <td colSpan="2" style={{ verticalAlign: 'top' }}>
                <h3 style={{ border: '1px solid #333', padding: '5px 100px', float: 'left', fontSize:'14px'  }}><strong>Job Number: {orderInfo.jobNumber}</strong></h3>
                </td>
            </tr>
            <br/>
            <tr style={{fontSize:'12px'}}>
                <td style={{ width: '15%', verticalAlign: 'top' }}>
                <strong>Order Date:</strong>
                </td>
                <td style={{ width: '34%', verticalAlign: 'top' }}>{new Date(orderInfo.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>
                <td style={{ width: '2%' }}>&nbsp;</td>
                <td style={{ width: '15%', verticalAlign: 'top' }}>
                <strong>Party Name:</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                <strong style={{ fontSize: '14px' }}>{orderInfo.partyName}</strong>
                </td>
            </tr>
            <tr style={{fontSize:'12px'}}>
                <td style={{ verticalAlign: 'top' }}>
                <strong>Team Name:</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>{orderInfo.teamName}</td>
                <td>&nbsp;</td>
                <td style={{ verticalAlign: 'top' }}>
                <strong>Buyer Name:</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>{orderInfo.buyerName}</td>
            </tr>
            <tr style={{fontSize:'12px'}}>
                <td style={{ verticalAlign: 'top' }}>
                <strong>E.D.D.:</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>{new Date(orderInfo.delivery_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>
                <td>&nbsp;</td>
                <td style={{ verticalAlign: 'top' }}>
                <strong>Style No.:</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>{orderInfo.style}</td>
            </tr>
            <tr style={{fontSize:'12px'}}>
                <td style={{ verticalAlign: 'top' }}>
                <strong>Deli. Station:</strong>
                </td>
                <td style={{ verticalAlign: 'top'}}>
                <div style={{width:'400px',wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  {orderInfo.delivery_station}
                </div>
                </td>
                <td>&nbsp;</td>
                <td style={{ verticalAlign: 'top' }}>
                <strong>LPD PO No.</strong>
                </td>
                <td style={{ verticalAlign: 'top' }}>{orderInfo.lpd_po_no}</td>
            </tr>
        </tbody>
        </table>
        <table width="100%" border="1" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse', marginTop:'20px' }}>
        <thead style={{fontSize:'12px'}}>
        <tr>
          <th width="10%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Items</th>
          <th width="5%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Spec.</th>
          <th width="7%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Measurement</th>
          <th width="3%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Total</th>
          <th width="3%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Order Qty</th>
          <th width="7%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Notes</th>
          <th width="5%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Tot. SqrMt-Lbs</th>
          <th width="8%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Liner Outer (Kg)</th>
          <th width="8%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Liner Inner (Kg)</th>
          <th class="bf1" width="8%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>B-Flute1 (Kg)</th>
          <th class="cf" width="8%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>C-Flute (Kg)</th>
          <th class="pm1" width="8%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>P. Med.1 (Kg)</th>
          <th width="5%" align="center" style={{padding:'8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000'}}>Paper Size</th>
        </tr>

        </thead>
        <tbody className='challan_pdf_table' style={{ fontSize: '12px' }}>
          {(() => {
            return orderInfo.items?.map((item, itemIndex) => {
              return (
                <>
                  {item.specifications?.map((specification, specIndex) => (
                    specification.gsms?.map((gsm, gsmIndex) => {
                      // Update category totals
                      if (!categoryTotals[item.item_category]) {
                        categoryTotals[item.item_category] = {
                          totalOrderQuantity: 0,
                        };
                      }
                      categoryTotals[item.item_category].totalOrderQuantity += gsm.orderQuantity;
                      totalOrderQuantity += gsm.orderQuantity;
                      totalSquareMeter += parseFloat(gsm.totalSquareMeter);
                      totalLinerOuter += parseFloat(gsm.linerOuterReqQty);
                      totalLinerInner += parseFloat(gsm.linerInnerReqQty);
                      totalBFlute1 += parseFloat(gsm.bFlute1ReqQty);
                      totalCFlute += parseFloat(gsm.cFluteReqQty);
                      totalPM1 += parseFloat(gsm.pMedium1ReqQty);                    

                      return (
                        <tr key={`${itemIndex}-${specIndex}-${gsmIndex}`}>
                          {specIndex === 0 && gsmIndex === 0 && (
                            <td rowSpan={item.specifications.reduce((total, spec) => total + spec.gsms.length, 0)} data-title="Item Name">
                              {item.itemName}
                            </td>
                          )}
                          {specIndex === 0 && gsmIndex === 0 && (
                            <td rowSpan={specification.gsms.length} className="txtc" data-title="Specification Name">
                              {specification.specification}
                            </td>
                          )}
                          <td className="txtc" data-title="Measurement">
                            {gsm.measurement}
                          </td>
                          <td style={{ textAlign: 'center' }} className="txtc" data-title="Measurement Order Qty Total">
                            {gsm.orderQuantity}
                          </td>
                          <td style={{ textAlign: 'center' }} className="txtc" data-title="Order Qty">
                            {gsm.orderQuantity}/Pcs
                          </td>
                          <td className="txtc" data-title="Square Meter">
                            {gsm.notes}
                          </td>
                          <td style={{ textAlign: 'center' }} className="txtc" data-title="Square Meter">
                            {gsm.totalSquareMeter}
                          </td>
                          <td style={{ textAlign: 'center' }} data-title="Liner Outer">
                            {gsm.linerOuter}
                            <br />
                            {gsm.linerOuter && (
                              <>
                                
                                {gsm.linerOuterReqQty.toFixed(4)}
                              </>
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }} data-title="Liner Inner">
                            {gsm.linerInner}
                            <br />
                            {gsm.linerInner && (
                              <>
                                
                                {gsm.linerInnerReqQty.toFixed(4)}
                              </>
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }} data-title="B-Flute1">
                            {gsm.bFlute1}
                            <br />
                            {gsm.bFlute1 && (
                              <>
                                
                                {gsm.bFlute1ReqQty.toFixed(4)}
                              </>
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }} data-title="C-Flute">
                            {gsm.cFlute}
                            <br />
                            {gsm.cFlute && (
                              <>
                                
                                {gsm.cFluteReqQty.toFixed(4)}
                              </>
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }} data-title="P. Medium1">
                            {gsm.pMedium1}
                            <br />
                            {gsm.pMedium1 && (
                              <>
                                {gsm.pMedium1ReqQty.toFixed(4)}
                              </>
                            )}
                          </td>
                          <td className="txtc" data-title="Paper Size">
                            {gsm.rollSize}
                          </td>
                        </tr>
                      );
                    })
                  ))}
                </>
              );
            });
          })()}
          {/* Job Total Row */}
          <tr style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
            <td colSpan="4" style={{ textAlign: 'center' }}>
              <strong>
                Total:{" "}
                {Object.keys(categoryTotals)
                  .map(category => `${category} (${categoryTotals[category].totalOrderQuantity})`)
                  .join(", ")}
              </strong>
            </td>
            <td style={{ textAlign: 'center' }}><strong>{totalOrderQuantity}</strong></td>
            <td></td>
            <td style={{ textAlign: 'center' }}><strong>{totalSquareMeter.toFixed(3)}</strong></td>
            <td style={{ textAlign: 'center' }}><strong>{totalLinerOuter.toFixed(4)}</strong></td>
            <td style={{ textAlign: 'center' }}><strong>{totalLinerInner.toFixed(4)}</strong></td>
            <td style={{ textAlign: 'center' }}><strong>{totalBFlute1.toFixed(4)}</strong></td>
            <td style={{ textAlign: 'center' }}><strong>{totalCFlute.toFixed(4)}</strong></td>
            <td style={{ textAlign: 'center'}}><strong>{totalPM1.toFixed(4)}</strong></td>
            <td style={{ textAlign: 'center' }}></td>
          </tr>
        </tbody>
        </table>
      <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', marginTop:'30px' }}>
        <tbody>
          <tr>
            <td style={{fontSize:'12px', textAlign:'left' }}><span style={{fontWeight: 'bold'}}>Note: </span>{orderInfo.note} </td>
            {/* <td>
              <div style={{ width: '100%', minHeight: '20px' }} id="noteId"></div>
            </td> */}
          </tr>
          <tr>
            <td colSpan="2" style={{ height: '100px', margin: '0', padding: '0' }}></td>
          </tr>
          <tr>
            <td colSpan="2">
              <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', fontSize:'12px' }}>
                <tbody>
                  <tr>
                    <td style={{ padding: '20px', width: '33%' }}>
                      <div style={{ margin: '0 20px' }}>
                        Nadia Islam
                        <p style={{ borderTop: '1px solid black', marginTop: '5px' }}>
                          <strong>Prepared By</strong>
                        </p>
                      </div>
                    </td>
                    <td style={{ padding: '20px', width: '33%' }}>
                      <div style={{ margin: '30px 20px 0' }}>
                        <p style={{ borderTop: '1px solid black', marginTop: '5px' }}>
                          <strong>Checked By</strong>
                        </p>
                      </div>
                    </td>
                    <td style={{ padding: '20px', width: '33%' }}>
                      <div style={{ margin: '30px 20px 0' }}>
                        <p style={{ borderTop: '1px solid black', marginTop: '5px' }}>
                          <strong>Authorized By</strong>
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </body>
  );
};

export default  WorkOrderPDF;