import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function ManageGradeForm({ saveClick, setSaveClick, selectedEntry,setSelectedEntry }) {

    const [gsm_options, set_gsm_options] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        liner_outer:'',
        liner_inner:'',
        bflute1:'',
        bflute2:'',
        cflute:'',
        pmedium1:'',
        pmedium2:'',
    });
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (selectedEntry !== null) {
            setFormData({
                    name: selectedEntry['Name'],
                    liner_outer:selectedEntry['LinerOuter'],
                    liner_inner:selectedEntry['LinerInner'],
                    bflute1:selectedEntry['BFlute1'],
                    bflute2:selectedEntry['BFlute2'],
                    cflute:selectedEntry['CFlute'],
                    pmedium1:selectedEntry['PMedium1'],
                    pmedium2:selectedEntry['PMedium2'],
                });
            setIsEditMode(true);
        }
    }, [selectedEntry]);

    useEffect(() => {
        fetchData();
      }, []);
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_gsm`);
            const options = response.data.data.map((gsm) => gsm['Name'] + ' ' + gsm['GSMValue']);
            set_gsm_options(options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = () => {
        if(formData.name !== ''){
            axios.post(`${window.backendUrl}/insert_manage_grade`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    liner_outer:'',
                    liner_inner:'',
                    bflute1:'',
                    bflute2:'',
                    cflute:'',
                    pmedium1:'',
                    pmedium2:'',
                });
            })
        }
    };

    const handleUpdate = () => {
        if(formData.name !== ''){
            axios.put(`${window.backendUrl}/update_manage_grade/${selectedEntry['GradeID']}`, formData)
            .then((response) => {
                console.log('Success:', response.data);
            })
            .catch((error) => {
                console.error('Error:edidq', error);
            })
            .then(() => {
                setSaveClick(!saveClick);
                setFormData({
                    name: '',
                    liner_outer:'',
                    liner_inner:'',
                    bflute1:'',
                    bflute2:'',
                    cflute:'',
                    pmedium1:'',
                    pmedium2:'',
                });
                setIsEditMode(false);
                setSelectedEntry(null);
            })
        }
    };
    
    const handleDelete = () => {
        axios.delete(`${window.backendUrl}/delete_manage_grade/${selectedEntry['GradeID']}`)
        .then((response) => {
            console.log('Success:', response.data);
        })
        .catch((error) => {
            console.error('Error:edidq', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData({
                name: '',
                liner_outer:'',
                liner_inner:'',
                bflute1:'',
                bflute2:'',
                cflute:'',
                pmedium1:'',
                pmedium2:'',
            });
            setIsEditMode(false);
            setSelectedEntry(null);
        })
    };
    
    const handleCancel = () => {
        setFormData({
            name: '',
            liner_outer:'',
            liner_inner:'',
            bflute1:'',
            bflute2:'',
            cflute:'',
            pmedium1:'',
            pmedium2:'',
        });
        setIsEditMode(false);
        setSelectedEntry(null);
    };

    return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
        <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Grade</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Grade Name* :</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Liner Outer">
            <Form.Label>Liner Outer :</Form.Label>
            <Form.Select name="liner_outer" value={formData.liner_outer} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Liner Inner">
            <Form.Label>Liner Inner :</Form.Label>
            <Form.Select name="liner_inner" value={formData.liner_inner} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="B Flute 1">
            <Form.Label>B Flute 1 :</Form.Label>
            <Form.Select name="bflute1" value={formData.bflute1} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="B Flute 2">
            <Form.Label>B Flute 2 :</Form.Label>
            <Form.Select name="bflute2" value={formData.bflute2} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="C Flute">
            <Form.Label>C Flute :</Form.Label>
            <Form.Select name="cflute" value={formData.cflute} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="P Medium 1">
            <Form.Label>P. Medium 1 :</Form.Label>
            <Form.Select name="pmedium1" value={formData.pmedium1} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="P Medium 2">
            <Form.Label>P. Medium 2 :</Form.Label>
            <Form.Select name="pmedium2" value={formData.pmedium2} onChange={handleInputChange}>
            <option value=''></option>
            {gsm_options.map((gsm, index) => (
                <option key={index} value={gsm}>
                {gsm + ' GSM'}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        {isEditMode ? (
            <>
            <Button variant="primary" onClick={handleUpdate}>
                Update
            </Button>
            <Button variant="warning" onClick={handleCancel} style={{ marginLeft: '70px' }}>
                Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>
                Delete
            </Button>
            </>
        ) : (
            <Button variant="primary" onClick={handleSubmit}>
            Add
            </Button>
        )}
        </Form>
    </div>
    );
}

export default ManageGradeForm;