import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Gate_Pass_Date_Modal, Gate_Pass_Delete_Modal, Gate_Pass_Status_Modal } from "../utilities";

function ManageGatePass() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => { 
        const { teamname, partyname, buyername, itemname, specification} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_all_gate_passes`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );

    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);

        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const [showGatePassDateModal, setShowGatePassDateModal] = useState(false);
    const [showGatePassDeleteModal, setShowGatePassDeleteModal] = useState(false);
    const [showGatePassStatusModal, setShowGatePassStatusModal] = useState(false);
    const [modal_gate_pass_details, set_modal_gate_pass_details] = useState({});

    const handleGatePassDateClick =(gate_pass_id, gate_pass_date)=>{
        set_modal_gate_pass_details({gate_pass_id, gate_pass_date});
        setShowGatePassDateModal(true);
    }

    const handleGatePassDateSave = (data) => {  
        console.log(data);
        axios.post(`${window.backendUrl}/update_gate_pass_date`, data)
            .then(response => {
                setShowGatePassDateModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }

    const handleGatePassDeleteClick =(gate_pass_id)=>{
        set_modal_gate_pass_details({gate_pass_id});
        setShowGatePassDeleteModal(true);
    }

    const handleGatePassDelete = (data) => {  

        axios.post(`${window.backendUrl}/delete_gate_pass`, data)
            .then(response => {
                // setShowGatePassDeleteModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }

    const handleStatusChangeClick =(gate_pass_list_id, current_status)=>{
        set_modal_gate_pass_details({gate_pass_list_id, current_status});
        setShowGatePassStatusModal(true);
    }

    const handleStatusChangeSave = (data) => {  
        console.log(data);

        axios.post(`${window.backendUrl}/change_gate_pass_list_status`, data)
            .then(response => {
                setShowGatePassStatusModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={3}>
                <h4>Manage Gate Pass</h4>
                </Col>
                <Col>
                    <Button style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'10px', color:'black'}} onClick={()=>navigate("/new-gate-pass")}>
                        New Gate Pass
                    </Button>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '13px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th>SL</th>
                                <th>Date</th>
                                <th>Party Name</th>
                                <th>Challan No.</th>
                                <th>Deli Date</th>
                                <th>Date <br/>Action</th>
                                <th>Job No.</th>
                                <th>LPD / Style</th>
                                <th>Buyer <br/>Name</th>
                                <th>Delivery <br/>Station</th>
                                <th>Vehicle No / <br/>Driver Name</th>
                                <th>Quantity</th>
                                <th>Packet <br/> Qty.</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '11px' }}>
                            {orders.map((gatePass, gatePassIndex) => {
                                    // Calculate totalQty and totalPacketQty for each gate pass
                                    const gatePassTotalQty = Object.values(gatePass.parties).reduce((totalQty, party) => {
                                        return totalQty + party.challans.reduce((partyTotalQty, challan) => partyTotalQty + challan.deliveryQty, 0);
                                    }, 0);

                                    const gatePassTotalPacketQty = Object.values(gatePass.parties).reduce((totalPacketQty, party) => {
                                        return totalPacketQty + party.challans.reduce((partyTotalPacketQty, challan) => partyTotalPacketQty + challan.packetQty, 0);
                                    }, 0);

                                return (
                                <>
                                    {Object.keys(gatePass.parties).map((partyName, partyIndex) => {
                                        const total_challans = Object.keys(gatePass.parties).reduce((total, key) => total + gatePass.parties[key].challans.length, 0);

                                        return (
                                            <>
                                                {gatePass.parties[partyName].challans.map((challan, challanIndex) => {
                                                    const partyTotalQty = Object.values(gatePass.parties[partyName].challans).reduce((total, challan) => total + challan.deliveryQty, 0);
                                                    const partyTotalPacketQty = Object.values(gatePass.parties[partyName].challans).reduce((total, challan) => total + challan.packetQty, 0);
                                                    const status_color = challan.status === 0 ? '' : challan.status === 1 ? '#edefac' : '#c3fec4';
                                                    const status_modification_date = challan.status != 0 ? new Date(challan.status_modification_date * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '.') : '';


                                                    return (
                                                        <>
                                                            <tr key={`challan-${challan.deliveryChallanId}`}>
                                                                {challanIndex === 0 && partyIndex === 0 && (
                                                                    <>
                                                                        <td rowSpan={Object.keys(gatePass.parties).reduce((total, key) => total + gatePass.parties[key].challans.length + 1, 0) + 1}>
                                                                            {gatePass.serial_no}
                                                                        </td>
                                                                        <td  rowSpan={Object.keys(gatePass.parties).reduce((total, key) => total + gatePass.parties[key].challans.length + 1, 0) + 1}>
                                                                            <div className='d-flex justify-content-center'>
                                                                                {new Date(gatePass.createdDate * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '.')}
                                                                                <i class="fa fa-calendar-o" style={{cursor:'pointer',color:'#337ab7', marginLeft:'7px'}} onClick={() => handleGatePassDateClick(gatePass.gatePassId, gatePass.createdDate)}></i>
                                                                                <i style={{cursor:'pointer', color:'red',fontSize:'18px' ,marginLeft:'17px'}} class="fa fa-remove errormsg txt18" onClick={() => handleGatePassDeleteClick(gatePass.gatePassId)}></i>
                                                                            </div>
                                                                            <div className='d-flex justify-content-center'>
                                                                                <i style={{ marginRight: '40px', fontSize:'13px' }} className="print-button fa fa-print" onClick={() => window.open(`/gatePass-pdf-viewer/${gatePass.gatePassId}`, '_blank')}></i>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}
                                                                {challanIndex === 0 && (
                                                                    <>
                                                                        <td rowSpan={gatePass.parties[partyName].challans.length + 1}>
                                                                            {partyName}
                                                                        </td>
                                                                    </>
                                                                )}
                                                                <td style={{backgroundColor: status_color}}>{challan.deliveryChallanNo}</td>
                                                                <td style={{backgroundColor: status_color}}>{new Date(challan.deliveryDate * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '.')}</td>
                                                                <td style={{ textAlign: 'center', backgroundColor: status_color }}>
                                                                    {challan.status === 0 && (
                                                                        <i className='fa fa-check txt18 gate_pass_tick' onClick={() => handleStatusChangeClick(challan.gatePassListId, challan.status)} ></i>
                                                                    )}
                                                                    {challan.status === 1 && (
                                                                        <div className='d-flex flex-column'>
                                                                            <i className='fa fa-check txt18 gate_pass_tick' onClick={() => handleStatusChangeClick(challan.gatePassListId, challan.status)} ></i>
                                                                            <span>{status_modification_date}</span>
                                                                        </div>
                                                                    )}
                                                                    {challan.status === 2 && (
                                                                        <span>{status_modification_date}</span>
                                                                    )}
                                                                    </td>
                                                                <td style={{backgroundColor: status_color}}>{challan.jobNumber}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.style}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.buyerName}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.deliveryStation}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.vehicleNo}<hr style={{ border: '1px solid grey' }} />{challan.driverName}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.deliveryQty}</td>
                                                                <td style={{backgroundColor: status_color}}>{challan.packetQty}</td>
                                                            </tr>
                                                            {challanIndex === gatePass.parties[partyName].challans.length - 1 && (
                                                                <tr>
                                                                    <td colSpan="8" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        Party Total:
                                                                    </td>
                                                                    <td><strong>{partyTotalQty}</strong></td>
                                                                    <td><strong>{partyTotalPacketQty}</strong></td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                                {partyIndex === Object.keys(gatePass.parties).length - 1 && (
                                                    <tr className='green_row'>
                                                        <td  style={{textAlign:'right'}}><strong>Total Challan: </strong></td>
                                                        <td><strong>{total_challans }</strong></td>
                                                        <td colSpan="7" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            Gate Pass Total:
                                                        </td>
                                                        <td><strong>{gatePassTotalQty}</strong></td>
                                                        <td><strong>{gatePassTotalPacketQty }</strong></td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </>)
                            })}
                        </tbody>

                    </Table>
                </Col>
            </Row>
        </Container>
        <Gate_Pass_Date_Modal show={showGatePassDateModal} setShow={setShowGatePassDateModal} handleSave={handleGatePassDateSave} details={modal_gate_pass_details}/>
        <Gate_Pass_Delete_Modal show={showGatePassDeleteModal} setShow={setShowGatePassDeleteModal} handleDelete={handleGatePassDelete} details={modal_gate_pass_details}/>
        <Gate_Pass_Status_Modal show={showGatePassStatusModal} setShow={setShowGatePassStatusModal} handleSave={handleStatusChangeSave} details={modal_gate_pass_details}/>
    </>
    )
}

export default ManageGatePass