import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../axios';
import '../Functions/manage_orders.css';
import { set } from 'rsuite/esm/internals/utils/date';

const AddNewCommercialForm = ({commercial_id}) => {
    const today = new Date().toLocaleDateString('en-CA', { timeZone: 'Asia/Dhaka' });

    const [formData, setFormData] = useState({
        com_date: today,
        invoice_no: '',
        duration: '',
        acceptance_terms: 'Acceptance',
        party_name: '',
        alt_party_name: '',
        alt_party_address: '',
        com_type: 'General',
        party_bank_name: '',
        alt_pi_no: '',
        lc_no: '',
        lc_date: today,
        amendment_info: '',
        contract_no: '',
        contract_date: today,
        netweight: '',
        grossweight: '',
        others_info: '',
        expiry_date: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveCommercial = () => {
        // console.log(formData);
        // console.log(selectedPIIds);
        const payload = {
            commercial_info: formData,
            selectedPIIds: selectedPIIds
        }

        axios.post(`${window.backendUrl}/add_new_commercial`, payload)
            .then(response => {
                setSelectedPIIds([]);
                setFormData({
                    com_date: today,
                    invoice_no: '',
                    duration: '',
                    acceptance_terms: 'Acceptance',
                    party_name: '',
                    alt_party_name: '',
                    alt_party_address: '',
                    com_type: 'General',
                    party_bank_name: '',
                    alt_pi_no: '',
                    lc_no: '',
                    lc_date: today,
                    amendment_info: '',
                    contract_no: '',
                    contract_date: today,
                    netweight: '',
                    grossweight: '',
                    others_info: '',
                    expiry_date: '',
                })
                get_invoice_no();       
                window.location.reload()
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    // Handle form submission
    };

    const [party_bank_options, set_party_bank_options]=useState([]);
    const [party_options, set_party_options]=useState([]);

    useEffect(() => {
        fetch_dropdown_options();
        get_invoice_no();
    }, []); 

    const get_invoice_no = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_invoice_no_of_new_commercial`);
            setFormData((prevData) => ({ ...prevData, invoice_no: response.data.data[0]['next_invoice_no'] }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_party_banks`);

            const options1 = response1.data.data.map((party) => party['PartyName']);
            const options2 = response2.data.data.map((party_bank) => party_bank['Name']);

            set_party_options(options1);
            set_party_bank_options(options2);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [selectedPIIds, setSelectedPIIds] = useState([]);
    const [PINumbers,setPINumbers]=useState([]);

    useEffect(() => {
        if (formData.party_name === '') {
            setPINumbers([]);
            return;
        }
        if (formData.original_party_name !== formData.party_name) {
            axios.get(`${window.backendUrl}/get_all_pi_nos/${formData.party_name}`)
                .then((response) => {
                    const options = response.data.data.map((pi) => ({
                        pi_no: `PI/${pi['JobNumber']} ($${pi['TotalPrice']})`,
                        pi_id: pi['PiId']
                    }));
                    setPINumbers(options);
                    setSelectedPIIds([])
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }

        if (formData.original_party_name == formData.party_name) {

            axios.get(`${window.backendUrl}/get_commercial_info/${commercial_id}`)
                .then((response) => {
                    setSelectedPIIds(response.data.data.selectedPIIds);
                    setPINumbers(response.data.data.PINumbers);
                })
                .catch((error) => {
                    console.error('Error fetching commercial data:', error);
                });
        }
           
    }, [formData.party_name]);


    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedPIIds(prevValues => {
          if (checked) {
            // Add value to selectedValues if checkbox is checked
            return [...prevValues, value];
          } else {
            // Remove value from selectedValues if checkbox is unchecked
            return prevValues.filter(item => item !== value);
          }
        });
    };

    useEffect(() => {
        if (commercial_id) {
            axios.get(`${window.backendUrl}/get_commercial_info/${commercial_id}`)
                .then((response) => {
                    // console.log(response.data.data);
                    setFormData(response.data.data.commercial_info);
                    setSelectedPIIds(response.data.data.selectedPIIds);
                    setPINumbers(response.data.data.PINumbers);
                    // console.log(PINumbers);
                })
                .catch((error) => {
                    console.error('Error fetching commercial data:', error);
                });
        }
    }, [commercial_id]); 

    const handleChangeCommercial = () => {
        const payload = {
            commercial_id: commercial_id,
            commercial_info: formData,
            selectedPIIds: selectedPIIds
        }

        axios.post(`${window.backendUrl}/update_commercial`, payload)
            .then(response => {
                setSelectedPIIds([]);
                setFormData({
                    com_date: today,
                    invoice_no: '',
                    duration: '',
                    acceptance_terms: 'Acceptance',
                    party_name: '',
                    alt_party_name: '',
                    alt_party_address: '',
                    com_type: 'General',
                    party_bank_name: '',
                    alt_pi_no: '',
                    lc_no: '',
                    lc_date: today,
                    amendment_info: '',
                    contract_no: '',
                    contract_date: today,
                    netweight: '',
                    grossweight: '',
                    others_info: '',
                    expiry_date: '',
                })
                get_invoice_no();       
                window.location.reload()
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }
    return (
    <Container style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '10px 25px', borderRadius: '5px' }}>
        <h5 className='mt-1'>Add New Commercial</h5>
        <Form style={{fontSize:'15px', margin:'20px 0 10px 0'}}>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Comm. Date *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="date"
                name="com_date"
                value={formData.com_date}
                onChange={handleChange}
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Invoice No. *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="invoice_no"
                value={formData.invoice_no}
                onChange={handleChange}
                disabled
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Duration *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Acceptance Terms *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
                <Form.Select name="acceptance_terms" value={formData.acceptance_terms} onChange={handleChange}>
                    <option value="Acceptance">Acceptance</option>
                    <option value="Delivery">Delivery</option>
                </Form.Select>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Party Name *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
                <Form.Select name="party_name" value={formData.party_name} onChange={handleChange}>
                    <option value="">Select Party Name</option>
                    {party_options.map((party, index) => (
                        <option key={index} value={party}>
                        {party}
                        </option>
                    ))}
                </Form.Select>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Alternative Party:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="alt_party_name"
                value={formData.alt_party_name}
                onChange={handleChange}
                placeholder="Alternative Party Name"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Alt. Party Address:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="alt_party_address"
                value={formData.alt_party_address}
                onChange={handleChange}
                placeholder="Alternative Party Address"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Comm. Type *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
                <Form.Select name="com_type" value={formData.com_type} onChange={handleChange}>
                    <option value="General">General</option>
                    <option value="Group PI">Group PI</option>
                    <option value="Master PI">Master PI</option>
                </Form.Select>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Party Bank *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
                <Form.Select name="party_bank_name" value={formData.party_bank_name} onChange={handleChange}>
                    <option value="">Select Party Bank Name</option>
                    {party_bank_options.map((party_bank, index) => (
                        <option key={index} value={party_bank}>
                        {party_bank}
                        </option>
                    ))}
                </Form.Select>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Alt. PI Number:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="alt_pi_no"
                value={formData.alt_pi_no}
                onChange={handleChange}
                placeholder="Alternative PI Number"
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>PI Number *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            {(PINumbers.length > 0) ? (
                PINumbers.map((pi, index) => (
                    <div className='d-flex'>
                        <input type="checkbox" value={pi.pi_id} onChange={handleCheckboxChange} checked={selectedPIIds.includes(pi.pi_id.toString())}  style={{marginRight:'10px'}} />
                        <p><strong>{pi.pi_no}</strong></p>
                    </div>
                ))):(
                 <p>No PI</p>
                )}
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>LC No. *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="lc_no"
                value={formData.lc_no}
                onChange={handleChange}
                placeholder="LC No."
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>LC Date *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="date"
                name="lc_date"
                value={formData.lc_date}
                onChange={handleChange}
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Amendment Info.:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="amendment_info"
                value={formData.amendment_info}
                onChange={handleChange}
                placeholder="Amendment Info"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Export LC/SC No. :</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="contract_no"
                value={formData.contract_no}
                onChange={handleChange}
                placeholder="Export LC/SC No."
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Export LC/SC Date :</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="date"
                name="contract_date"
                value={formData.contract_date}
                onChange={handleChange}
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Net weight:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="netweight"
                value={formData.netweight}
                onChange={handleChange}
                placeholder="Net weight"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Gross weight:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="text"
                name="grossweight"
                value={formData.grossweight}
                onChange={handleChange}
                placeholder="Gross weight"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Others Info :</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                as='textarea'
                name="others_info"
                value={formData.others_info}
                onChange={handleChange}
                placeholder="Others Info"
                
            />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={4}>
            <Form.Label><strong>Expiry Date *:</strong></Form.Label>
            </Col>
            <Col style={{width:'300px'}}>
            <Form.Control
                type="date"
                name="expiry_date"
                value={formData.expiry_date}
                onChange={handleChange}
                
            />
            </Col>
        </Row>
        <Row className='d-flex justify-content-end'>
            <Col sm={8}>
                {(commercial_id == null) ?
                
                (<Button variant="primary" onClick={handleSaveCommercial}>
                    Save Commercial
                </Button>)
                :(<Button variant="primary" onClick={handleChangeCommercial}>
                    Change Commercial
                </Button>)
            }   
            </Col>
        </Row>
        </Form>
    </Container>
    );
};

export default AddNewCommercialForm;