import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import '../Functions/manage_orders.css';
import Image from "react-bootstrap/Image";
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function GroupPI() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);

            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const fetchDataWithFilters  = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_all_group_pis`, {
            params: { teamname, partyname, buyername, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const handle_edit_group_pi_click = (group_pi_id) => {
        // This is the edit group pi page which I have commented because I don't want this in deployment now
        // navigate('/edit-group-pi', { state: {group_pi_id } });
    }

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Group PI</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered condensed="true" style={{  overflowY: 'auto'  }}>
                        <thead className="cf" style={{ fontSize: '14px',position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr>
                            <th>SL #</th>
                            <th>MPI - GPI / Date</th>
                            <th>Buyer Name</th>
                            <th>PI No / Date</th>
                            <th>Job No</th>
                            <th>Party Name</th>
                            <th>Bank Name</th>
                            <th>Total Price</th>
                            <th>Note</th>
                            <th>LC Opened</th>
                            <th>Print</th>
                        </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px'}}>
                            {orders.map((group_pi, groupIndex) => {
                                // Calculate the total price for the GroupPi
                                const groupPiTotalPrice = group_pi.PIs.reduce((total, pi) => total + pi.TotalPrice, 0);

                                return (
                                <>
                                    {group_pi.PIs.map((pi, piIndex) => (
                                    <tr key={`group-${groupIndex}-pi-${piIndex}`}>
                                        {piIndex === 0 && (
                                        <>
                                            <td rowSpan={group_pi.PIs.length}>{group_pi.serial_no}<i className="ms-3 print-button fa fa-print" onClick={() => window.open(`/group-pi-pdf-viewer/${group_pi.GroupPiId}`, '_blank')}></i></td>
                                            <td rowSpan={group_pi.PIs.length}><i class="fa fa-edit" id="pi_edit_icon" style={{marginRight:'10px'}} onClick={() => handle_edit_group_pi_click(group_pi.GroupPiId)} ></i>{group_pi.GroupPiNo}<hr style={{ border: '1px solid black' }} />{new Date(group_pi.GroupPiDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</td>
                                            <td rowSpan={group_pi.PIs.length}>{pi.BuyerName}</td>
                                        </>
                                        )}
                                        <td>PI/{pi.JobNumber}<hr style={{ border: '1px solid black' }} />{new Date(pi.PiDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(/\//g, '.')}</td>
                                        <td>{pi.JobNumber}</td>
                                        <td>{pi.PartyName}</td>
                                        <td>{pi.CompanyBank}</td>
                                        <td style={{ textAlign: 'right' }}>${pi.TotalPrice.toFixed(2)}</td>
                                        <td>{pi.Note}</td>
                                        <td></td>
                                        <td>
                                        <i className="print-button fa fa-print"></i>
                                        </td>
                                    </tr>
                                    ))}
                                    <tr className="green_row">
                                    <td colSpan="6"></td>
                                    <td style={{ textAlign: 'right' }}><strong>Grand Total:</strong></td>
                                    <td style={{ textAlign: 'right' }}><strong>${groupPiTotalPrice.toFixed(2)}</strong></td>
                                    <td style={{ textAlign: 'right' }}><strong>LC Total:</strong></td>
                                    <td style={{ textAlign: 'right' }}><strong></strong></td>
                                    <td></td>
                                    </tr>
                                </>
                                );
                            })}
                        </tbody>
                    </Table>
                    {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default GroupPI