import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import ManagePartyTable from '../Tables/manage_party_table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import NavigationBar from '../NavigationBar';
import { useState, useEffect } from 'react';
import {renderPagination, renderTableData, PartyTable, renderTableData2, renderAdvancedPagination } from '../utilities';
import axios from '../axios';

function Manage_party() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [saveClick, setSaveClick] = useState(false);
  // const [selectedEntry, setSelectedEntry] = useState(null);

  useEffect(() => {
      fetchData(currentPage);
    }, [currentPage]);
  
  const fetchData = async (page) => {
      try {
          const response = await axios.get(`${window.backendUrl}/get_manage_party_table`, {
          params: {
              page: page
          }
          });
          setData(response.data.data);
          setCurrentPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const handleDeleteParty = (entry) => {
    axios.delete(`${window.backendUrl}/delete_manage_party/${entry['PartyID']}`)
    .then((response) => {
        console.log('Success:', response.data);
    })
    .catch((error) => {
        console.error('Error:edidq', error);
    })
    .then(() => {
      window.location.reload();
    })
  };

  return (
    <>
    <NavigationBar />
    <Container fluid >
    <Row >
      <Col xs='auto'>
        <h3 style={{margin:'20px 20px'}}>Manage Party</h3>
      </Col>
      <Col xs={6} className='d-flex align-items-end'>
        <Form>
            <Row className="mb-3">
                <Col>
                  <Button variant="primary" href="/add-new-party">Add New Party</Button>
                </Col>
                <Col style={{marginLeft:'100px'}}>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
        </Form>
      </Col>
    </Row>
    <Row>
        <Col className="d-flex justify-content-center" style={{margin:'20px 20px'}}>
          <Table striped bordered hover>
              <thead>
                  <tr>
                  <th>SL</th>
                  <th>Company</th>
                  <th>Contact No</th>
                  <th>Address</th>
                  <th>Team</th>
                  <th>USD / BDT</th>
                  <th>Payment Type</th>
                  <th>View, Edit or Delete Party</th>
                  </tr>
              </thead>
              <tbody>
                  {/* {renderPartyTableData(currentPage, data, handleDeleteParty, 'PartyName','ContactNo','Address','TeamName','Currency','PaymentType')} */}
                  <PartyTable
                    currentPage={currentPage} // Example value
                    data={data}
                    handleDeleteParty={handleDeleteParty}
                    fields={['PartyName','ContactNo','Address','TeamName','Currency','PaymentType']} // Example fields
                  />
              </tbody>
          </Table>
        </Col>
    </Row>
    </Container>
    </>
  )
}

export default Manage_party
