import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, Edit_Bill_Modal, formatNumberWithCommas} from '../utilities';
import { DateRangePicker } from 'rsuite';
import '../Functions/manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function CreateBill() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        itemname: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, itemname} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_create_bill_orders`, {
            params: { teamname, partyname, itemname, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const [showModal, setShowModal] = useState(false);
    const [modal_bill_id, set_modal_bill_id]=useState('');

    const handle_create_bill = (order_id) => {
        const bill_date = Math.floor(Date.now() / 1000);

        axios.post(`${window.backendUrl}/create_bill`, {order_id, bill_date})
        .then((response) => {
            set_modal_bill_id(response.data.bill_id);
            setShowModal(true);
            fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    
    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Create bill</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'350px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'350px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'350px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '13px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th>SL</th>
                                <th>Date/ <br/>Last DD</th>
                                <th>Job No./ <br/>Style/PO</th>
                                <th>Company</th>
                                <th>Team</th>
                                <th>Items</th>
                                <th>Speci</th>
                                <th>Measurement</th>
                                <th>Order<br/> Qty.</th>
                                <th>Prod. <br/>Done</th>
                                <th>Deli. Qty.</th>
                                <th>Ready for <br/>Deli.</th>
                                <th>Balance <br/>Qty</th>
                                <th>T.Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, orderIndex) => {
                                let totalOrderQuantity = 0;
                                let totalPrice = 0;
                                let totalFinishedQty = 0;
                                let totalDeliveryQty = 0;
                                let totalReadyForDeliQty = 0;
                                let totalDeliveryBalanceQty = 0;

                                const orderRows = order.items.map((item, itemIndex) => (
                                    item.specifications.map((spec, specIndex) => (
                                        spec.gsms.map((gsm, gsmIndex) => {
                                            totalOrderQuantity += gsm.orderQuantity;
                                            totalPrice += gsm.orderQuantity * parseFloat(gsm.unitPrice);
                                            totalFinishedQty += gsm.finished_qty;
                                            totalDeliveryQty += gsm.delivery_qty;
                                            totalReadyForDeliQty += gsm.ready_for_deli_qty;
                                            totalDeliveryBalanceQty += gsm.delivery_balance_qty;
                                            const rowSpanLength = order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0) + 1;

                                            return (
                                                <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`}>
                                                    {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                        <>
                                                            <td rowSpan={rowSpanLength}>{order.serial_no}</td>
                                                            <td rowSpan={rowSpanLength}>{new Date(order.order_date * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</td>
                                                            <td rowSpan={rowSpanLength}>{order.jobNo}<hr style={{border: '1px solid black' }}/>{order.style}</td>
                                                            <td rowSpan={rowSpanLength}>{order.party_name}</td>
                                                            <td rowSpan={rowSpanLength}>{order.team}</td>
                                                        </>
                                                    )}
                                                    {gsmIndex === 0 && specIndex === 0 && (
                                                        <td rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.item_name}</td>
                                                    )}
                                                    {gsmIndex === 0 && (
                                                        <td rowSpan={spec.gsms.length}>{spec.specification}</td>
                                                    )}
                                                    <td>{gsm.measurement}</td>
                                                    <td>{gsm.orderQuantity}</td>
                                                    <td>{gsm.finished_qty}</td>
                                                    <td>{gsm.delivery_qty}</td>
                                                    <td>{gsm.ready_for_deli_qty} Pcs</td>
                                                    <td>{gsm.delivery_balance_qty} Pcs</td>
                                                    <td>৳{formatNumberWithCommas((gsm.orderQuantity * parseFloat(gsm.unitPrice)).toFixed(2))}</td>
                                                    {/* <td><Button variant="primary">Action</Button></td> */}
                                                    {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                        <td rowSpan={rowSpanLength-1}>100%</td>
                                                    )}
                                                </tr>
                                            );
                                        })
                                    ))
                                ));

                                return (
                                    <>
                                        {orderRows}
                                        <tr className='pink_row'>
                                            <td colSpan="3" style={{textAlign:'right'}}><strong>Total: </strong></td>
                                            <td><strong>{totalOrderQuantity}</strong></td>
                                            <td><strong>{totalFinishedQty}</strong></td>
                                            <td><strong>{totalDeliveryQty}</strong></td>
                                            <td><strong>{totalReadyForDeliQty}</strong></td>
                                            <td><strong>{totalDeliveryBalanceQty }</strong></td>
                                            <td><strong>৳{formatNumberWithCommas(totalPrice.toFixed(2))}</strong></td>
                                            <td style={{width:'100px'}}><div className='create_bill_btn' onClick={()=>{handle_create_bill(order.orderId)}}>Cr. Bill</div></td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Edit_Bill_Modal show={showModal} setShow={setShowModal} bill_id={modal_bill_id}/>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default CreateBill