import React from 'react';
import {useState} from 'react';

const TruckReceipt = ({table_info, commercial_info}) => {
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));

    return (
      <div style={{ paddingTop: '10px', display: 'block', fontSize: '13px' }}>
        <table width="100%" border="0" cellSpacing="5" cellPadding="0">
          <tbody>
            <tr>
              <td align="center" valign="top">
                <h1 className='challan_header' style={{ fontSize: '40px', margin: 0, color:'blue'}}>{companyTitle}</h1>
                <p style={{ fontSize: '16px', margin: 0 }}>
                  Factory &amp; Office : Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.<br />
                  Phone: +8801819239970, +8801713130713, Email: palash.orixpackaging@gmail.com
                </p>
                <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: '100%', border: '0', borderCollapse: 'collapse', padding: '0' }}>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }}>
                <p style={{ textDecoration: 'underline', fontSize:'20px', fontWeight: 'normal', margin: '0 0 15px' }}>TRUCK RECEIPT</p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                <table style={{ width: '100%', border: '0', borderCollapse: 'collapse', padding: '0' }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <span style={{ fontSize: '15px' }}>M/S Sadesh Transport Agencies</span><br />
                        25, Tejgoan rail gate, Tejgoan, Dhaka<br />
                        Phone : 9138687<br />
                        TRUCK NO. : COMILLA-KA-14-2108
                      </td>
                      <td style={{ textAlign: 'right', width: '35%' }}>
                        <div style={{ textAlign: 'center', width: '180px', border: '1px solid #333', padding: '8px 15px', marginTop: '0' }}>
                          <strong style={{ fontSize: '15px' }}>FREIGHT PREPAID</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                <table style={{ width: '100%', border: '0', borderCollapse: 'collapse', padding: '0' }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        TR No. <strong>{commercial_info.invoiceNo}</strong>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        DATE:        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', padding: '10px 0' }}>
                <table style={{ width: '100%', border: '0', borderCollapse: 'collapse', padding: '0' }}>
                  <tbody>
                    <tr>
                      <td width="55%" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                      <table width="100%" border="0" cellSpacing="5" cellPadding="0">
                  <tbody>
                    <tr className='no_padding_no_vertical_align'>
                      <td width="55%" align="left" valign="top">
                        <table width="100%" border="0" cellSpacing="5" cellPadding="0">
                          <tbody className='no_padding_no_vertical_align'>
                            <tr>
                              <td width="100" align="left" valign="top">EXPORTER</td>
                              <td align="left" valign="top">:</td>
                              <td align="left">
                                <strong>{companyTitle}</strong><br />
                                Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
                              </td>
                            </tr>
                            <tr><td align="left" valign="top">&nbsp;</td></tr>
                            <tr>
                              <td width="100" align="left" valign="top">NOTIFY PARTY</td>
                              <td align="left" valign="top">:</td>
                              <td align="left">
                                <strong>{commercial_info.partyName}</strong><br />
                                {/* East Narshinghapur, Ashulia<br />
                                Savar, Dhaka */}
                                {table_info[0].party_address}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td align="left" valign="top">
                        CONSIGNEE:<br />
                        <strong>{commercial_info.partyBank}</strong><br />
                        {commercial_info.partyBankAddress.split(',').reduce((acc, word, index) => {
                          if (index > 0 && index % 4 === 0) acc.push(<br key={index} />);
                          acc.push(word.trim());
                          return acc;
                        }, []).map((item, index) => (
                          <span key={index}>
                            {item}
                            {index < commercial_info.partyBankAddress.split(',').length - 1 ? ',' : ''}
                          </span>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', paddingTop: '10px' }}>
                FROM : BENEFICIARY'S FACTORY, TO : OPENER'S FACTORY
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', padding: '10px 0' }}>
                <table style={{ width: '100%', border: '0', borderCollapse: 'collapse', padding: '0' }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left', verticalAlign: 'top', whiteSpace: 'nowrap' }}>
                        <p>Buyer: {table_info[0].buyer_name}</p>
                      </td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
                <table width="100%" cellPadding="0" cellSpacing="0" style={{ border: '1px solid #000',marginBottom:'20px' }}>
                <tr>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>SL</th>
                  <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Date</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>LPD No.</th>
                  <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Style/Order/File No.</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Item Name</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Spec.</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Measurement</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Qty</th>
                  <th style={{ border: '1px solid black',  padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Sq.Mt./Pcs</th>
                </tr>
                <tbody class='challan_pdf_table'>
                  {table_info.map((order, orderIndex) => {
                    const totalOrderQuantity = order.items.reduce((total, item) => {
                      return total + item.specifications.reduce((specTotal, spec) => {
                        return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.orderQuantity, 0);
                      }, 0);
                    }, 0);

                    const orderRows = order.items.map((item, itemIndex) => (
                      item.specifications.map((spec, specIndex) => {
                        const gsmRowsLength = spec.gsms.length;

                        return spec.gsms.map((gsm, gsmIndex) => {
                          const rowSpanLength = order.items.reduce((acc, item) => {
                            return acc + item.specifications.reduce((specAcc, spec) => specAcc + spec.gsms.length, 0);
                          }, 0);

                          const itemRowSpanLength = item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0);
                          
                          return (
                            <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`}>
                              {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                <>
                                  <td rowSpan={rowSpanLength + 1}>{orderIndex + 1}</td>
                                  <td rowSpan={rowSpanLength + 1}>
                                    {new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
                                  </td>
                                  <td rowSpan={rowSpanLength + 1}>{order.lpd_po_no}</td>
                                  <td rowSpan={rowSpanLength + 1}>{order.style}</td>
                                </>
                              )}
                              {gsmIndex === 0 && (
                                <td rowSpan={itemRowSpanLength}>{item.item_name}</td>
                              )}
                              {gsmIndex === 0 && (
                                <td rowSpan={gsmRowsLength}>{spec.specification}</td>
                              )}
                              <td>
                                {gsm.measurement.split('\n').map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </td>
                              <td>{gsm.orderQuantity}/Pcs</td>
                              <td>{gsm.squareMeterPerPiece}</td>
                            </tr>
                          );
                        });
                      })
                    ));

                    return (
                      <>
                        {orderRows}
                        <tr>
                          <td colSpan={3} style={{ textAlign: 'right' }}><strong>LPD No. Total: </strong></td>
                          <td><strong>{totalOrderQuantity}</strong></td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                      <td colSpan={7} style={{ textAlign: 'right' }}><strong>Overall Total: </strong></td>
                      <td>
                      <strong>
                        {table_info.reduce((grandTotal, order) => {
                          return grandTotal + order.items.reduce((total, item) => {
                            return total + item.specifications.reduce((specTotal, spec) => {
                              return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.orderQuantity, 0);
                            }, 0);
                          }, 0);
                        }, 0)}
                        </strong>
                      </td>
                      <td></td>
                  </tr>
                </tbody>
              </table>
                <tr >
                  <td align="left" style={{ padding: '10px 0' }}>
                    DOCUMENTARY CREDIT NO. <strong>{commercial_info.lcNo}</strong>, DATE :  <strong>{new Date(commercial_info.lcDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    Export LC/SC NO. <strong>{commercial_info.contractNo}</strong> <strong>  DATE : {new Date(commercial_info.contractDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
                  </td>
                </tr>
                <tr>
                  <td align="left" style={{ padding: '10px 15px 0 0' }}>
                    ISSUING BANK BIN NO. <strong>{commercial_info.partyBankBin}</strong><br />
                    BENEFICIARY'S VAT REG. NO. 18051014507, BIN NO. 000353482-0102, H.S CODE NO. 4819.10.00<br/>
                    PROFORMA INVOICE NO. {commercial_info.piInfo.map((pi, index) => <span key={pi.piId}><strong>PI/{pi.jobNumber}  </strong> DATE:<strong> {new Date(pi.createdDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.')}</strong>{index < commercial_info.piInfo.length - 1 ? ', ' : ''}</span>)}<br />
                  </td>
                </tr>
                <tr>
                  <td align="left" style={{ padding: '10px 0' }}>
                    IN APPARENT WE RECEIVED THE AFORESAID GOODS IN GOOD ORDER AND CONDITION.
                  </td>
                </tr>
              </td>
            </tr>
            <tr >
                <td style={{ padding: "0", margin: "0" }}>
                    <table style={{ width: "100%", border: "0", cellspacing: "0", cellpadding: "0", marginTop:'40px' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "33%", textAlign: "left", verticalAlign: "top", padding: "20px" }}>
                                    <strong>RECEIVED BY</strong>
                                    <div style={{ height: "80px" }}></div>
                                    <span style={{ borderTop: "1px solid black", marginTop: "10px" }}>AUTHORISED SIGNATURE</span>
                                </td>
                                <td style={{ width: "33%", textAlign: "center", verticalAlign: "top", padding: "20px" }}>
                                    ON BEHALF OF<br />
                                    <strong style={{ fontSize: "13px" }}>{companyTitle}</strong><br />
                                    <img style={{ maxWidth: "150px" }} src="/Images/miah_and_sons_signature.png" alt="AUTHORISED SIGNATURE" /><br />
                                    <span style={{ borderTop: "1px solid black", marginTop: "10px" }}>AUTHORISED SIGNATURE</span>
                                </td>
                                <td style={{ width: "33%", textAlign: "right", verticalAlign: "top", padding: "20px" }}>
                                    ON BEHALF OF<br />
                                    <strong style={{ fontSize: "13px" }}>M/S Sadesh Transport Agencies</strong>
                                    <div style={{ height: "80px" }}></div>
                                    <span style={{ borderTop: "1px solid black", marginTop: "10px" }}>AUTHORISED SIGNATURE</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>


          </tbody>
        </table>
      </div>
    );
  };

export default TruckReceipt;