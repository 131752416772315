import React from 'react';
import {useState} from 'react';
import { formatNumberWithCommas, convertNumberToWords } from '../../utilities';

const CommercialInvoice = ({table_info, commercial_info}) => {
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));

  return (
    <div style={{ paddingTop: '10px', display: 'block', fontSize: '13px' }}>
      <table width="100%" border="0" cellSpacing="5" cellPadding="0">
        <tbody>
          <tr>
            <td align="center" valign="top">
              <h1 className='challan_header' style={{ fontSize: '40px', margin: 0, color:'blue'}}>{companyTitle}</h1>
              <p style={{ fontSize: '16px', margin: 0 }}>
                Factory &amp; Office : Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.<br />
                Phone: +8801819239970, +8801713130713, Email: palash.orixpackaging@gmail.com
              </p>
              <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: 'center' }}>
        <p style={{ textDecoration: 'underline', fontSize:'20px', fontWeight: 'normal', margin: '0 0 15px' }}>COMMERCIAL INVOICE</p>
      </div>
      <table style={{ width: '100%', border: '0', cellspacing: '5', cellpadding: '0' }}>
        <tbody>
          <tr>
            <td style={{ width: '55%', textAlign: 'left', verticalAlign: 'top' }}>
              <table style={{ width: '100%', border: '0', cellspacing: '5', cellpadding: '0' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '15%', textAlign: 'left', verticalAlign: 'top' }}>
                      INVOICE NO.
                    </td>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>:</td>
                    <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{commercial_info.invoiceNo}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>DATE</td>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>:</td>
                    <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{new Date(commercial_info.commercialDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: 'nowrap', textAlign: 'left', verticalAlign: 'top' }}>
                      DOCUMENTARY CREDIT NO.
                    </td>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>:</td>
                    <td style={{ textAlign: 'left' }}>
                      <strong>{commercial_info.lcNo}</strong>, DATE : <strong>{new Date(commercial_info.lcDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>Export LC/SC NO.</td>
                    <td style={{ textAlign: 'left', verticalAlign: 'top' }}>:</td>
                    <td style={{ textAlign: 'left' }}>
                      <strong>{commercial_info.contractNo}</strong> <strong> DATE: <strong>{new Date(commercial_info.contractDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong></strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'left', verticalAlign: 'top', padding: '10px 15px 0 0' }}>
                      ISSUING BANK BIN NO. <strong>{commercial_info.partyBankBin}</strong><br />
                      BENEFICIARY'S VAT REG. NO. 18051014507, BIN NO. 000353482-0102, H.S CODE NO. 4819.10.00
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'left', verticalAlign: 'top', paddingTop: '5px' }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'left', verticalAlign: 'top', paddingTop: '5px' }}>
                      SHIPPED : BY TRUCK<br />
                      FROM : BENEFICIARY'S FACTORY, TO : OPENER'S FACTORY
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ textAlign: 'left', verticalAlign: 'top', paddingLeft: '10px' }}>
              <div>
                SHIPPER / EXPORTER:<br />
                <strong>{companyTitle}</strong><br />
                Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
                <br /><br />
                CONSIGNEE:<br />
                <strong>{commercial_info.partyBank}</strong><br />
                {commercial_info.partyBankAddress.split(',').reduce((acc, word, index) => {
                        if (index > 0 && index % 4 === 0) acc.push(<br key={index} />);
                        acc.push(word.trim());
                        return acc;
                      }, []).map((item, index) => (
                        <span key={index}>
                          {item}
                          {index < commercial_info.partyBankAddress.split(',').length - 1 ? ',' : ''}
                        </span>
                ))}
                <br /><br />
                NOTIFY PARTY:<br />
                <strong>{commercial_info.partyName}</strong><br />
                {table_info[0].party_address}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'20px' }}>
        <div>
          ACCESSORIES FOR READYMADE GARMENTS INDUSTRY
        </div>
        <div>
          <strong>CPT</strong>
        </div>
      </div>
      <div style={{marginTop:'10px'}}>
        <div>
          <p>Buyer: CALERES</p>
        </div>
        <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>SL</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Date</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>LPD No.</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Style/Order/File No.</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Item Name</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Spec.</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Measurement</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Qty</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Sq.Mt./Pcs</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Unit Price</th>
            <th style={{ border: '1px solid black', padding: '8px 10px', backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'center' }}>Total Amount</th>
          </tr>
          </thead>
          <tbody className='challan_pdf_table'>
            {table_info.map((order, orderIndex) => {
              const totalOrderQuantity = order.items.reduce((total, item) => {
                return total + item.specifications.reduce((specTotal, spec) => {
                  return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.orderQuantity, 0);
                }, 0);
              }, 0);

              const totalOrderPrice = order.items.reduce((total, item) => {
                return total + item.specifications.reduce((specTotal, spec) => {
                  return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.totalPrice, 0);
                }, 0);
              }, 0);

              const orderRows = order.items.map((item, itemIndex) =>
                item.specifications.map((spec, specIndex) => {
                  const gsmRowsLength = spec.gsms.length;

                  return spec.gsms.map((gsm, gsmIndex) => {
                    const rowSpanLength = order.items.reduce((acc, item) => {
                      return acc + item.specifications.reduce((specAcc, spec) => specAcc + spec.gsms.length, 0);
                    }, 0);

                    const itemRowSpanLength = item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0);

                    return (
                      <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`}>
                        {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                          <>
                            <td rowSpan={rowSpanLength + 1}>{orderIndex + 1}</td>
                            <td rowSpan={rowSpanLength + 1}>
                              {new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
                            </td>
                            <td rowSpan={rowSpanLength + 1}>{order.lpd_po_no}</td>
                            <td rowSpan={rowSpanLength + 1}>{order.style}</td>
                          </>
                        )}
                        {gsmIndex === 0 && (
                          <td rowSpan={itemRowSpanLength}>{item.item_name}</td>
                        )}
                        {gsmIndex === 0 && (
                          <td rowSpan={gsmRowsLength}>{spec.specification}</td>
                        )}
                        <td>
                          {gsm.measurement.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </td>
                        <td>{gsm.orderQuantity}/Pcs</td>
                        <td>{gsm.squareMeterPerPiece}</td>
                        <td>${gsm.unitPrice}</td>
                        <td>${formatNumberWithCommas((gsm.totalPrice).toFixed(3))}</td>
                      </tr>
                    );
                  });
                })
              );

              return (
                <>
                  {orderRows}
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'right' }}><strong>LPD No. Total: </strong></td>
                    <td><strong>{totalOrderQuantity}</strong></td>
                    <td colSpan='2'></td>
                    <td><strong>${formatNumberWithCommas(totalOrderPrice.toFixed(2))}</strong></td>
                  </tr>
                </>
              );
            })}

            <tr>
              <td colSpan={7} style={{ textAlign: 'right' }}><strong>Overall Total: </strong></td>
              <td>
                <strong>
                  {table_info.reduce((grandTotal, order) => {
                    return grandTotal + order.items.reduce((total, item) => {
                      return total + item.specifications.reduce((specTotal, spec) => {
                        return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.orderQuantity, 0);
                      }, 0);
                    }, 0);
                  }, 0)}
                </strong>
              </td>
              <td colSpan='2'></td>
              <td>
                <strong>
                  ${formatNumberWithCommas(table_info.reduce((grandTotal, order) => {
                    return grandTotal + order.items.reduce((total, item) => {
                      return total + item.specifications.reduce((specTotal, spec) => {
                        return specTotal + spec.gsms.reduce((gsmTotal, gsm) => gsmTotal + gsm.totalPrice, 0);
                      }, 0);
                    }, 0);
                  }, 0).toFixed(2))}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{marginTop:'20px'}}>
          <tbody>
            <tr>
              <td align="left" style={{ marginTop: '10px', marginBottom: '10px', fontSize: '13px' }}>
                <strong>In Words: <strong>{convertNumberToWords(commercial_info.totalPrice)}</strong></strong>
              </td>
            </tr>
            <tr>
              <td align="left">
                WE CERTIFY THAT THE GOODS SHIPPED ARE STRICTLY IN ACCORDANCE WITH THE SPECIFICATION QUALITY, QUANTITY, PACKING, MARKING AND PRICE ARE AS PER PROFORMA INVOICE NO.  {commercial_info.piInfo.map((pi, index) => <span key={pi.piId}><strong>PI/{pi.jobNumber}  </strong> DATE:<strong> {new Date(pi.createdDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.')}</strong>{index < commercial_info.piInfo.length - 1 ? ', ' : ''}</span>)} AND HANDED OVER IN GOOD CONDITION TO BUYERS BONDED WAREHOUSE.<br />
                <br />
                WE ALSO CERTIFY THAT MERCHANDISE IS OF BANGLADESHI ORIGIN.
              </td>
            </tr>
            <tr>
              <td align="left">
                <table width="100%" border="0" cellSpacing="5" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="55%" align="left" valign="top" style={{ padding: '0' }}>
                        &nbsp;
                      </td>
                      <td align="right" valign="top" style={{ padding: '0' }}>
                        <p style={{ margin: '0', padding: '0' }}><br /><br /></p>
                        ON BEHALF OF<br />
                        <strong style={{ fontSize: '15px' }}>{companyTitle}</strong>
                        <br /><img style={{ maxWidth: '150px' }} src="/Images/miah_and_sons_signature.png" alt="AUTHORISED SIGNATURE" /><br />
                        <span style={{ borderTop: '1px solid' }}>AUTHORISED SIGNATURE</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommercialInvoice;
