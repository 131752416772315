import React from 'react'
import NavigationBar from '../NavigationBar';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from '../axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../Functions/manage_orders.css';

function EditGroupPi() {
    const location = useLocation();
    const navigate = useNavigate();
    const [group_pi_info, set_group_pi_info] = useState([]);
    const [group_pi_list, set_group_pi_list] = useState([]);

    useEffect(() => {
        if (location.state && location.state.group_pi_id) {
            const group_pi_id = location.state.group_pi_id
  
            axios.get(`${window.backendUrl}/get_group_pi_info/${group_pi_id}`)
            .then(response => {
                set_group_pi_info(response.data.group_pi_info);
            })
            .catch(error => {
                console.error('There was an error fetching the order:', error);
            });
        }
      }, []);

    const handleSave = () => {

    }

    const handleRemoveGroupPi = () => {

    }

    return (

        <>
            <NavigationBar />
            <Container fluid style={{fontSize:'15px'}}>
                <Row className='mb-5 mt-3'>
                    <Col>
                        <h3>Master PI</h3>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <p className='mb-2'><strong>Party Name :</strong></p>
                    <div className='d-flex'>
                        <p className='me-2'><strong>Party Address : </strong></p>
                        <textarea rows='3' style={{width:'300px'}} />
                    </div>
                </Row>
                <Row className='mb-4'>
                    <Col className='d-flex'>
                        <div className='d-flex align-items-center'>
                            <p style={{marginRight:'10px'}}><strong>Date :</strong></p>
                            <Form.Control type='date' style={{marginRight:'10px', width:'150px'}}/>
                        </div>

                        <div className='d-flex align-items-center'>
                            <p style={{marginRight:'10px'}}><strong>Group/Master PI No.:</strong></p>
                            <Form.Control type='text' style={{ width:'250px'}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered condensed="true" style={{ fontSize: '13px', overflowY: 'auto'  }}>
                            <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                <tr>
                                    <th>SL</th>
                                    <th>Job Number</th>
                                    <th>Style/Order /File No.</th>
                                    <th>Buyer Name</th>
                                    <th>Item Name</th>
                                    <th>Spec.</th>
                                    <th>Measurement</th>
                                    <th>Qty</th>
                                    <th>Unit Price /Pcs</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-between mt-2'>
                    <Button variant="danger"  onClick={handleRemoveGroupPi} style={{marginLeft: '30px',width:'230px' }}>
                        Remove Group/Master PI
                    </Button>
                    <Button variant="primary" onClick={handleSave} style={{width:'100px', marginLeft:'auto',marginRight:'30px'}}>
                        Save 
                    </Button>
                </Row>
            </Container>
        </>
    )
}

export default EditGroupPi