import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from '../axios';

function Add_new_party_form({ handlePartyDataChange,partyData}) {

    const [teams, setTeams] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_teams`);
            const team_options = response.data.data.map((team) => team['Name']);
            setTeams(team_options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <Container fluid>
            <Row>
                <h5>Add New Party</h5>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={4}>
                    <Form>
                        <Form.Group controlId="currency"  className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Team Name : </Form.Label>
                            <Form.Select name="teamName" value={partyData.teamName}  onChange={handlePartyDataChange} style={{width:'300px'}}>
                            <option value=''>Select team</option>
                                {teams.map((team, index) => (
                                    <option key={index} value={team}>
                                        {team}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {/* Other Form.Group components with similar styles */}
                        <Form.Group controlId="partyName" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Party Name:</Form.Label>
                            <Form.Control style={{ maxWidth:'300px'}} type="text" name="partyName" value={partyData.partyName}  onChange={handlePartyDataChange} />
                        </Form.Group>

                        <Form.Group controlId="address" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Address:</Form.Label>
                            <Form.Control style={{ maxWidth:'300px'}} type="text" name="address" value={partyData.address}  onChange={handlePartyDataChange} />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={4}>
                    <Form>
                        <Form.Group controlId="contactNo" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Contact No.:</Form.Label>
                            <Form.Control style={{ maxWidth:'300px'}} type="text" name="contactNo" value={partyData.contactNo}  onChange={handlePartyDataChange} />
                        </Form.Group>

                        {/* Other Form.Group components with similar styles */}
                        <Form.Group controlId="notes" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Notes:</Form.Label>
                            <Form.Control  style={{ maxWidth:'300px'}} type="text" name="notes" value={partyData.notes}  onChange={handlePartyDataChange} />
                        </Form.Group>

                        <Form.Group controlId="beneficiaryInfo" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Beneficiary Info.:</Form.Label>
                            <Form.Control  style={{ maxWidth:'300px'}} type="text" name="beneficiaryInfo" value={partyData.beneficiaryInfo}  onChange={handlePartyDataChange} />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={4}>
                    <Form> 
                        <Form.Group controlId="currency" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Currency:</Form.Label>
                            <Form.Select name="currency" value={partyData.currency}  onChange={handlePartyDataChange} style={{width:'300px'}}>
                                <option value='$'>USD</option>
                                <option value='BDT'>BDT</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="paymentType" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Payment Type:</Form.Label>
                            <Form.Select name="paymentType" value={partyData.paymentType}  onChange={handlePartyDataChange} style={{width:'300px'}}>
                                <option value='Payment by LC'>Payment by LC</option>
                                <option value='Payment by Cash'>Payment by Cash</option>
                            </Form.Select>
                        </Form.Group>
 
                        <Form.Group controlId="allowance" className="mb-2 d-flex flex-col justify-content-end">
                            <Form.Label className='me-2'>Allowance:</Form.Label>
                            <Row style={{ maxWidth:'300px'}}>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceLW" value={partyData.allowanceLW}  onChange={handlePartyDataChange}/>
                                    <p style={{ fontSize: 'tiny' }}>L+W</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceWH" value={partyData.allowanceWH}  onChange={handlePartyDataChange}/>
                                    <p style={{ fontSize: 'tiny' }}>W+H</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceLength" value={partyData.allowanceLength}  onChange={handlePartyDataChange}/>
                                    <p style={{ fontSize: 'tiny' }}>Length</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceWidth" value={partyData.allowanceWidth}  onChange={handlePartyDataChange} />
                                    <p style={{ fontSize: 'tiny' }}>Width</p>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Add_new_party_form