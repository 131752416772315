import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function ManageTeamForm({ saveClick, setSaveClick }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    contact: '',
    username:'',
    password:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = () => {
    if(formData.name !== ''){
        axios.post(`${window.backendUrl}/insert_manage_team`, formData)
        .then((response) => {
            console.log('Success:', response.data);
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData({
                name: '',
                email: '',
                address: '',
                contact: '',
                username:'',
                password:''
            });
        })
    }
  };

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
      <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Team</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
          <Form.Label>Name* :</Form.Label>
          <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Name">
          <Form.Label>Email :</Form.Label>
          <Form.Control type="text" name="email" value={formData.email} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Address">
          <Form.Label>Address :</Form.Label>
          <Form.Control type="text" name="address" value={formData.address} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Contact No.">
          <Form.Label>Contact No. :</Form.Label>
          <Form.Control type="text" name="contact" value={formData.contact} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Name">
          <Form.Label>Username* :</Form.Label>
          <Form.Control type="text" name="username" value={formData.username} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Name">
          <Form.Label>Password* :</Form.Label>
          <Form.Control type="text" name="password" value={formData.password} onChange={handleInputChange} />
        </Form.Group>

        <Button variant="primary" onClick={handleSubmit}>
          Add
        </Button>
      </Form>
    </div>
  );
}

export default ManageTeamForm;