import React from 'react';
import NavigationBar from '../NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Add_new_party_form from '../Forms/add_new_party_form';
import { useState, useEffect } from 'react';
import { Form,Button } from 'react-bootstrap';
import Item from '../add_new_party_components/item';
import axios from '../axios';
import { useLocation, useNavigate } from 'react-router-dom';

function Add_new_party() {
    const location = useLocation();
    const navigate = useNavigate();
    const [update_mode, set_update_mode] = useState(false);
    const [itemCount, setitemCount] = useState(1);

    const [partyData, setPartyData] = useState({
        teamName: "",
        partyName: "",
        address: "",
        contactNo: "",
        notes: "",
        beneficiaryInfo: "",
        currency: "$",
        paymentType: "Payment by LC",
        allowanceLW: 0,
        allowanceWH: 0,
        allowanceLength: 0,
        allowanceWidth: 0,
        dieCut: 0,
        C: 0
    });
    
    const [partyItems, setPartyItems] = useState([]);

    const handlePartyDataChange = (e) => {
        const { name, value } = e.target;
        setPartyData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    
    const handlePartyItemChange = (e) => {
        const { name, value, dataset } = e.target;
        if (name=='itemName'){
            const item_id = parseInt(dataset.item_id, 10);
        
            // Ensure the item at item_id exists and is an object
            const updatedItems = [...partyItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }
        
            updatedItems[item_id][name] = value;
            setPartyItems(updatedItems);
        }else if (name == 'specName'){
            const item_id = parseInt(dataset.item_id, 10);
            const spec_id = parseInt(dataset.spec_id, 10);

            // Ensure the item at item_id exists and is an object
            const updatedItems = [...partyItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }

            if (!('specification' in updatedItems[item_id])){
                updatedItems[item_id]['specification'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]) {
                updatedItems[item_id]['specification'][spec_id]={}
            }

            updatedItems[item_id]['specification'][spec_id][name]= value
            setPartyItems(updatedItems);
            // console.log(updatedItems)
        }else if (name== 'gradeName' || name == 'price'){
            const item_id = parseInt(dataset.item_id, 10);
            const spec_id = parseInt(dataset.spec_id, 10);
            const grade_id = parseInt(dataset.grade_id, 10);

            // Ensure the item at item_id exists and is an object
            const updatedItems = [...partyItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }

            if (!('specification' in updatedItems[item_id])){
                updatedItems[item_id]['specification'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]) {
                updatedItems[item_id]['specification'][spec_id]={}
            }

            if (!('grade' in updatedItems[item_id]['specification'][spec_id])){
                updatedItems[item_id]['specification'][spec_id]['grade'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]['grade'][grade_id]) {
                updatedItems[item_id]['specification'][spec_id]['grade'][grade_id]={}
            }

            updatedItems[item_id]['specification'][spec_id]['grade'][grade_id][name]= value
            setPartyItems(updatedItems);
        }
    };

    const handleSubmit = () => {
        const payload = { partyData, partyItems };
        axios.post(`${window.backendUrl}/insert_party`, payload)
            .then(response => {
                alert('Party added successfully.');
                navigate('/manage-party')
            })
            .catch(error => {
                console.error('There was an error saving the data:', error);
            })
    };

    const handleUpdate = () => {
        const payload = { partyData, partyItems };
        axios.post(`${window.backendUrl}/update_party`, payload)
            .then(response => {
                alert('Party updated successfully.');
                navigate('/manage-party')
            })
            .catch(error => {
                console.error('There was an error saving the data:', error);
            })
    }

    useEffect(() => {
        if (partyItems.length === 0) {
          setitemCount(1);
        } else {
          setitemCount(partyItems.length);
        }
    }, [partyItems.length]);

    useEffect(() => {
        if (location.state && location.state.partyId) {
            const partyId = location.state.partyId
            
            axios.get(`${window.backendUrl}/get_party/${partyId}`)
            .then(response => {
                setPartyData(response.data.partyData);
                setPartyItems(response.data.partyItems);
                set_update_mode(true);//dont forget to set this when update button is clicked
            })
            .catch(error => {
                console.error('There was an error fetching the order:', error);
                // Handle any additional error actions here
            });
        }
      }, [location.state]);

    return (
        <>
            <NavigationBar />
            <Container fluid style={{ marginTop: '20px',width:'97%' }}>
                <Row>
                    <Col xs={12}>
                        <Add_new_party_form handlePartyDataChange={handlePartyDataChange} partyData={partyData}/>
                    </Col>
                </Row>
                <Row style={{marginTop:'15px'}}>
                    <Col xs={12} lg={3}>
                        <Container fluid style={{ width: '97%',padding: '15px', border: '1px solid rgba(0,0,0,0.3)',borderRadius: '5px',}}>
                            <Row style={{ fontSize: '18px' }}>
                                <Col className="me-4">
                                    <p>Additional work</p>
                                </Col>
                                <Col>
                                    <p>Price</p>
                                </Col>
                                <hr />
                            </Row>
                            <Row style={{ fontSize: '15px' }}>
                                <Col className="me-4">
                                    <p>DIE CUT</p>
                                </Col>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="dieCut">
                                            <Form.Control type="text" name="dieCut" value={partyData.dieCut}  onChange={handlePartyDataChange} style={{ maxWidth: '100px' }}/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row style={{ fontSize: '15px',marginTop:'20px' }}>
                                <Col className="me-4">
                                    <p>C</p>
                                </Col>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="C">
                                            <Form.Control type="text" name="C" value={partyData.C}  onChange={handlePartyDataChange} style={{ maxWidth: '100px' }}/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} lg={9}>
                        {/* Component */}
                        <Container fluid style={{padding: '15px', border: '1px solid rgba(0,0,0,0.3)',borderRadius: '5px',}}>
                            <Row className="flex-column flex-md-row"  style={{ fontSize: '18px' }}>
                                <Col className="me-4">
                                    <p>Item Name</p>
                                </Col>
                                <Col>
                                    <p>Specification</p>
                                </Col>
                                <Col>
                                    <p>Grade</p>
                                </Col>
                                <Col>
                                    <p>Price</p>
                                </Col>
                                <hr />
                            </Row>
                            <Row>
                                {/* item component */}
                                {Array.from({ length: itemCount }, (_, index) => (
                                    <Item key={index} item_id={index} itemCount={itemCount} setitemCount={setitemCount} partyItems={partyItems} setPartyItems={setPartyItems} handlePartyItemChange={handlePartyItemChange} />
                                ))}
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className='mt-4 mb-4'>
                    {/* <Col xs={6}>
                        <Button variant="warning">Reset</Button>
                    </Col> */}
                    <Col className="text-end me-2 mt-5">
                        {update_mode ? (<Button variant="primary" onClick={handleUpdate}>Update</Button>):
                        (<Button variant="primary" onClick={handleSubmit}>Save</Button>)}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Add_new_party;
