import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';
import BillOfExchange1 from './ComponentsOfCommercialPDF/BillOfExchange1';
import BillOfExchange2 from './ComponentsOfCommercialPDF/BillOfExchange2';
import DeliveryChallan from './ComponentsOfCommercialPDF/DeliveryChallan';
import CommercialInvoice from './ComponentsOfCommercialPDF/CommercialInvoice';
import PackingList from './ComponentsOfCommercialPDF/PackingList';
import WeightAndMeasurement from './ComponentsOfCommercialPDF/WeightAndMeasurement';
import TruckReceipt from './ComponentsOfCommercialPDF/TruckReceipt';
import CertificateOfOrigin from './ComponentsOfCommercialPDF/CertificateOfOrigin';
import BeneficiaryCertificate from './ComponentsOfCommercialPDF/BeneficiaryCertificate';
import PreShipmentCertificate from './ComponentsOfCommercialPDF/PreshipmentCertificate';

const CommercialPDF = () => {
  const { commercialId } = useParams();
  const [table_info, set_table_info] = useState([]);
//   const [challan_details_info, set_challan_details_info] = useState({});
  const [commercial_info, set_commercial_info] = useState({});
  const [loading, setLoading] = useState(true);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  // const [categoryTotals,setCategoryTotals] = useState('');

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (commercialId) {
      fetch_commercial_pdf_info();
    }
  }, [commercialId]);

  // const fetch_commercial_pdf_info = () => {
  //   axios.get(`${window.backendUrl}/get_commercial_info_for_pdf/${commercialId}`)
  //   .then((response) => {
  //     console.log(response.data)
  //     set_commercial_info(response.data);
  //     setLoading(false);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setLoading(false);
  //   });
  // };
  const fetch_commercial_pdf_info = () => {
    Promise.all([
      axios.get(`${window.backendUrl}/get_commercial_info_for_pdf/${commercialId}`),
      axios.get(`${window.backendUrl}/get_commercial_pdf_table_info/${commercialId}`)
    ])
    .then((responses) => {
      set_commercial_info(responses[0].data);
      set_table_info(responses[1].data);
      // You can handle the second response if needed
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  

  if (loading) {
    return <></>
  }


  return (
    <div style={{ paddingTop: '140px', display: 'block', fontSize: '14px', margin:'0px 100px' }}>
        <BillOfExchange1 commercial_info={commercial_info}/>
        <BillOfExchange2 commercial_info={commercial_info}/>
        <DeliveryChallan commercial_info={commercial_info} table_info={table_info}/>
        <CommercialInvoice commercial_info={commercial_info} table_info={table_info}/>
        <PackingList commercial_info={commercial_info} table_info={table_info}/>
        <WeightAndMeasurement commercial_info={commercial_info} table_info={table_info}/>
        <TruckReceipt commercial_info={commercial_info} table_info={table_info}/>
        <CertificateOfOrigin commercial_info={commercial_info} table_info={table_info}/>
        <BeneficiaryCertificate commercial_info={commercial_info} table_info={table_info}/>
        <PreShipmentCertificate commercial_info={commercial_info} table_info={table_info}/>
    </div>
  );
};

export default CommercialPDF;