import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function AdditionalWorkForm({ saveClick, setSaveClick }) {
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = () => {
    if(formData.name !== ''){
        axios.post(`${window.backendUrl}/insert_additional_work`, formData)
        .then((response) => {
            console.log('Success:', response.data);
        })
        .catch((error) => {
            console.error('Error:edidq', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData({
                name: '',
            });
        })
    }
  };

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
      <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Additional Work</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
          <Form.Label>Additional Work Name* :</Form.Label>
          <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Button variant="primary" onClick={handleSubmit}>
          Add
        </Button>
      </Form>
    </div>
  );
}

export default AdditionalWorkForm;