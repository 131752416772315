import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function ManageSpecificationForm() {
  return (
    <div style={{backgroundColor:'rgba(0,0,0,0.08)',padding:'20px',borderRadius:'5px'}}>
        <Form style={{fontWeight:'bold'}}>
        <h4>Add New Specification</h4>
            <hr></hr>
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Spec name : </Form.Label>
            <Form.Control type="text" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Spec value : </Form.Label>
            <Form.Control type="text" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Formula :  </Form.Label>
            <Form.Control type="text" />
        </Form.Group>
        <Button variant="primary">
            Save
        </Button>
        </Form>
    </div>
  );
}

export default ManageSpecificationForm;