import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useContext } from "react";

function FinishingHistory() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername, itemname, specification} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_finishing_history`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            // console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change
    
    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col>
                <h4>Finishing History</h4>
                </Col>
                <Col>
                    <Button style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'10px', color:'black'}} onClick={() => navigate('/manage-finishing')}>Finishing</Button>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col className='d-flex justify-content-end' style={{paddingRight:'100px'}}>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select  style={{width:'150px'}}> 
                        <option value="">All category</option>
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '12px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ fontSize:'14px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th rowSpan='2'>SL</th>
                                <th rowSpan='2'>PP Date</th>
                                <th rowSpan='2'>Order Date</th>
                                <th rowSpan='2'>Job / Style#</th>
                                <th rowSpan='2'>Company/ Buyer</th>
                                <th rowSpan='2'>Team</th>
                                <th rowSpan='2'>Items</th>
                                <th rowSpan='2'>Spec.</th>
                                <th rowSpan='2'>Measurement</th>
                                <th rowSpan='2'>Order Qty</th>
                                <th rowSpan='2'>B.M. Planning</th>
                                <th colSpan="2" >Finishing History</th>
                                <th rowSpan='2'>Roll Size</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Finishing <br/> Qty</th>   
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, orderIndex) => (
                                <>
                                <tr key={order.ProductionPlanningId}>
                                    <td rowSpan={order.dates.length}>{order.serial_no}</td>
                                    <td rowSpan={order.dates.length}>
                                        {new Date(order.pp_date * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}
                                    </td>
                                    <td rowSpan={order.dates.length}>{new Date(order.OrderDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</td>
                                    <td rowSpan={order.dates.length}>{order.JobNumber} / {order.Style}</td>
                                    <td rowSpan={order.dates.length}>{order.PartyName} / {order.BuyerName}</td>
                                    <td rowSpan={order.dates.length}>{order.TeamName}</td>
                                    <td rowSpan={order.dates.length}>{order.ItemName}</td>
                                    <td rowSpan={order.dates.length}>{order.Specification}</td>
                                    <td rowSpan={order.dates.length}>{order.Measurement}</td>
                                    <td rowSpan={order.dates.length}>{order.OrderQuantity}</td>
                                    <td rowSpan={order.dates.length}>{order.pp_qty}</td>
                                    <td>
                                        {new Date(order.dates[0].date * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(/\//g, '.')}
                                    </td>
                                    <td>{order.dates[0].new_finished_qty}</td>
                                    <td rowSpan={order.dates.length}>{order.RollSize}</td>
                                </tr>
                                {order.dates.slice(1).map((date, dateIndex) => (
                                    <tr key={dateIndex}>
                                        <td>
                                            {new Date(date.date * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(/\//g, '.')}
                                        </td>
                                        <td>{date.new_finished_qty}</td>
                                    </tr>
                                ))}
                                </>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default FinishingHistory