import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, Add_Finishing_Modal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import './manage_orders.css';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function ManageFinishing() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
        done_or_undone: 'Undone'
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };


    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername, itemname, specification, done_or_undone} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_finishing_orders`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, start_date, end_date, done_or_undone}
        })
        .then((response) => {
            // console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const [showAddFinishingModal, setShowAddFinishingModal] = useState(false);
    const [modal_order_details, set_modal_order_details] = useState({});

    const handleAddFinishingClick =(orderDetail)=>{
        set_modal_order_details(orderDetail);
        setShowAddFinishingModal(true);
    }

    const handleAddFinishingSave = (data) => {
        // data.finishing_date=new Date(data.finishing_date * 1000).toLocaleString('en-GB', 
        //     { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true}).replace(/\//g, '.')
        // console.log(data)
        const payload = {
            ProductionPlanningId: modal_order_details.ProductionPlanningId,
            OrderGsmId: modal_order_details.OrderGsmId,
            finishing_date: data.finishing_date,
            new_finishing_qty: data.new_finishing_qty
        };
        axios.post(`${window.backendUrl}/add_new_finishing_qty`, payload)
            .then(response => {
                setShowAddFinishingModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    };

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Manage Finishing</h4>
                </Col>
                <Col xs={6} className='d-flex justify-content-center'>
                    <Button style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'10px', color:'black'}} onClick={() => navigate('/finishing-history')}>Finishing history</Button>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                    <Form.Select style={{width:'170px',marginLeft:'10px'}} name="done_or_undone" value={filters.done_or_undone} onChange={handleInputChange}>
                        <option value="Undone">Undone</option>
                        <option value='Done'>Done</option>
                        <option value='All type'>All type</option>
                    </Form.Select>
                </Col>
                <Col className='d-flex justify-content-end' style={{paddingRight:'100px'}}>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select  style={{width:'150px'}}> 
                        <option value="">All category</option>
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '12px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ fontSize:'14px',position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th>SL</th>
                                <th>PP Date/<br/> Last B.M</th>
                                <th>Ord.Date/<br/>Ex.Deli</th>
                                <th>Job / Style#</th>
                                <th>Company / Buyer</th>
                                <th>Team</th>
                                <th>Items</th>
                                <th>Spec.</th>
                                <th>Measurement</th>
                                <th>Order Qty</th>
                                <th>B.M. Qty</th>
                                <th>Finishing Qty</th>
                                <th>Balance</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.reduce((acc, order, index) => {
                                // Find the party in the accumulator
                                const party = acc.find(p => p.partyName === order.PartyName);
                                if (party) {
                                    // If the party exists, add the order to its orders array and update the totals
                                    party.orders.push(order);
                                    party.totalBoardMakingQty += order.board_making_qty;
                                    party.totalFinishedQty += order.finished_qty;
                                    party.totalBalance +=  order.board_making_qty - order.finished_qty;
                                } else {
                                    // If the party doesn't exist, create a new entry for it
                                    acc.push({
                                        partyName: order.PartyName,
                                        orders: [order],
                                        totalBoardMakingQty: order.board_making_qty,
                                        totalFinishedQty: order.finished_qty,
                                        totalBalance: order.board_making_qty - order.finished_qty
                                    });
                                }
                                return acc;
                            }, []).map((party, partyIndex) => (
                                <React.Fragment key={partyIndex}>
                                    {party.orders.map((order, orderIndex) => (
                                        <tr key={orderIndex}>
                                            <td>{order.serial_no}</td>
                                            <td>{order.pp_date}<hr style={{border: '1px solid black' }}/>{order.last_bm_date}</td>
                                            <td>{new Date(order.OrderDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}<hr style={{border: '1px solid black' }}/>{new Date(order.DeliveryDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</td>
                                            <td>{order.JobNumber} <hr style={{border: '1px solid black' }}/>{order.Style}</td>
                                            <td>{order.PartyName} <hr style={{border: '1px solid black' }}/> {order.BuyerName}</td>
                                            <td>{order.TeamName}</td>
                                            <td>{order.ItemName}</td>
                                            <td>{order.Specification}</td>
                                            <td>{order.Measurement}</td>
                                            <td>{order.OrderQuantity}</td>
                                            <td>{order.board_making_qty}</td>
                                            {/* <td>{order.finished_qty}</td> */}
                                            <td><i class="fa fa-plus" id="add_finishing_icon" onClick={() => handleAddFinishingClick(order)}></i>{order.finished_qty}</td>
                                            <td>{order.board_making_qty- order.finished_qty}</td>
                                            <td style={{width:'100px'}}>{order.Notes}</td>
                                        </tr>
                                    ))}
                                    <tr key={`total-${partyIndex}`} style={{ fontWeight: 'bold' }} className='yellow_row'>
                                        <td colSpan="10" style={{textAlign:'right'}}>Total for {party.partyName} : </td>
                                        <td>{party.totalBoardMakingQty}</td>
                                        <td>{party.totalFinishedQty}</td>
                                        <td>{party.totalBalance}</td>
                                        <td></td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Add_Finishing_Modal show={showAddFinishingModal} setShow={setShowAddFinishingModal} handleSave={handleAddFinishingSave} details={modal_order_details}/>
    </>
    )
}

export default ManageFinishing