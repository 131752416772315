import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        setShowWelcome(true); // Trigger the welcome animation
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${window.backendUrl}/login`,
                { username, password },
                { withCredentials: true }
            );
            if (response.status === 200) {
                // localStorage.setItem('user', username);
                localStorage.setItem('company', 'MIAH & SONS INDUSTRIES LTD.');
                navigate('/manage-orders');
            }
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center min-vh-100">
            <Row className="w-100">
                <Col md={6} lg={4} className="mx-auto">
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <svg
                            viewBox="0 0 300 100" // Adjusted width to fit text
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: '100%', height: '150px' }} // Adjusted height
                        >
                            <path
                                d="M 10 50 Q 80 10, 150 50 Q 220 90, 290 50" // Adjusted curve
                                style={{
                                    stroke: '#007bff',
                                    strokeWidth: '2',
                                    fill: 'none',
                                    strokeDasharray: '100',
                                    strokeDashoffset: '1000',
                                    animation: 'dash 2s ease-in-out forwards',
                                }}
                            />
                            <text
                                x="50%"
                                y="50%"
                                style={{
                                    fill: 'transparent',
                                    stroke: '#007bff',
                                    strokeWidth: '1',
                                    fontSize: '40px',
                                    fontWeight: 'bold',
                                    textAnchor: 'middle',
                                    dominantBaseline: 'middle',
                                    opacity: 0, // Start fully invisible
                                    animation: 'text 2s ease-in-out forwards',
                                    animationDelay: '0s', // Delay to start after the path animation
                                }}
                            >
                                FlowTech
                            </text>
                        </svg>
                    </div>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPassword" className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4 w-100">
                            Login
                        </Button>
                    </Form>
                </Col>
            </Row>
            <style>
                {`
                    @keyframes dash {
                        to {
                            stroke-dashoffset: 0;
                        }
                    }
                    @keyframes text {
                        0% {
                            opacity: 0;
                            fill: transparent;
                        }
                        100% {
                            opacity: 1;
                            fill: #007bff;
                        }
                    }
                    .text {
                        animation: text 3s ease-in-out forwards;
                        animation-delay: 3s; /* Delay to start after the path animation */
                    }
                `}
            </style>
        </Container>
    );
};

export default LoginPage;






