// import { Navigate, Outlet } from "react-router-dom";
// // import useAuth from "./hooks/useAuth";

// const RequireAuth = () => {
//     // const { auth } = useAuth();
// // get local storage user
//     const auth = localStorage.getItem('user');

//     return (
//         <>
//             {auth ?
//                 <Outlet />
//                 : <Navigate to="/"/>
//             }
//         </>
//     );
    
// }

// export default RequireAuth;
import { Navigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const RequireAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null means loading

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // Perform an authenticated request to verify token validity
                await axios.get(`${window.backendUrl}/verify-token`, { withCredentials: true });
                setIsAuthenticated(true);
                console.log('token check')
            } catch (error) {
                // Token is invalid or expired
                console.log('Authentication failed:', error);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <></> // Or a spinner/loading indicator
    }

    return (
        <>
            {isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        </>
    );
};

export default RequireAuth;

