import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { set } from "lodash";
import { formatNumberWithCommas, getAbbreviationForPi  } from "../utilities";

function ManageJobApproval() {
    const page_type = 'manage-job-approval';
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
        category: '',
        order_status: 'DISAPPROVED'
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);
    const [category_options, set_category_options]=useState([])

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            const response5 = await axios.get(`${window.backendUrl}/get_all_categories`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            const options5 = response5.data.data.map((category) => category['Category']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
            set_category_options(options5);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    // useEffect(() => {
    //     fetchDataWithFilters(currentPage)
    // }, [filters, currentPage, searchClick, dateRange]);


    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        
        const { teamname, partyname, buyername, itemname, specification, category, order_status } = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''
        // console.log(page_type)
        axios.get(`${window.backendUrl}/get_orders_with_filters`, {
            params: { teamname, partyname, buyername, itemname, specification, category, page, job_number, style, start_date, end_date, order_status, page_type},
            withCredentials: true 
        })
        .then((response) => {
            console.log(response.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            // console.log(error);
            if (error.response && error.response.status === 401) {
                // Clear local storage and redirect to login page
                navigate('/')
            } else {
                console.log(error);
            }
        });
    };
    
    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change
    

    const handleJobNoClick = (orderId) => {
        navigate('/add-new-order', { state: {orderId } });
    };

    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  
    const handleOrderStatusClick = (orderId, order_status) => {
        setSelectedOrderId(orderId);
        setSelectedOrderStatus(order_status);
        setShowApprovalModal(true);
    };
  
    const handleCloseApprovalModal = () => {
        setShowApprovalModal(false);
    };
  
    const handleConfirmApprove = () => {
      // Handle axios post here
      axios.post(`${window.backendUrl}/change-order-status`, { orderId: selectedOrderId, orderStatus: selectedOrderStatus})
        .then(response => {
          // Handle successful response
          console.log('Job approved:', response);
          setShowApprovalModal(false);
          fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
        })
        .catch(error => {
          // Handle error
          console.error('Error approving job:', error);
          setShowApprovalModal(false);
          fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
        });
        setShowApprovalModal(false);
        // window.location.reload();
    };

    const [schedule_date, set_schedule_date] = useState(null);
    const [showDateModal, setShowDateModal] = useState(false);

    const handleScheduleClick = (orderId) => {
        setSelectedOrderId(orderId);
        // setSelectedOrderStatus(order_status);
        setShowDateModal(true);
    };
  
    const handleCloseDateModal = () => {
        setShowDateModal(false);
    };

    const handleSaveDate = () => {
        // Handle axios post here
        axios.post(`${window.backendUrl}/insert_schedule_date`, { orderId: selectedOrderId, schedule_date: schedule_date})
          .then(response => {
            // Handle successful response
            console.log('Schedule date added :', response);
            setShowDateModal(false);
            window.location.reload();
          })
          .catch(error => {
            // Handle error
            console.error('Error adding schedule date:', error);
            setShowDateModal(false);
            window.location.reload();
          });
        // console.log(schedule_date)
            setShowDateModal(false);
            // window.location.reload();
    };

    return (
        <>
            <NavigationBar />
            <Container fluid>
                <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                    <Col md={2}>
                    <h4>
                            {page_type === 'manage-orders' ? 'Manage Orders' : 
                            page_type === 'manage-job-approval' ? 'Manage Job Approval' : 
                            page_type === 'manage-job-scheduling' ? 'Manage Job Scheduling' : 
                            null}
                    </h4>
                    </Col>
                    <Col>
                        <Button style={{backgroundColor:'rgb(43, 111, 189)',textDecoration:'none',border:'0px'}} onClick={()=>navigate("/add-new-order")}>Add New Order</Button>
                    </Col>
                    <Col>
                        <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                    </Col>
                    <Col>
                        <Form.Select style={{width:'180px'}} name="order_status" value={filters.order_status} onChange={handleInputChange}>
                            <option value="APPROVED">APPROVED job</option>
                            {page_type != 'manage-job-scheduling' ? (
                            <>
                            <option value="DISAPPROVED">DISAPPROVED jobs</option>
                            <option value="">All types of jobs</option>
                            </>
                            ): null}
                        </Form.Select>
                    </Col>
                    <Col>
                        {renderPagination({ totalPages, currentPage, setCurrentPage })}
                        {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                    <Form className='d-flex justify-content-around'>
                        <Form.Select style={{width:'180px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                            <option value="">All Teams</option>
                            {team_options.map((team, index) => (
                                <option key={index} value={team}>
                                {team}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                            <option value="">All Parties</option>
                            {party_options.map((party, index) => (
                                <option key={index} value={party}>
                                {party}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                            <option value="">All Buyers</option>
                            {buyer_options.map((buyer, index) => (
                                <option key={index} value={buyer}>
                                {buyer}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                            <option value="">All Items</option>
                            {item_options.map((item, index) => (
                                <option key={index} value={item}>
                                {item}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select  style={{width:'180px'}} name="category" value={filters.category} onChange={handleInputChange}> 
                            <option value="">All Categories</option>
                                {category_options.map((category, index) => (
                                    <option key={index} value={category}>
                                    {category}
                                    </option>
                                ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                            <option value="">All specification</option>
                            {spec_options.map((team, index) => (
                                <option key={index} value={team}>
                                {team}
                                </option>
                            ))}
                        </Form.Select>
                        
                        <Form.Control style={{width:'180px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                        <div className="search_container" style={{width:'180px'}} >
                            <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                            <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                        </div>
                    </Form>
                </Row>
                <Row>
                    <Col>
                        <Table bordered striped condensed="true" style={{ fontSize: '12px' }}>
                            <thead className="cf" style={{ fontSize: '14px' }}>
                                <tr>
                                    <th>SL</th>
                                    <th>Ord.Date <hr style={{border: '1px solid black' }}/> {page_type === 'manage-job-scheduling' ? 'Schedule Date' : 'ExpDeli'}</th>
                                    <th>Job No. <hr style={{border: '1px solid black' }}/> Status</th>
                                    <th>Style<hr style={{border: '1px solid black' }}/>LPD PO No.</th>
                                    <th>PI No <hr style={{border: '1px solid black' }}/> Bill No.</th>
                                    <th>Company <hr style={{border: '1px solid black' }}/>Buyer</th>
                                    <th>Team</th>
                                    <th>Items</th>
                                    <th>Style/PO#</th>
                                    <th>Spec.</th>
                                    <th>Measurement</th>
                                    <th>Ord. Qty</th>
                                    <th>SqrMt/<br/>Lbs/Pcs</th>
                                    <th>Tot.<br/> SqrMt/Lbs</th>
                                    <th>Price/SqrMt<br/>/Lbs/Pcs</th>
                                    <th>Unit<br/> Price</th>
                                    <th>Tot. Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, orderIndex) => {
                                    let totalOrderQuantity = 0;
                                    let totalPrice = 0;
                                    let totalSquareMeterOfOrder = 0;
                                    const categoryTotals = {};

                                    const orderRows = order.items.map((item, itemIndex) => (
                                        item.specifications.map((spec, specIndex) => (
                                            spec.gsms.map((gsm, gsmIndex) => {
                                                totalOrderQuantity += gsm.orderQuantity;
                                                totalPrice += parseFloat(gsm.totalPrice);
                                                totalSquareMeterOfOrder += parseFloat(gsm.totalSquareMeter);
                                                const rowSpanLength= order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0) + 1

                                                // Calculate category totals
                                                if (!categoryTotals[item.item_category]) {
                                                    categoryTotals[item.item_category] = {
                                                    totalOrderQuantity: 0,
                                                    };
                                                }
                                                categoryTotals[item.item_category].totalOrderQuantity += gsm.orderQuantity;

                                                return (
                                                    <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`} className={order.orderStatus == 'DISAPPROVED' ? 'disapproved_order' : ''}>
                                                        {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                            <>
                                                                <td rowSpan={rowSpanLength}>{order.serial_no}<hr style={{border: '1px solid black' }}/></td>
                                                                {page_type === 'manage-job-scheduling' ? (
                                                                    <td rowSpan={rowSpanLength}>
                                                                        {new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')} <hr style={{ border: '1px solid black' }} />
                                                                        <span className='job_no' onClick={() => handleScheduleClick(order.orderId)}>
                                                                            Schedule
                                                                        </span>
                                                                    </td>
                                                                
                                                                ) : (<td rowSpan={rowSpanLength}>{new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}<hr style={{border: '1px solid black' }}/>{new Date(order.delivery_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>)}
                                                                {/* <td rowSpan={rowSpanLength}><span onClick={() => handleJobNoClick(order.orderId)} style={{ cursor: 'pointer',color: 'rgb(0, 0, 238)', textDecoration: 'underline'  }}>{order.jobNo}</span><hr style={{border: '1px solid black' }}/> {order.orderStatus}</td> */}
                                                                {page_type == 'manage-job-approval' ? (
                                                                    <td rowSpan={rowSpanLength}>
                                                                        <span className='job_no' onClick={() => handleJobNoClick(order.orderId)}>
                                                                            {order.jobNo}
                                                                        </span>
                                                                        <hr style={{ border: '1px solid black' }} />
                                                                        <span className='underline' onClick={() => handleOrderStatusClick(order.orderId, order.orderStatus)}>
                                                                            {order.orderStatus}
                                                                        </span>
                                                                    </td>
                                                                ) : (
                                                                    <td rowSpan={rowSpanLength}>
                                                                        <span className='job_no' onClick={() => handleJobNoClick(order.orderId)}>
                                                                            {order.jobNo}
                                                                        </span>
                                                                        <hr style={{ border: '1px solid black' }} />
                                                                            {order.orderStatus}
                                                                    </td>
                                                                )}
                                                                {/* <td rowSpan={rowSpanLength}>{order.style}<hr style={{border: '1px solid black' }}/>{order.lpd_po_no}</td> */}
                                                                <td rowSpan={rowSpanLength}>
                                                                {order.style.split(/(.{20})/).filter(Boolean).map((line, index) => (
                                                                    <>
                                                                    {line}
                                                                    {index < order.style.split(/(.{20})/).length - 1 && <br />}
                                                                    </>
                                                                ))}
                                                                <hr style={{ border: '1px solid black' }} />
                                                                {order.lpd_po_no.split(/(.{20})/).filter(Boolean).map((line, index) => (
                                                                    <>
                                                                    {line}
                                                                    {index < order.lpd_po_no.split(/(.{20})/).length - 1 && <br />}
                                                                    </>
                                                                ))}
                                                                </td>
                                                                <td rowSpan={rowSpanLength}>
                                                                    {order.currency === '$' ? `PI/${getAbbreviationForPi(order.party_name)}/${order.jobNo}` : ''}
                                                                    <hr style={{ border: '1px solid black' }} />
                                                                    {order.bill_id !== null ? `BILL/${order.jobNo}` : ''}
                                                                </td>
                                                                <td rowSpan={rowSpanLength}>{order.party_name}<hr style={{border: '1px solid black' }}/>{order.buyer_name}</td>
                                                                <td rowSpan={rowSpanLength}>{order.team}</td>
                                                            </>
                                                        )}
                                                        {gsmIndex === 0 && specIndex === 0 && (
                                                            <>
                                                            <td style={{verticalAlign:'middle'}} rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.item_name}</td>
                                                            <td style={{verticalAlign:'middle'}} rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.item_style}</td>
                                                            </>
                                                        )}
                                                        {gsmIndex === 0 && (
                                                            <td style={{verticalAlign:'middle'}} rowSpan={spec.gsms.length}>{spec.specification}</td>
                                                        )}
                                                        <td>
                                                            {gsm.measurement
                                                                .split('\n')
                                                                .map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                                ))}
                                                        </td>
                                                        <td>{formatNumberWithCommas(gsm.orderQuantity)}</td>
                                                        <td>{gsm.squareMeterPerPiece}</td>
                                                        <td>{formatNumberWithCommas(gsm.totalSquareMeter)}</td>
                                                        <td>{order.currency == '$' ? '$' : '৳'}{gsm.pricePerSquareMeter}/ {order.unit_price_per == 'Square Meter' ? ' SqrMtr' : order.unit_price_per == 'Order Qty' ? 'OrdQty' : '' }</td>
                                                        <td>{order.currency == '$' ? '$' : '৳'}{gsm.unitPrice}</td>
                                                        <td>{order.currency == '$' ? '$' : '৳'}{formatNumberWithCommas(gsm.totalPrice)}</td>
                                                    </tr>
                                                );
                                            })
                                        ))
                                    ));

                                    return (
                                        <>
                                            {orderRows}
                                            <tr className='yellow_row'>
                                                <td colSpan="4" style={{textAlign: 'right'}}>
                                                    <strong>
                                                        Job Total:{" "}
                                                        {Object.keys(categoryTotals)
                                                        .map(category => `${category} (${categoryTotals[category].totalOrderQuantity})`)
                                                        .join(", ")}
                                                    </strong>
                                                </td>

                                                <td data-title="Total Order QTY"><strong>{formatNumberWithCommas(totalOrderQuantity)}</strong></td>
                                                <td></td>
                                                <td data-title="Total Square Meter"><strong>{formatNumberWithCommas(totalSquareMeterOfOrder.toFixed(3))}</strong></td>
                                                <td colSpan="2"></td>
                                                <td data-title="Total Price"><strong>{order.currency == '$' ? '$' : '৳'}{formatNumberWithCommas(totalPrice.toFixed(2))}</strong></td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                    </Col>
                </Row>
            </Container>
            <JobApprovalModal order_status={selectedOrderStatus} show={showApprovalModal} handleClose={handleCloseApprovalModal} handleConfirm={handleConfirmApprove} />
            <ScheduleDateModal set_schedule_date={set_schedule_date} show={showDateModal} handleClose={handleCloseDateModal} handleSave={handleSaveDate} />
        </>
    );
}

export default ManageJobApproval;

