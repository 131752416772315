import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import GSM from './gsm';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from 'react';
import axios from '../axios';

function Spec_and_gsm({party_name, item_name, Spec_and_gsm_count, setSpec_and_gsm_count, item_id, spec_id, orderItems, setOrderItems, handleOrderItemChange}) {
    const [spec_options, set_spec_options] = useState([]);

    const increasespecCount = () => {
        setSpec_and_gsm_count(prevCount => prevCount + 1);
    };

    const decreasespecCount = () => {
        setOrderItems((prevOrderItems) =>
            prevOrderItems.map((item, item_index) => {
              if (item_index == item_id && 'specification' in item) {
                return {
                  ...item,
                  specification: item.specification.filter((_, index) => index != spec_id),
                };
              }
              return item;
            })
        );
        setSpec_and_gsm_count(prevCount => prevCount - 1);
    };

    const [gsmCount, setgsmCount] = useState(1);

    useEffect(() => {
        const item = orderItems[item_id];
        if (item && item.specification && item.specification[spec_id] && item.specification[spec_id].gsm) {
          if (item.specification[spec_id].gsm.length === 0) {
            setgsmCount(1);
          } else {
            setgsmCount(item.specification[spec_id].gsm.length);
          }
        }
      }, [orderItems[item_id]?.['specification']?.[spec_id]?.['gsm']?.length]);

    useEffect(() => {
        fetchData();
    }, [item_name, party_name]);
    
    const fetchData = async () => {
        if (!party_name || !item_name) {
            return;
        }
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_party_specifications/${party_name}/${item_name}`);
            const options = response.data.map((spec) => spec['Specification']);
            set_spec_options(options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div style={{marginBottom:'10px'}}>
            {spec_id != 0  && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={decreasespecCount} src="/Images/cross-button.png" alt="cross" style={{ width: '17px', height: '17px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
            <Container fluid style={{width:'96%',border:'1px solid rgba(0,0,0,0.3)',borderRadius:'5px'}}>
                <Row style={{padding:'10px 0px 0px 10px'}}>
                    <Col xs={2}>
                        <Form >
                            <Form.Select name="specName"  value={orderItems[item_id]?.['specification']?.[spec_id]?.specName || ""}  data-item_id={item_id} data-spec_id={spec_id}   onChange={handleOrderItemChange} style={{width:'200px', marginBottom:'10px'}}>
                            <option value=''>Select specification</option>
                                {spec_options.map((spec,index) => (
                                    <option key={index} value={spec}>{spec}</option>
                                ))}
                            </Form.Select>
                        </Form>
                    </Col>
                    <Col xs={10}>
                        {/* GSM Component */}
                        {/* <GSM/> */}
                        {Array.from({ length: gsmCount }, (_, index) => (
                        <GSM key={index}  party_name={party_name} item_name={item_name} spec_name={orderItems[item_id]?.['specification']?.[spec_id]?.specName || ""} item_id={item_id} spec_id={spec_id} gsm_id={index} gsmCount={gsmCount} setgsmCount={setgsmCount} orderItems={orderItems} setOrderItems={setOrderItems} handleOrderItemChange={handleOrderItemChange}  />
                        ))}
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={increasespecCount} src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Spec_and_gsm