import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import ManagePartyTable from '../Tables/manage_party_table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavigationBar from '../NavigationBar';
import ManageBrandForm from '../Forms/manage_brand_form';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../axios';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import {renderPagination, renderTableData, renderTableData2, renderAdvancedPagination } from '../utilities';

function Manage_brand() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [saveClick, setSaveClick] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    useEffect(() => {
        fetchData(currentPage);
      }, [currentPage,saveClick]);
    
    const fetchData = async (page) => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_manage_brand_table`, {
            params: {
                page: page
            }
            });
            setData(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleCellClick = (entry) => {
        setSelectedEntry(entry);
    };

    return (
    <>
    <NavigationBar />
    <Container fluid >
            <Row style={{marginTop:'20px'}}>
                <Col xs={2}></Col>
                <Col xs={7}>
                    <Row className='d-flex justify-space-between mb-3'>
                        <Col >
                            <h3 style={{margin:'0px',textAlign:'left'}}>Manage Brand</h3>
                        </Col>
                        <Col>
                            {renderPagination({ totalPages, currentPage, setCurrentPage })}
                            {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <ManageBuyerTable /> */}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Contact No.</th>
                                    <th>Roll/Others</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableData2(currentPage, data, handleCellClick, 'Name', 'Address', 'ContactNo', 'RollOrOthers')}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <ManageBrandForm saveClick={saveClick} setSaveClick={setSaveClick} selectedEntry={selectedEntry} setSelectedEntry={setSelectedEntry} />
                </Col>
            </Row>
    </Container>
    </>
    )
}

export default Manage_brand