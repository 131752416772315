import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {convertNumberToWords} from '../utilities';
import axios from '../axios';
import { useParams } from 'react-router-dom';

const GatePassPDF = () => {
  const { gatePassId } = useParams();
  const [gatePassInfo, setGatePassInfo] = useState([]);
  const [companyTitle, setCompanyTitle] = useState(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true);
  const [categoryTotals,setCategoryTotals] = useState('');

  useEffect(() => {
    const storedCompany = localStorage.getItem('company');
    if (storedCompany) {
      setCompanyTitle(storedCompany);
    }
  }, []);

  useEffect(() => {
    if (gatePassId) {
      fetch_gatePass_pdf_info();
    }
  }, [gatePassId]);

  const fetch_gatePass_pdf_info = () => {
    axios.get(`${window.backendUrl}/get_gate_pass_pdf_info/${gatePassId}`)
      .then((response) => {
        setGatePassInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // setLoading(false);
  };

  if (loading) {
    return <></>
  }


  return (
    <div style={{ position: 'relative', width: '100%', minHeight: '900px' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'center' }}>
                <h1 className='challan_header' style={{fontSize:'25px', color:'blue', lineHeight:'25px'}}> {companyTitle} </h1>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '15px' }}>
              <strong style={{ color: '#61190d' }}>Factory :</strong> Nandan Icon, House # 45, Lavel-5, Road #15, Rabindra Saroni, Sector #3, Uttara, Dhaka-1230, Bangladesh.
              <hr className='challan_hr' style={{ border: '1px solid black', width: '100%' }} />
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: '100%', fontSize:'12px'}}>
                <tbody>
                  <tr>
                    <td style={{ width: '33%' }}>
                      <p>
                        <strong>SL. No. </strong>{gatePassId}<br />
                        <strong>Vehicle No.: </strong><span id="vehicle_no">{gatePassInfo.vehicleNo}</span>
                      </p>
                    </td>
                    <td style={{ width: '33%', textAlign: 'center' }}>
                        <div style={{ display: 'inline-block' }}>
                            <h2 style={{ border: '1px solid #333', padding: '8px 45px', lineHeight:'18px', fontSize:'20px'}}><strong>Gate Pass</strong></h2>
                        </div>
                    </td>

                    <td style={{ textAlign: 'right' }}>
                      <p>
                        <strong>Date: </strong>{new Date(gatePassInfo.createdDate * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '.')}<br />
                        <strong>Driver Name: </strong><span id="driver_name">{gatePassInfo.driverName}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
                <table style={{ width: '100%', border: '1px solid #000', borderCollapse: 'collapse' }}>
                    <thead style={{fontSize:'12px'}}>
                        <tr>
                        <th style={{ width: '3%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>#</th>
                        <th style={{ width: '20%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Party Name</th>
                        <th style={{ width: '7%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Challan No.</th>
                        <th style={{ width: '7%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Job No.</th>
                        <th style={{ width: '5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Quantity</th>
                        <th style={{ width: '5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Pkt.Qty.</th>
                        <th style={{ width: '15%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Delivery Station</th>
                        <th style={{ width: '5%', padding:'8px 10px',backgroundColor: 'rgba(0, 0, 0, 0.05)', border: '1px solid #000' }}>Remarks</th>
                        </tr>
                    </thead>
                    <tbody class='challan_pdf_table'>
                        {gatePassInfo && gatePassInfo.parties && Object.keys(gatePassInfo.parties).map((partyName, partyIndex) => {
                            const party = gatePassInfo.parties[partyName];
                            return party.challans.map((challan, challanIndex) => (
                            <React.Fragment key={challan.gatePassListId}>
                                <tr>
                                {challanIndex === 0 && (
                                    <>
                                    <td style={{ textAlign: 'right', border: '1px solid #000' }} rowSpan={party.challans.length + 1}>
                                        {partyIndex + 1}
                                    </td>
                                    <td style={{ textAlign: 'left', border: '1px solid #000' }} rowSpan={party.challans.length + 1}>
                                        {party.partyName}
                                    </td>
                                    </>
                                )}
                                <td style={{ textAlign: 'center', border: '1px solid #000' }}>{challan.deliveryChallanNo}</td>
                                <td style={{ textAlign: 'center', border: '1px solid #000' }}>{challan.jobNumber}</td>
                                <td style={{ textAlign: 'right', border: '1px solid #000' }}>{challan.deliveryQty} Pcs</td>
                                <td style={{ textAlign: 'right', border: '1px solid #000' }}>{challan.packetQty || '-'}</td>
                                <td style={{ textAlign: 'center', border: '1px solid #000' }}>{challan.deliveryStation}</td>
                                <td style={{ textAlign: 'right', border: '1px solid #000' }}>{challan.note}</td>
                                </tr>
                                {challanIndex === party.challans.length - 1 && (
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'right', border: '1px solid #000' }}>
                                    <strong>Total : {party.categoryTotalsString  || '-'}</strong>
                                    </td>
                                    <td style={{ textAlign: 'right', border: '1px solid #000' }}>
                                    <strong>{party.challans.reduce((sum, ch) => sum + ch.deliveryQty, 0)}</strong>
                                    </td>
                                    <td style={{ textAlign: 'right', border: '1px solid #000' }}>
                                    <strong>-</strong>
                                    </td>
                                    <td colSpan="2" style={{ textAlign: 'right', border: '1px solid #000' }}></td>
                                </tr>
                                )}
                            </React.Fragment>
                            ));
                        })}
                        {/* Summary row at the end of tbody */}
                        <tr>
                            <td colSpan="4" style={{ textAlign: 'right', border: '1px solid #000' }}>
                            <strong>Gate Pass Total: {gatePassInfo.categoryTotalsString  || '-'}</strong>
                            </td>
                            <td style={{ textAlign: 'right', border: '1px solid #000' }}>
                                <strong>
                                {Object.values(gatePassInfo.parties || {}).reduce((total, party) =>
                                    total + (party.challans || []).reduce((sum, ch) =>
                                    sum + (ch.deliveryQty || 0), 0), 0)}
                                </strong>
                            </td>
                            <td style={{ textAlign: 'right', border: '1px solid #000' }}>
                            <strong>
                                {Object.values(gatePassInfo.parties || {}).reduce((total, party) =>
                                    total + (party.challans || []).reduce((sum, ch) =>
                                    sum + (ch.packetQty || 0), 0), 0) || '-'}
                                </strong>
                            </td>
                            <td colSpan="2" style={{ textAlign: 'right', border: '1px solid #000' }}>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
          </tr>
          <tr>
            <td className='gate_pass_pdf' style={{ padding: '140px 0 20px' }}>
              <table style={{ width: '100%', fontSize:'12px', border: '0' }}>
                <tbody >
                  <tr>
                    <td style={{ textAlign: 'center', verticalAlign: 'top', width: '25%' }}>
                      <div style={{ borderTop: '1px solid #333', width: '90%', paddingTop: '10px', fontWeight: 'bold' }}>Received by</div>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top', width: '25%' }}>
                      <div style={{ borderTop: '1px solid #333', width: '90%', paddingTop: '10px', fontWeight: 'bold' }}>Checked by</div>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top' }}>
                      <div style={{ borderTop: '1px solid #333', width: '90%', paddingTop: '10px', fontWeight: 'bold' }}>Delivery In charge</div>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top', width: '25%' }}>
                      <div style={{ borderTop: '1px solid #333', width: '90%', paddingTop: '10px', fontWeight: 'bold' }}>Authorized by</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GatePassPDF;
