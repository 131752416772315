import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { formatNumberWithCommas } from "../utilities";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function ManageDelivery() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
        category: ''
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);
    const [category_options, set_category_options]=useState([])

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            const response5 = await axios.get(`${window.backendUrl}/get_all_categories`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            const options5 = response5.data.data.map((category) => category['Category']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
            set_category_options(options5);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    // write a new version of the code below
    // useEffect(() => {
    //     fetchDataWithFilters(currentPage)
    // }, [filters, currentPage, searchClick, dateRange]);

    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername, itemname, category, specification} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_manage_delivery_orders`, {
            params: { teamname, partyname, buyername, itemname, specification, category, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col md={2}>
                <h4>Manage Delivery</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select  style={{width:'180px'}} name="category" value={filters.category} onChange={handleInputChange}> 
                        <option value="">All Categories</option>
                            {category_options.map((category, index) => (
                                <option key={index} value={category}>
                                {category}
                                </option>
                            ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered condensed="true" style={{ height: '400px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{fontSize:'14px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th rowspan="2">SL</th>
                                <th>Ord.Date</th>
                                <th rowspan="2">Job No.</th>
                                <th style={{width:'300px'}}>Style</th>
                                <th>Company</th>
                                <th rowspan="2">Team</th>
                                <th rowspan="2">Items</th>
                                <th rowspan="2">Spec.</th>
                                <th rowspan="2">Measurement<br/>LxWxH</th>
                                <th colspan="2">Order Info.</th>
                                <th colspan="2">Production Info.</th>
                                <th colspan="3">Delivery Info.</th>
                                <th colspan="2">Ready for Deli.</th>
                                <th colspan="2">Delivery Balance</th>
                            </tr>
                            <tr>
                                <th>Exp.Deli.</th>
                                <th style={{width:'300px'}}>LPD PO No.</th>
                                <th>Buyer</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Deli.%</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Qty</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:'12px'}}>
                            {orders.map((order, orderIndex) => {
                                const categoryTotals = {};
                                let totalOrderQuantity = 0;
                                let totalOrderQuantityValue = 0;
                                let total_finished_qty = 0;
                                let total_finished_qty_value = 0;
                                let total_finished_balance_qty = 0;
                                let total_delivery_qty = 0;
                                let total_delivery_qty_value = 0;
                                let total_delivery_balance_qty = 0;
                                let total_delivery_balance_qty_value = 0;
                                let total_ready_for_deli_qty = 0;
                                let total_ready_for_deli_qty_value = 0;

                                const orderRows = order.items.map((item, itemIndex) => (
                                    item.specifications.map((spec, specIndex) => (
                                        spec.gsms.map((gsm, gsmIndex) => {
                                            totalOrderQuantity += gsm.orderQuantity;
                                            totalOrderQuantityValue += parseFloat(gsm.totalPrice) || 0;
                                            total_finished_qty += gsm.finished_qty || 0;
                                            total_finished_qty_value += parseFloat(gsm.finished_qty_value) || 0;
                                            total_finished_balance_qty += gsm.finished_balance_qty || 0;
                                            total_delivery_qty += gsm.delivery_qty || 0;
                                            total_delivery_qty_value += parseFloat(gsm.delivery_qty_value) || 0;
                                            total_delivery_balance_qty += gsm.delivery_balance_qty || 0;
                                            total_delivery_balance_qty_value += parseFloat(gsm.delivery_balance_qty_value) || 0;
                                            total_ready_for_deli_qty += gsm.ready_for_deli_qty || 0;
                                            total_ready_for_deli_qty_value += parseFloat(gsm.ready_for_deli_qty_value) || 0;

                                            const rowSpanLength = order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0) + 1;

                                            // Calculate category totals
                                            if (!categoryTotals[item.item_category]) {
                                                categoryTotals[item.item_category] = {
                                                totalOrderQuantity: 0,
                                                };
                                            }
                                            categoryTotals[item.item_category].totalOrderQuantity += gsm.orderQuantity;
                                            console.log(categoryTotals)

                                            return (
                                                <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`} className={order.orderStatus === 'DISAPPROVED' ? 'disapproved_order' : ''}>
                                                    {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                        <>
                                                            <td rowSpan={rowSpanLength}>{order.serial_no}<hr style={{border: '1px solid black' }}/></td>
                                                            <td rowSpan={rowSpanLength}>
                                                                {new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
                                                                <hr style={{border: '1px solid black' }}/>
                                                                {new Date(order.delivery_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}
                                                            </td>
                                                            <td  rowSpan={rowSpanLength}>{order.jobNo}</td>
                                                            <td style={{ width: '300px', wordWrap: 'break-word', wordBreak: 'break-word', overflowWrap: 'break-word' }}rowSpan={rowSpanLength}>{order.style}<hr style={{border: '1px solid black' }}/>{order.lpd_po_no}</td>
                                                            <td rowSpan={rowSpanLength}>{order.party_name}<hr style={{border: '1px solid black' }}/>{order.buyer_name}</td>
                                                            <td rowSpan={rowSpanLength}>{order.team}</td>
                                                        </>
                                                    )}
                                                    {gsmIndex === 0 && specIndex === 0 && (
                                                        <td style={{verticalAlign:'middle'}} rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.item_name}</td>
                                                    )}
                                                    {gsmIndex === 0 && (
                                                        <td style={{verticalAlign:'middle'}} rowSpan={spec.gsms.length}>{spec.specification}</td>
                                                    )}
                                                    <td>{gsm.measurement}</td>
                                                    <td>{gsm.orderQuantity}</td>
                                                    <td>{order.currency}{gsm.totalPrice}</td>
                                                    <td>{gsm.finished_qty}</td>
                                                    <td>{order.currency}{gsm.finished_qty_value}</td>
                                                    <td>{gsm.delivery_qty}</td>
                                                    <td>{order.currency}{gsm.delivery_qty_value}</td>
                                                    <td>{gsm.delivery_percentage}%</td>
                                                    <td>{gsm.ready_for_deli_qty}</td>
                                                    <td>{order.currency}{gsm.ready_for_deli_qty_value}</td>
                                                    <td>{gsm.delivery_balance_qty}</td>
                                                    <td>{order.currency}{gsm.delivery_balance_qty_value}</td>
                                                </tr>
                                            );
                                        })
                                    ))
                                ));

                                return (
                                    <>
                                        {orderRows}
                                        <tr className='yellow_row'>
                                            <td colSpan="3" style={{textAlign: 'right'}}>
                                                    <strong>
                                                        Job Total:{" "}
                                                        {Object.keys(categoryTotals)
                                                        .map(category => `${category} (${categoryTotals[category].totalOrderQuantity})`)
                                                        .join(", ")}
                                                        {/* {console.log(categoryTotals)} */}
                                                    </strong>
                                            </td>
                                            <td><strong>{formatNumberWithCommas(totalOrderQuantity)}</strong></td>
                                            <td><strong>{formatNumberWithCommas(totalOrderQuantityValue.toFixed(2))}</strong></td>
                                            <td><strong>{formatNumberWithCommas(total_finished_qty)}</strong></td>
                                            <td><strong>{order.currency}{formatNumberWithCommas(total_finished_qty_value.toFixed(2))}</strong></td>
                                            <td><strong>{formatNumberWithCommas(total_delivery_qty)}</strong></td>
                                            <td><strong>{order.currency}{formatNumberWithCommas(total_delivery_qty_value.toFixed(2))}</strong></td>
                                            <td></td>
                                            <td><strong>{formatNumberWithCommas(total_ready_for_deli_qty)}</strong></td>
                                            <td><strong>{order.currency}{formatNumberWithCommas(total_ready_for_deli_qty_value.toFixed(2))}</strong></td>
                                            <td><strong>{formatNumberWithCommas(total_delivery_balance_qty)}</strong></td>
                                            <td><strong>{order.currency}{formatNumberWithCommas(total_delivery_balance_qty_value.toFixed(2))}</strong></td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </Table>
                    {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default ManageDelivery