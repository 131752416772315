import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { Add_BM_Modal, Edit_PP_Qty_Modal, PP_Date_Modal ,BM_Small_Trash_Modal} from "../utilities";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { set } from "rsuite/esm/internals/utils/date";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';


function BoardMaking() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
        done_or_undone: 'Undone'
    });
    // const [dateRange, setDateRange] = useState([]);
    // const [dateRange, setDateRange] = useState([new Date(), new Date()]); // use this in production
    const [dateRange, setDateRange] = useState([new Date(), new Date(new Date().setDate(new Date().getDate() + 1))]); // use this in production

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };


    const fetchDataWithFilters = (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername, itemname, specification, done_or_undone} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_board_making_orders`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, start_date, end_date, done_or_undone}
        })
        .then((response) => {
            console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change

    const [showAddBMModal, setShowAddBMModal] = useState(false);
    const [showPPQtyModal, setShowPPQtyModal] = useState(false);
    const [showPPDateModal, setShowPPDateModal] = useState(false);
    const [showSmallTrashModal, setShowSmallTrashModal] = useState(false);
    const [modal_order_details, set_modal_order_details] = useState({});

    const handleAddBMClick =async (orderDetail)=>{
        set_modal_order_details(orderDetail);
        setShowAddBMModal(true);
    }

    const handleEditPPQtyClick =async (orderDetail)=>{
        set_modal_order_details(orderDetail);
        setShowPPQtyModal(true);
    }

    const handlePPDateClick =async (all_pp_ids, pp_date)=>{
        set_modal_order_details({ all_pp_ids, pp_date })
        setShowPPDateModal(true);
    }

    const handleSmallTrashClick =async (orderDetail) => {
        set_modal_order_details(orderDetail);
        setShowSmallTrashModal(true);
    }

    const handleAddBMSave = (data) => {

        const payload = {
            ProductionPlanningId: modal_order_details.ProductionPlanningId,
            OrderGsmId: modal_order_details.OrderGsmId,
            board_making_date: data.board_making_date,
            new_board_making_qty: data.new_board_making_qty
        };

        axios.post(`${window.backendUrl}/add_new_board_making_qty`, payload)
            .then(response => {
                setShowAddBMModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    };

    const handlePPQtySave = (updated_pp_qty) => {
        const payload = {
            ProductionPlanningId: modal_order_details.ProductionPlanningId,
            OrderGsmId: modal_order_details.OrderGsmId,
            old_pp_qty: modal_order_details.pp_qty,
            updated_pp_qty: updated_pp_qty
        };

        axios.post(`${window.backendUrl}/update_pp_qty`, payload)
            .then(response => {
                setShowPPQtyModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }
      
    const handlePPDateSave = (data) => {

        axios.post(`${window.backendUrl}/update_pp_date`, data)
            .then(response => {
                setShowPPDateModal(false)
                // fetchDataWithFilters(currentPage)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }

    const handleSmallTrashSave = (production_planning_id) => {
        axios.post(`${window.backendUrl}/remove_production_plan`, {production_planning_id : production_planning_id})
            .then(response => {
                setShowSmallTrashModal(false)
                fetchDataWithFilters(currentPage, filters, dateRange, job_number, style);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            }
        );
    }

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col>
                <h4>Board Making</h4>
                </Col>
                <Col xs={6} className='d-flex justify-content-center'>
                    <Button style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'10px', color:'black'}} onClick={() => navigate('/board-making-history')}>B.M history</Button>
                    <DateRangePicker placeholder="Select P.P Date Range" onChange={handleDateRangeChange} value={dateRange}/>
                    <Form.Select style={{width:'170px',marginLeft:'10px'}} name="done_or_undone" value={filters.done_or_undone} onChange={handleInputChange}>
                        <option value="Undone">Undone</option>
                        <option value='Done'>Done</option>
                        <option value='All type'>All type</option>
                    </Form.Select>
                </Col>
                <Col className='d-flex justify-content-end' style={{paddingRight:'100px'}}>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select  style={{width:'150px'}}> 
                        <option value="">All category</option>
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered condensed="true" style={{ overflowY: 'auto'  }}>
                        <thead className="cf" style={{fontSize:'14px',position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th>SL</th>
                                <th style={{width:'90px'}}>P.P.D.</th>
                                <th>Roll</th>
                                <th>Grade</th>
                                <th>Measurement</th>
                                <th>O.D.</th>
                                <th style={{width:'200px'}}>Job#/ Style#</th>
                                <th>Company / Buyer</th>
                                <th>Team</th>
                                <th>Items</th>
                                <th>Spec.</th>
                                {/* <th  style={{width:'150px'}}>LO - LI - MC - MP</th> */}
                                <th>O.Qty</th>
                                <th>BMP.</th>
                                <th>BMQ.</th>
                                <th>Bal.</th>
                                <th>Notes</th>
                                <th><Image src="/Images/dustbin_logo.jpg" alt="dustbin" className='dustbin_img'/></th>
                            </tr>
                        </thead>
                            <tbody style={{ fontSize: '12px' }}>
                            {orders.map((order, orderIndex) => {
                                const ppDate = order.pp_date;
                                const rollSize = order.RollSize;

                                const totalOrderQuantity = order.grades.reduce((acc, grade) => 
                                    acc + grade.measurements.reduce((acc, measurement) => 
                                        acc + measurement.orders.reduce((acc, orderDetail) => 
                                            acc + orderDetail.order_quantity, 0), 0), 0);

                                const total_pp_qty = order.grades.reduce((acc, grade) => 
                                    acc + grade.measurements.reduce((acc, measurement) => 
                                        acc + measurement.orders.reduce((acc, orderDetail) => 
                                            acc + orderDetail.pp_qty, 0), 0), 0);

                                const total_bm_qty = order.grades.reduce((acc, grade) => 
                                    acc + grade.measurements.reduce((acc, measurement) => 
                                        acc + measurement.orders.reduce((acc, orderDetail) => 
                                            acc + orderDetail.board_making_qty, 0), 0), 0);

                                const total_balance_qty = order.grades.reduce((acc, grade) => 
                                    acc + grade.measurements.reduce((acc, measurement) => 
                                        acc + measurement.orders.reduce((acc, orderDetail) => 
                                            acc + (orderDetail.pp_qty - orderDetail.board_making_qty), 0), 0), 0);


                                const all_pp_ids = order.grades.flatMap((grade) => 
                                    grade.measurements.flatMap((measurement) => 
                                        measurement.orders.map((orderDetail) => 
                                            orderDetail.ProductionPlanningId
                                        )
                                    )
                                );
                                return (
                                    <>
                                        {order.grades.map((grade, gradeIndex) => {
                                            const gradeName = grade.gradename;
                                            // Calculate total order quantity for each grade
                                            const totalGradeQuantity = grade.measurements.reduce((acc, measurement) => 
                                                acc + measurement.orders.reduce((acc, orderDetail) => 
                                                    acc + orderDetail.order_quantity, 0), 0);

                                            const total_pp_qty_green = grade.measurements.reduce((acc, measurement) => 
                                                acc + measurement.orders.reduce((acc, orderDetail) => 
                                                    acc + orderDetail.pp_qty, 0), 0);

                                            const total_bm_qty_green = grade.measurements.reduce((acc, measurement) => 
                                                acc + measurement.orders.reduce((acc, orderDetail) => 
                                                    acc + orderDetail.board_making_qty, 0), 0);
                                            
                                            const total_balance_qty_green = grade.measurements.reduce((acc, measurement) => 
                                                acc + measurement.orders.reduce((acc, orderDetail) => 
                                                    acc + (orderDetail.pp_qty - orderDetail.board_making_qty), 0), 0);

                                            return (
                                                <>
                                                    {grade.measurements.map((measurement, measurementIndex) => {
                                                        const measurementValue = measurement.measurement;

                                                        return (
                                                            <>
                                                                {measurement.orders.map((orderDetail, orderDetailIndex) => {
                                                                    const rowSpanLength = order.grades.reduce(
                                                                        (acc, grade) => acc + grade.measurements.reduce((acc, measurement) => acc + measurement.orders.length, 0),
                                                                        0
                                                                    ) + order.grades.length +1;

                                                                    return (
                                                                        <tr key={`${orderIndex}-${gradeIndex}-${measurementIndex}-${orderDetailIndex}`}>
                                                                            {gradeIndex === 0 && measurementIndex === 0 && orderDetailIndex === 0 && (
                                                                                <>
                                                                                    <td rowSpan={rowSpanLength}>{order.serial_no}</td>
                                                                                    <td rowSpan={rowSpanLength} style={{backgroundColor:' rgba(255, 0, 0, 0.23)'}}>
                                                                                        {ppDate}
                                                                                        <hr style={{border: '1px solid black', margin:'0 0 5px 0' }}/>
                                                                                        <div className='d-flex justify-content-around'>
                                                                                            <i class="fa fa-calendar-o" style={{cursor:'pointer'}}onClick={() => handlePPDateClick(all_pp_ids, ppDate)}></i>
                                                                                            <i class="fa fa-trash-o"></i>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan={rowSpanLength}><h3>{rollSize}</h3></td>
                                                                                </>
                                                                            )}
                                                                            {measurementIndex === 0 && orderDetailIndex === 0 && (
                                                                                <td rowSpan={grade.measurements.reduce(
                                                                                    (acc, measurement) => acc + measurement.orders.length,
                                                                                    0
                                                                                )+1}>{gradeName}</td>
                                                                            )}
                                                                            {orderDetailIndex === 0 && (
                                                                                <td rowSpan={measurement.orders.length}>{measurementValue}</td>
                                                                            )}
                                                                            <td>{new Date(orderDetail.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>
                                                                            <td style={{width:'200px'}}>{orderDetail.job_no} / {orderDetail.style}</td>
                                                                            <td>{orderDetail.party_name} / {orderDetail.buyer_name}</td>
                                                                            <td>{orderDetail.team}</td>
                                                                            <td>{orderDetail.item}</td>
                                                                            <td>{orderDetail.spec}</td>
                                                                            {/* <td >{orderDetail.gsm_info}</td> */}
                                                                            {/* <td></td> */}
                                                                            <td>{orderDetail.order_quantity}</td>
                                                                            <td><i class="fa fa-edit" id="edit_icon" onClick={() => handleEditPPQtyClick(orderDetail)}></i>{orderDetail.pp_qty}</td>
                                                                            <td><i class="fa fa-plus" id="edit_icon" onClick={() => handleAddBMClick(orderDetail)}></i>{orderDetail.board_making_qty}</td>
                                                                            <td>{orderDetail.pp_qty - orderDetail.board_making_qty}</td>
                                                                            <td></td>
                                                                            <td>{orderDetail.pp_qty - orderDetail.board_making_qty > 0 && <i class="fa fa-trash-o" style={{cursor:'pointer'}} onClick={() => handleSmallTrashClick(orderDetail)}></i>}</td>
                                                                            {/* <Add_BM_Modal show={showAddBMModal} setShow={setShowAddBMModal} handleSave={handleAddBMSave} /> */}
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    })}
                                                    <tr className='green_row'>
                                                        <td colSpan='7' style={{ textAlign: 'right' }}>
                                                           {/* <strong>Grade Total: {gradeName} </strong> 
                                                           <hr style={{ border: '1px solid grey' }} />
                                                           <strong>GP 161 (45), Local 150 (80)</strong> */}
                                                           <strong>Total : </strong>
                                                        </td>
                                                        <td><strong>{totalGradeQuantity}</strong></td>
                                                        <td><strong>{total_pp_qty_green}</strong></td>
                                                        <td><strong>{total_bm_qty_green}</strong></td>
                                                        <td><strong>{total_balance_qty_green}</strong></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                        <tr className='yellow_row'>
                                            <td colSpan='8' style={{ textAlign: 'right' }}>
                                                {/* <strong>Roll Size Total : Carton (788), Top Bottom (21224)</strong>
                                                <hr style={{ border: '1px solid grey' }} />
                                                <strong>Local 150 (291), B3 Taiwan 150 (54), GP 161 (121), IP 250 (1189), GP 160 (533)</strong> */}
                                                <strong>Total : </strong>
                                            </td>
                                            <td><strong>{totalOrderQuantity}</strong></td>
                                            <td><strong>{total_pp_qty}</strong></td>
                                            <td><strong>{total_bm_qty}</strong></td>
                                            <td><strong>{total_balance_qty}</strong></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                );
                            })}
                            </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Add_BM_Modal show={showAddBMModal} setShow={setShowAddBMModal} handleSave={handleAddBMSave} details={modal_order_details}/>
        <Edit_PP_Qty_Modal show={showPPQtyModal} setShow={setShowPPQtyModal} handleSave={handlePPQtySave} details={modal_order_details}/>
        <PP_Date_Modal show={showPPDateModal} setShow={setShowPPDateModal} handleSave={handlePPDateSave} details={modal_order_details}/>
        <BM_Small_Trash_Modal show={showSmallTrashModal} setShow={setShowSmallTrashModal} handleSave={handleSmallTrashSave} details={modal_order_details}/>
    </>
    )
}

export default BoardMaking