import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from '../axios';

function ManageItemForm({ saveClick, setSaveClick }) {

    const categoryOptions = ['Divider','Top Bottom','Stiffener','Poly','Gum Tape']
    const heightOptions = ['Height','Thickness','Height+Thickness','No Height/Thickness']
    const [formData, setFormData] = useState({
    name: '',
    category : 'Carton',
    heightorthickness : 'All Height/Thickness',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = () => {
    if(formData.name !== ''){
        axios.post(`${window.backendUrl}/insert_manage_item`, formData)
        .then((response) => {
        })
        .catch((error) => {
            console.error('Error:edidq', error);
        })
        .then(() => {
            setSaveClick(!saveClick);
            setFormData((prevFormData) => ({ ...prevFormData, name: '' }));
            alert('Item added successfully');
        })
    }
    };

    return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.08)', padding: '20px', borderRadius: '5px' }}>
        <Form style={{ fontWeight: 'bold' }} onSubmit={handleSubmit}>
        <h4>Add New Item</h4>
        <hr />
        <Form.Group className="mb-3" controlId="Name">
            <Form.Label>Name* :</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Category">
            <Form.Label>Category :</Form.Label>
            <Form.Select name="category" value={formData.category} onChange={handleInputChange}>
            <option value="Carton" >Carton</option>
            {categoryOptions.map((category, index) => (
                <option key={index} value={category}>
                {category}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="HeightOrThickness">
            <Form.Label>Height or Thickness :</Form.Label>
            <Form.Select name="heightorthickness" value={formData.heightorthickness} onChange={handleInputChange}>
            <option value="All Height/Thickness">All Height/Thickness</option>
            {heightOptions.map((height, index) => (
                <option key={index} value={height}>
                {height}
                </option>
            ))}
            </Form.Select>
        </Form.Group>

        <Button variant="primary" onClick={handleSubmit}>
            Save
        </Button>
        </Form>
    </div>
    );
}

export default ManageItemForm;