import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from '../axios';

function Add_new_order_form({orderData, handleOrderDataChange}) {
    const [party_options, set_party_options] = useState([]);
    const [buyer_options, set_buyer_options] = useState([]);
    const [company_bank_options, set_company_bank_options] = useState([]);
    const [team_options, set_team_options] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [response1, response2, response3, response4] = await Promise.all([
                axios.get(`${window.backendUrl}/get_all_parties`),
                axios.get(`${window.backendUrl}/get_all_buyers`),
                axios.get(`${window.backendUrl}/get_all_company_banks`),
                axios.get(`${window.backendUrl}/get_all_teams`) ,
            ]);
    
            const parties = response1.data.data.map((party) => party['PartyName']);
            const buyers = response2.data.data.map((buyer) => buyer['Name']);
            const company_banks = response3.data.data.map((company_bank) => ({
                id: company_bank['CompanyBankID'],
                name: company_bank['Name'],
                address: company_bank['Address']
            }));
            const teams = response4.data.data.map((team) => team['Name']);
            
            set_party_options(parties)
            set_buyer_options(buyers)
            set_company_bank_options(company_banks)
            set_team_options(teams)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    return (
        <Container fluid>
            <Row>
                <h5>Add New Order</h5>
            </Row>
            <Row style={{ fontSize: '13px', fontWeight: 'bold', marginTop: '10px' }}>
                <Col xs={12} md={4} >
                    <Form >
                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="jobNumber">
                            <Form.Label className='me-2'>Job Number:*</Form.Label>
                            <Form.Control type="text" style={{ maxWidth: '250px' }} name="job_number" value={orderData.job_number}  onChange={handleOrderDataChange} disabled/>
                        </Form.Group>

                        {/* Other Form.Group components with similar styles */}
                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="orderDate">
                            <Form.Label className='me-2'>Order Date:*</Form.Label>
                            <Form.Control type="date" style={{ maxWidth: '250px' }} name="order_date" value={orderData.order_date}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="exDeliveryDate">
                            <Form.Label className='me-2'>Ex. Delivery Date:*</Form.Label>
                            <Form.Control type="date" style={{ maxWidth: '250px' }} name="delivery_date" value={orderData.delivery_date}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="partyName">
                            <Form.Label className='me-2'>Party Name:*</Form.Label>
                            <Form.Select name="party_name" value={orderData.party_name}  onChange={handleOrderDataChange} style={{width:'250px', marginBottom:'5px'}}>
                                <option value=''>Select Party Name</option>
                                {party_options.map((party, index) => (
                                    <option key={index} value={party}>
                                    {party}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="buyerName">
                            <Form.Label className='me-2'>Buyer Name:*</Form.Label>
                            <Form.Select name="buyer_name" value={orderData.buyer_name}  onChange={handleOrderDataChange} style={{width:'250px', marginBottom:'5px'}}>
                                <option value=''>Select Buyer Name</option>
                                {buyer_options.map((buyer, index) => (
                                    <option key={index} value={buyer}>
                                    {buyer}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="styleOrderFileNo">
                            <Form.Label className='me-2'>Style/Order/File No.:*</Form.Label>
                            <Form.Control type="text" style={{ maxWidth: '250px' }} name="style" value={orderData.style}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="attention">
                            <Form.Label className='me-2'>Attention:</Form.Label>
                            <Form.Control type="text" style={{ maxWidth: '250px' }} name="attention" value={orderData.attention}  onChange={handleOrderDataChange}/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={4}>
                    <Form>
                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="deliveryStation">
                            <Form.Label className='me-2'>Delivery Station:*</Form.Label>
                            <Form.Control as="textarea" style={{ maxWidth: '300px' }} rows='2' name="delivery_station" value={orderData.delivery_station}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="lpdPoNo">
                            <Form.Label className='me-2'>LPD PO No.:</Form.Label>
                            <Form.Control type="text" style={{ maxWidth: '300px' }} name="lpd_po_no" value={orderData.lpd_po_no}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="companyBank">
                        <Form.Label className='me-2'>Company Bank :*</Form.Label>
                            {/* <Form.Select name="company_bank" value={orderData.company_bank}  onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value=''>Select Bank Name</option>
                                {company_bank_options.map((company_bank, index) => (
                                    <option key={index} value={company_bank}>
                                    {company_bank}
                                    </option>
                                ))}
                            </Form.Select> */}
                            <Form.Select name="company_bank" value={`${orderData.company_bank_id}|${orderData.company_bank}`} onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value=''>Select Bank</option>
                                {company_bank_options.map((company_bank, index) => (
                                    <option key={index} value={`${company_bank.id}|${company_bank.name}`}>
                                        {`${company_bank.name}, ${company_bank.address}`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="creditDays">
                            <Form.Label className='me-2'>Credit Days:*</Form.Label>
                            <Form.Control type="number" style={{ maxWidth: '300px' }} name="credit_days" value={orderData.credit_days}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="concernEmail">
                            <Form.Label className='me-2'>Concern Email:</Form.Label>
                            <Form.Control type="email" style={{ maxWidth: '300px' }} name="concern_email" value={orderData.concern_email}  onChange={handleOrderDataChange}/>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="sampleOrder">
                        <   Form.Label className='me-2'>Sample Order: </Form.Label>
                            <Form.Check type="checkbox"/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={4}>
                    <Form>
                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="teamName">
                            <Form.Label className='me-2'>Team Name:</Form.Label>
                            <Form.Select name="team_name" value={orderData.team_name}  onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value=''>Select Team Name</option>
                                {team_options.map((team, index) => (
                                    <option key={index} value={team}>
                                    {team}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="currency">
                            <Form.Label className='me-2'>Currency:</Form.Label>
                            <Form.Select name="currency" value={orderData.currency}  onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value='$'>USD</option>
                                <option value='BDT'>BDT</option>
                                {/* Add dropdown options here */}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="unit_price_per">
                            <Form.Label className='me-2'>Unit price per:</Form.Label>
                            {/* <Form.Control type="text" style={{ maxWidth: '200px' }} name="unit_price_per" value={orderData.unit_price_per}  onChange={handleOrderDataChange}/> */}
                            <Form.Select name="unit_price_per" value={orderData.unit_price_per}  onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value='Square Meter'>Square Meter</option>
                                <option value='Order Qty'>Order Qty</option>
                                <option value='Pound'>Pound (LBS)</option>
                                {/* Add dropdown options here */}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="allowance">
                            <Form.Label className='me-2'>Allowance:</Form.Label>
                            <Row style={{ maxWidth:'320px'}}>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceLW" value={orderData.allowanceLW}  onChange={handleOrderDataChange}/>
                                    <p style={{ fontSize: 'tiny',fontWeight:'normal' }}>L+W</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceWH" value={orderData.allowanceWH}  onChange={handleOrderDataChange}/>
                                    <p style={{ fontSize: 'tiny',fontWeight:'normal' }}>W+H</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceLength" value={orderData.allowanceLength}  onChange={handleOrderDataChange}/>
                                    <p style={{ fontSize: 'tiny',fontWeight:'normal' }}>Length</p>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Control type="text" className="mb-2" name="allowanceWidth" value={orderData.allowanceWidth}  onChange={handleOrderDataChange} />
                                    <p style={{ fontSize: 'tiny',fontWeight:'normal' }}>Width</p>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-2 d-flex flex-col justify-content-end" controlId="note">
                            <Form.Label className='me-2'>Note (if any):</Form.Label>
                            <Form.Control as="textarea" style={{ maxWidth: '300px' }} name='note' value={orderData.note}  onChange={handleOrderDataChange}  />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Add_new_order_form;
