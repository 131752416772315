import React from 'react'
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import ManagePartyTable from '../Tables/manage_party_table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavigationBar from '../NavigationBar';
import ManageSpecificationTable from '../Tables/manage_specification_table';
import ManageSpecificationForm from '../Forms/manage_specification_form';

function Manage_specification() {
  return (
    <>
    <NavigationBar />
    <Container fluid >
            <Row style={{marginTop:'20px'}}>
                <Col xs={2}></Col>
                <Col xs={7}>
                    <Row>
                        <Col xs='auto'>
                            <h3 style={{marginBottom:'15px',textAlign:'left'}}>Manage Specification</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th width="3%">SL</th>
                                <th width="8%">Name</th>
                                <th width="5%">Value</th>
                                <th>Formula</th>
                                <th width="5%">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td align="center">1</td>
                                <td align="left">PE</td>
                                <td align="left"></td>
                                <td align="left"></td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">2</td>
                                <td align="left">Ply</td>
                                <td align="left">1</td>
                                <td align="left">
                                    Ply 3, 5, 7:<br />
                                    Liner Area/Gsm = ((L+W+Allowance:(L+W)) * (W+H+Allowance:(W+H)) *2*Order Qty /10,000) SqrMtr<br />
                                    B Flute Area/Gsm = (LinerAreaPerGsm * 1.40) SqrMtr<br />
                                    C Flute Area/Gsm = (LinerAreaPerGsm * 1.50) SqrMtr<br />
                                    Plain Medium Area/Gsm = LinerAreaPerGsm SqrMtr<br />
                                </td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">3</td>
                                <td align="left">Ply</td>
                                <td align="left">3</td>
                                <td align="left">
                                    Ply 3, 5, 7:<br />
                                    Liner Area/Gsm = ((L+W+Allowance:(L+W)) * (W+H+Allowance:(W+H)) *2*Order Qty /10,000) SqrMtr<br />
                                    B Flute Area/Gsm = (LinerAreaPerGsm * 1.40) SqrMtr<br />
                                    C Flute Area/Gsm = (LinerAreaPerGsm * 1.50) SqrMtr<br />
                                    Plain Medium Area/Gsm = LinerAreaPerGsm SqrMtr<br />
                                </td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">4</td>
                                <td align="left">Ply</td>
                                <td align="left">5</td>
                                <td align="left">
                                    Ply 3, 5, 7:<br />
                                    Liner Area/Gsm = ((L+W+Allowance:(L+W)) * (W+H+Allowance:(W+H)) *2*Order Qty /10,000) SqrMtr<br />
                                    B Flute Area/Gsm = (LinerAreaPerGsm * 1.40) SqrMtr<br />
                                    C Flute Area/Gsm = (LinerAreaPerGsm * 1.50) SqrMtr<br />
                                    Plain Medium Area/Gsm = LinerAreaPerGsm SqrMtr<br />
                                </td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">5</td>
                                <td align="left">Ply</td>
                                <td align="left">7</td>
                                <td align="left">
                                    Ply 3, 5, 7:<br />
                                    Liner Area/Gsm = ((L+W+Allowance:(L+W)) * (W+H+Allowance:(W+H)) *2*Order Qty /10,000) SqrMtr<br />
                                    B Flute Area/Gsm = (LinerAreaPerGsm * 1.40) SqrMtr<br />
                                    C Flute Area/Gsm = (LinerAreaPerGsm * 1.50) SqrMtr<br />
                                    Plain Medium Area/Gsm = LinerAreaPerGsm SqrMtr<br />
                                </td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">6</td>
                                <td align="left">PP</td>
                                <td align="left"></td>
                                <td align="left"></td>
                                <td align="center"></td>
                                </tr>
                                <tr>
                                <td align="center">7</td>
                                <td align="left">PP</td>
                                <td align="left">100</td>
                                <td align="left"></td>
                                <td align="center"></td>
                                </tr>
                            </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <ManageSpecificationForm />
                </Col>
            </Row>
    </Container>
    </>
  )
}

export default Manage_specification