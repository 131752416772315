import React from 'react';
import { Navbar, Row, Col, Container, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from '../NavigationBar';
// import './manage_orders.css';

const PerfectTable = () => {
  return (
    <>
    <NavigationBar />
        <Container fluid>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" className="cf" style={{fontSize:'12px'}}>
                    <thead className="cf">
                        <tr>
                        <th align="center">SL</th>
                        <th align="center">Ord.Date</th>
                        <th align="center">Job No.</th>
                        <th align="center">Style</th>
                        <th align="center">PI No</th>
                        <th align="center">Company</th>
                        <th align="center">Team</th>
                        <th align="center">Items</th>
                        <th align="center">Style/PO#</th>
                        <th align="center">Spec.</th>
                        <th align="center">Measurement</th>
                        <th align="center">Ord. Qty</th>
                        <th align="center">SqrMtr/ Lbs/Pcs</th>
                        <th align="center">Tot. SqrMtr/Lbs</th>
                        <th align="center">Price/SqrMt/Lbs/Pcs</th>
                        <th align="center">Unit Price</th>
                        <th align="center">Tot. Price</th>
                        </tr>
                    </thead>
                    <tbody id="Searchresult">
                        <tr>
                        <td rowSpan="4" data-title="SL">2<hr className="mtopbottom1 bordTop"/><a className="floatright" href="javascript:void(0);" onClick={() => viewWorkOrder(1416, 1)} title="Print this Job"><i className="fa fa-print txt18"></i></a></td>
                        <td rowSpan="4" data-title="Date">03.06.24<hr className="mtopbottom1 bordTop"/>06.06.24</td>
                        <td rowSpan="4" data-title="Job Number"><a href="/Functions/orderForm/1416/manageOrders" title="Change this Job">919/24-N</a><a className="floatright" href="javascript:void(0);" onClick={() => viewWorkOrder(1416, 0)} title="Print this Job"><i className="fa fa-print txt18"></i></a><hr className="mtopbottom1 bordTop"/>APPROVED</td>
                        <td rowSpan="4" data-title="Style Number">-<hr className="mtopbottom1 bordTop"/>POSS - 25 - 00230</td>
                        <td rowSpan="4" data-title="PI / Bill No.">PI/FFL/919/24-N<a className="linknormal floatright" target="_blank" href="/Commercial/PIPrint/1397/1/" title="View PI">
                            <i className="fa fa-info-circle cursor txt15"></i>
                        </a><hr className="mtopbottom1 bordTop"/></td>
                        <td rowSpan="4" data-title="Party Name">FB Footwear Ltd.<hr className="mtopbottom1 bordTop"/>No Buyer</td>
                        <td rowSpan="4" data-title="Team Name">Miah &amp; Son's</td>
                        <td rowSpan="3" style={{verticalAlign:'middle'}} data-title="Item Name">Auto Corrugated Printed Master Carton</td>
                        <td rowSpan="3" data-title="Style/PO#">POSS-25-00230</td>
                        <td rowSpan="3" data-title="Specification Name">Ply 5</td>
                        <td data-title="LxWxH">57.5x44.5x33.5&nbsp;CM</td>
                        <td align="right" data-title="Order Qty">30</td>
                        <td align="right" data-title="Sq. Mt.">1.771</td>
                        <td align="right" data-title="Tot. Sq. Mt.">53.136</td>
                        <td align="right" data-title="Price/ SqrMt/Pcs">$0.650/ SqrMtr</td>
                        <td align="right" data-title="Unit Price">$1.151</td>
                        <td align="right" data-title="Tot. Price">$34.54</td>
                        </tr>
                        <tr>
                        <td data-title="LxWxH">62.5x42.5x36.5&nbsp;CM</td>
                        <td align="right" data-title="Order Qty">30</td>
                        <td align="right" data-title="Sq. Mt.">1.843</td>
                        <td align="right" data-title="Tot. Sq. Mt.">55.278</td>
                        <td align="right" data-title="Price/ SqrMt/Pcs">$0.650/ SqrMtr</td>
                        <td align="right" data-title="Unit Price">$1.198</td>
                        <td align="right" data-title="Tot. Price">$35.93</td>
                        </tr>
                        <tr>
                        <td data-title="LxWxH">62.5x46.5x36.5&nbsp;CM</td>
                        <td align="right" data-title="Order Qty">30</td>
                        <td align="right" data-title="Sq. Mt.">2.001</td>
                        <td align="right" data-title="Tot. Sq. Mt.">60.030</td>
                        <td align="right" data-title="Price/ SqrMt/Pcs">$0.650/ SqrMtr</td>
                        <td align="right" data-title="Unit Price">$1.301</td>
                        <td align="right" data-title="Tot. Price">$39.02</td>
                        </tr>
                        <tr>
                        <td className="lightyellowrow" style={{backgroundColor:'rgba(0,133,34,125)'}} align="right" colSpan="4" data-title="Total"><strong>Job Total: Carton (90)</strong>  </td>
                        <td className="lightyellowrow" align="right" data-title="Total Order QTY"><strong>90</strong></td>
                        <td className="lightyellowrow" align="right" data-title="">&nbsp;</td>
                        <td className="lightyellowrow" align="right" data-title="Total Square Meter"><strong>168.44</strong></td>
                        <td className="lightyellowrow" colSpan="2" align="right" data-title="">&nbsp;</td>
                        <td className="lightyellowrow" align="right" data-title="Total Price"><strong>$109.49</strong></td>
                        </tr>
                    </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    </>
  );
};

const viewWorkOrder = (orderId, printFlag) => {
  // Your logic here
  console.log(`View Work Order ${orderId}, Print Flag: ${printFlag}`);
};

export default PerfectTable;