import React from 'react';
import NavigationBar from '../NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Add_new_party_form from '../Forms/add_new_party_form';
import { Form } from 'react-bootstrap';
import Specification_and_grade from './specification_and_grade';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from 'react';
import axios from '../axios';

function Item({itemCount, setitemCount,partyItems,setPartyItems,item_id,handlePartyItemChange}) {

    // const [itemCount, setitemCount] = useState(0);


    // const sendDataToParent = () => {
    //     setitemCount(prevCount => prevCount + 1);
    //     updateItemCount(prevCount => prevCount + 1);
    // };

    const increaseitemCount = () => {
        setitemCount(prevCount => prevCount + 1);
    };

    const decreaseitemCount = () => {
        setPartyItems((prevPartyItems) => prevPartyItems.filter((_, index) => index !== item_id));
        setitemCount(prevCount => prevCount - 1);
    }; 

    const [Spec_and_grade_count, setSpec_and_grade_count] = useState(1);

    // const updateSpec_and_grade_count = (count) => {
    //     setSpec_and_grade_count(count);
    // }

    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_items`);
            const item_options = response.data.data.map((item) => item['Name']);
            setItems(item_options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const item = partyItems[item_id];
        if (item && item.specification) {
          if (item.specification.length === 0) {
            setSpec_and_grade_count(1);
          } else {
            setSpec_and_grade_count(item.specification.length);
          }
        }
    }, [partyItems[item_id]?.['specification']?.length]);

    return (
        <div style={{marginTop:'20px'}}>           
            {/* {item_id != 0 && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={decreaseitemCount} src="/Images/cross-button.png" alt="cross" style={{ width: '17px', height: '17px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)} */}
            <Container fluid>
                {item_id != 0 && (<Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-end'>
                            <Image onClick={decreaseitemCount} src="/Images/cross-button.png" alt="cross" style={{ position:'absolute', width: '17px', height: '17px',cursor:'pointer'}}/>
                        </Col>
                    </Row>
                </Container>)}
                <Row>
                    <Col xs={12} md={3}>
                            <Form.Select name="itemName"  value={partyItems[item_id]?.itemName || ""}  data-item_id={item_id}  onChange={handlePartyItemChange} style={{width:'250px'}}>
                                <option value=''></option>
                                {items.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Select>
                    </Col>
                    <Col xs={12} md={9}>
                        {/* Specification and grade component */}
                        {Array.from({ length: Spec_and_grade_count }, (_, index) => (
                            <Specification_and_grade key={index} item_id={item_id} spec_id={index} Spec_and_grade_count={Spec_and_grade_count} setSpec_and_grade_count={setSpec_and_grade_count} partyItems={partyItems} setPartyItems={setPartyItems} handlePartyItemChange={handlePartyItemChange} />
                        ))}
                    </Col>
                </Row>
            </Container>
            {item_id == itemCount-1 && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image  onClick={increaseitemCount}  src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
        </div>
    )
}

export default Item