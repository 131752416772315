import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import Spec_and_gsm from './spec_and_gsm';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from 'react';
import axios from '../axios';
import { v4 as uuidv4 } from 'uuid';

function Item({party_name,itemCount, setitemCount, item_id, orderItems, setOrderItems, handleOrderItemChange }) {
    const [item_options, set_item_options] = useState([]);

    const increaseitemCount = () => {
        setitemCount(prevCount => prevCount + 1);
    };

    const decreaseitemCount = () => {
        setOrderItems((prevOrderItems) => prevOrderItems.filter((_, index) => index !== item_id));
        setitemCount(prevCount => prevCount - 1);
    }; 

    const [Spec_and_gsm_count, setSpec_and_gsm_count] = useState(1);

    useEffect(() => {
        const item = orderItems[item_id];
        if (item && item.specification) {
          if (item.specification.length === 0) {
            setSpec_and_gsm_count(1);
          } else {
            setSpec_and_gsm_count(item.specification.length);
          }
        }
    }, [orderItems[item_id]?.['specification']?.length]);

    useEffect(() => {
        fetchData();
    }, [party_name]);
    
    const fetchData = async () => {
        if (!party_name) {
            return;
        }
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_party_items/${party_name}`);
            // const options = response.data.map((item) => item['ItemName']);
            // set_item_options(options);
            // console.log(response.data);
            const options = response.data.map((item) => ({
                name: item['ItemName'],
                category: item['Category']
            }));
            // console.log(options)
            set_item_options(options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <>
            {item_id != 0 && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={decreaseitemCount} src="/Images/cross-button.png" alt="cross" style={{ width: '17px', height: '17px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
            <Container fluid style={{width:'97%',border:'1px solid rgba(0,0,0,0.3)',borderRadius:'5px'}}>   
                <Row style={{padding:'15px 0px'}}>
                    <Col xs='auto'>
                        <Form >
                            {/* <Form.Select name="itemName"  value={orderItems[item_id]?.itemName || ""}  data-item_id={item_id}  onChange={handleOrderItemChange} style={{width:'250px', marginBottom:'10px'}}>
                                <option value=''>Select item</option>
                                {item_options.map((item,index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </Form.Select> */}
                            {/* <Form.Select name="company_bank" value={`${orderData.company_bank_id}|${orderData.company_bank}`} onChange={handleOrderDataChange} style={{width:'300px', marginBottom:'5px'}}>
                                <option value=''>Select Bank</option>
                                {company_bank_options.map((company_bank, index) => (
                                    <option key={index} value={`${company_bank.id}|${company_bank.name}`}>
                                        {`${company_bank.name}, ${company_bank.address}`}
                                    </option>
                                ))}
                            </Form.Select> */}
                            <Form.Select name="itemName"  value={`${orderItems[item_id]?.itemName || ''}|${orderItems[item_id]?.category || ''}`}  data-item_id={item_id}  onChange={handleOrderItemChange} style={{width:'250px', marginBottom:'10px'}}>
                                <option value=''>Select item</option>
                                {item_options.map((item, index) => (
                                    <option key={index} value={`${item.name}|${item.category}`}>
                                        {`${item.name}`}
                                    </option>
                                ))}
                            </Form.Select>

                            {/* Other Form.Group components with similar styles */}
                            <Form.Group className="mb-2" controlId="style">
                                <Form.Control type="text" style={{ width: '250px',fontSize:'15px' , height:'30px' }} placeholder='Style/Order/File No.' name="style"  value={orderItems[item_id]?.style || ""}  data-item_id={item_id}  onChange={handleOrderItemChange} />
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="style">
                                <Form.Control type="text" style={{ width: '250px',fontSize:'15px' , height:'30px' }} placeholder='PO No.' name="PO"  value={orderItems[item_id]?.PO || ""}  data-item_id={item_id}  onChange={handleOrderItemChange} />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={true}>
                            {/* <Spec_and_gsm/> */}
                            {Array.from({ length: Spec_and_gsm_count }, (_, index) => (
                            <Spec_and_gsm key={index} party_name={party_name} item_name={orderItems[item_id]?.itemName || ""} item_id={item_id} spec_id={index} Spec_and_gsm_count={Spec_and_gsm_count} setSpec_and_gsm_count={setSpec_and_gsm_count} orderItems={orderItems} setOrderItems={setOrderItems} handleOrderItemChange={handleOrderItemChange} />
                             ))}
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{marginBottom:'15px'}}>
                    <Row>
                        <Col className='d-flex justify-content-end'>
                            <Image onClick={increaseitemCount} src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                        </Col>
                    </Row>
            </Container>
        </>
    )
}

export default Item