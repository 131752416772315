import React from 'react';
import NavigationBar from '../NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Add_new_party_form from '../Forms/add_new_party_form';
import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useState, useEffect } from 'react';
import axios from '../axios';

function Grade({gradeCount, setgradeCount, grade_id, item_id, spec_id, partyItems, setPartyItems, handlePartyItemChange}) {

    // const [gradeCount, setgradeCount] = useState(0);


    const increasegradeCount = () => {
        setgradeCount(prevCount => prevCount + 1);
    };

    const decreasegradeCount = () => {
        setPartyItems((prevPartyItems) =>
          prevPartyItems.map((item, item_index) => {
            if (item_index === item_id && 'specification' in item) {
              return {
                ...item,
                specification: item.specification.map((spec, spec_index) => {
                  if (spec_index === spec_id && 'grade' in spec) {
                    return {
                      ...spec,
                      grade: spec.grade.filter((_, grade_index) => grade_index !== grade_id),
                    };
                  }
                  return spec;
                }),
              };
            }
            return item;
          })
        );
        setgradeCount(prevCount => prevCount - 1);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [grades, setGrades] = useState([]);
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_all_grades`);
            const grade_options = response.data.data.map((grade) => grade['Name']);
            setGrades(grade_options);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div style={{marginTop:'20px'}}>
            <Container fluid className='mt-3'>
                {grade_id != 0 && (<Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-end' style={{position:'relative'}}>
                            <Image onClick={decreasegradeCount} src="/Images/cross-button.png" alt="cross" style={{ position:'absolute',top:'-20px', right:'-10px', width: '17px', height: '17px',cursor:'pointer'}}/>
                        </Col>
                    </Row>
                </Container>)}
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Select name="gradeName"  value={partyItems[item_id]?.['specification']?.[spec_id]?.['grade']?.[grade_id]?.gradeName || ""}  data-item_id={item_id} data-spec_id={spec_id} data-grade_id={grade_id}  onChange={handlePartyItemChange} style={{width:'250px'}}>
                            <option value=''></option>
                            {grades.map((grade, index) => (
                                <option key={index} value={grade}>
                                    {grade}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xs={12} md={6}>
                            <Form.Control type="text" name="price" value={partyItems[item_id]?.['specification']?.[spec_id]?.['grade']?.[grade_id]?.price || ""}  data-item_id={item_id} data-spec_id={spec_id} data-grade_id={grade_id}  onChange={handlePartyItemChange}  />
                    </Col>
                </Row>
            </Container>
            {/* <Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image onClick={sendDataToParent} src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container> */}
            {grade_id == gradeCount-1 && (<Container fluid>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Image  onClick={increasegradeCount}  src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '20px', height: '20px',cursor:'pointer'}}/>
                    </Col>
                </Row>
            </Container>)}
        </div>
    )
}

export default Grade